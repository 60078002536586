import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const Test = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedTestId, setSelectedTestId] = useState(null);
  const navigate = useNavigate(); 

  const checkPermission = useCheckPermission();

  const canEditTest = checkPermission(ADMIN_MODULES.TEST, PERMISSIONS.EDIT);
  const canDeleteTest = checkPermission(ADMIN_MODULES.TEST, PERMISSIONS.DELETE);
  const canAddTest = checkPermission(ADMIN_MODULES.TEST, PERMISSIONS.ADD)
  const canViewTest = checkPermission(ADMIN_MODULES.TEST, PERMISSIONS.VIEW);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("tests/tests");
        setTests(response.data.test);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tests. Please try again.");
        setLoading(false);
      }
    };

    fetchTests();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [tests]); // Trigger DataTable when tests change

  const handleShow = (testId) => {
    setSelectedTestId(testId);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const deleteTest = async () => {
    try {
      await axiosClient.delete(`tests/test/${selectedTestId}`);
      setTests(tests.filter((test) => test._id !== selectedTestId));
      setShow(false);
      setSuccess("Test deleted successfully!");
      setError("");
    } catch (err) {
      setError("Failed to delete the test. Please try again.");
      setSuccess("");
    }
  };

  
  // Handel File Export in excel 
  const handleExportExcel= async() =>{
    try{
      await axiosClient.get("tests/excel", {
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "test_categories.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    }catch(error){
      console.error("Error exporting data to Excel:", error);
    }
  }
  const handleImportExcel = (filePath) => {
    const formData = new FormData();
    formData.append("file", filePath);
    axiosClient
      .post("tests/excel/import", formData)
      .then((response) => {
        window.location.reload();
        setSuccess("File uploaded successfully!");
        setError("");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setError("Failed to upload file. Please try again.");
        setSuccess("");
      });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Test</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this test?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={deleteTest}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Tests</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <div className="add-button gap-3">
                {canViewTest && <button onClick={handleExportExcel}> Export</button>}
                {canAddTest &&<label htmlFor="fileImport_exl" className="cursor-pointer font-medium text-sm md:text-base font-['Poppins'_,_sans-serif] relative bg-[#2e3192] text-white inline-block px-9 py-2 rounded-[10px]">
                  Import
                  <input type="file" id="fileImport_exl" className="cursor-pointer max-w-full opacity-0 absolute left-0 top-0 max-h-full w-full h-full z-10" name="file"
                  accept=".xlsx, .xls" onChange={(e) => handleImportExcel(e.target.files[0])}/>
                </label>}
                {/* <button onClick={handleImportExcel}> Import</button> */}
                {canAddTest&&<Link to="/admin/add-test">
                  <button>+ Add Test</button>
                </Link>}
              </div>
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Test Title</th>
                      <th>Price</th>
                      <th>Fasting Time</th>
                      <th>Report Time</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(tests || []).map((test, index) => (
                      <tr key={test._id}>
                        <td>{index + 1}</td>
                        <td>{test.title}</td>
                        <td>₹{test.price}</td>
                        <td>{test.fastingTime}</td>
                        <td>{test.reportTime}</td>

                        <td>
                          <Link to={`/admin/edit-test/${test._id}`}>
                           {canEditTest &&  <i className="fa-regular fa-pen-to-square"></i>}
                          </Link>
                          &nbsp;
                          {canDeleteTest && <i className="fa-solid fa-trash" onClick={() => handleShow(test._id)}></i>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Test;
