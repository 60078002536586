import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

function MetaTags() {
  const dataTableRef = useRef();
  const { filepath } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [meta, setMeta] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentogImg, setCurrentOgImg] = useState("");
  const [formData, setFormData] = useState({
    metaTitle: "",
    metaDescription: "",
    metaUrl: "",
    path: "",
    metaOgImg: ""
  });

  useEffect(() => {
    const interval = setTimeout(() => {
      fetchMeta();
    }, 400);
    return () => clearInterval(interval);
  }, []);
  const fetchMeta = async () => {
    try {
      const response = await axiosClient.get("meta/all-meta");
      setMeta(response.data.pageMeta);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  data pass in formData
    const formDataToSend = new FormData();
    formDataToSend.append("metaTitle", formData.metaTitle);
    formDataToSend.append("metaDescription", formData.metaDescription);
    formDataToSend.append("metaUrl", formData.metaUrl);
    formDataToSend.append("path", formData.path);
    if (formData.metaOgImg) {      
      formDataToSend.append("metaOgImg", formData.metaOgImg);
    }
    try {
      if (isEditMode) {
        // Update an existing testing step
        await axiosClient.put(`meta/${selectedSlot}`, formDataToSend);
        setSuccess("updated successfully!");
      } else {
        // Create a new testing step
        await axiosClient.post("meta/add", formDataToSend);
        setSuccess("created successfully!");
      }
      fetchMeta();
      setModalShow(false); // Close the modal after success

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
      setTimeout(() => {
        setError(""); // Clear error message
      }, 2000);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDelete = () => {
    axiosClient
      .delete(`meta/${selectedSlot}`)
      .then((response) => {
        if (response.data.success) {
          setMeta(meta.filter((category) => category._id !== selectedSlot));
          setSuccess("deleted successfully!");
          setError("");
          setShow(false);
          fetchMeta();
        }
      })
      .catch((err) => {
        setError("Failed to delete meta.");
        setSuccess("");
      });
  };

  const handleOgImgChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      metaOgImg: file
    }));

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentOgImg(imageUrl);
  };

  const handleModalShow = (categoryId = null) => {

    if (categoryId) {
      setIsEditMode(true);
      setSelectedSlot(categoryId);
      // Editing mode
      const testingToEdit = meta.find((t) => t._id === categoryId);
      setFormData({
        metaDescription: testingToEdit.metaDescription,
        metaTitle: testingToEdit.metaTitle,
        metaUrl: testingToEdit.metaUrl,
        metaOgImg: testingToEdit.metaOgImg,
        path: testingToEdit.path
      });
      setCurrentOgImg(testingToEdit.metaOgImg);      
    } else {
      // Create mode
      setFormData({
        metaTitle: "",
        metaDescription: "",
        metaUrl: "",
        metaOgImg: "",
        path: ""
      });
      setIsEditMode(false);
    }
    setModalShow(true);
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Tests</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <div className="add-button gap-3">
                {/* <button onClick={handleImportExcel}> Import</button> */}
                <button onClick={() => handleModalShow()}>+ Add Meta</button>
              </div>
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Meta Title</th>
                      <th>Meta Discription</th>
                      <th>Meta URL</th>
                      <th>Path</th>
                      <th>Meta og Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meta.length > 0 &&
                      meta.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item.metaTitle}</td>
                          <td>
                            <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full w-32">
                              {item.metaDescription}
                            </p>
                          </td>
                          <td>{item.metaUrl}</td>
                          <td>{item.path}</td>
                          <td>{item.metaOgImg}</td>
                          <td>
                            <Link>
                              {
                                <i
                                  className="fa-regular fa-pen-to-square"
                                  onClick={() => handleModalShow(item._id)}
                                ></i>
                              }
                            </Link>
                            &nbsp;
                            {<i className="fa-solid fa-trash" onClick={() => setShow(true)}></i>}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Edit Modal */}
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Home Testing Step" : "Add Home Testing Step"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="metaTitle">Title</label>
              <input
                type="text"
                id="metaTitle"
                className="form-control"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleInputChange}                
              />
              <label htmlFor="metaDescription">Description</label>
              <input
                type="text"
                className="form-control"
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleInputChange}                
              />
              <label htmlFor="metaUrl">URL</label>
              <input
                type="text"
                className="form-control"
                name="metaUrl"
                value={formData.metaUrl}
                onChange={handleInputChange}                
              />
              <label htmlFor="path">Path</label>
              <input
                type="text"
                className="form-control"
                name="path"
                value={formData.path}
                onChange={handleInputChange}                
              />
              <label htmlFor="metaOgImg">Image</label>
              <input
                type="file"
                className="form-control"
                name="metaOgImg"
                onChange={handleOgImgChange}                
              />
              {isEditMode && currentogImg && (
                <div className="col-lg-12 mx-3">
                  <label className="form-label">Image Preview</label>
                  <img
                    src={formData.metaOgImg instanceof File ? currentogImg : filepath + currentogImg}// use local preview if a new image is chosen
                    alt="Test Preview"
                    style={{ width: "200px", height: "auto" }}
                  />
                </div>
              )}
            </div>

            <Modal.Footer>
              <button type="submit" className="modal-footer-btn">
                {isEditMode ? "Update" : "Create"}
              </button>
              <button
                className="modal-footer-btn"
                onClick={() => {
                  setModalShow(false);
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {/* delete modal */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Time Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Are you sure you want to delete</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-footer-btn" onClick={handleDelete}>
            Delete
          </button>
          <button
            className="modal-footer-btn"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MetaTags;
