// import React, { useState } from "react";

// import { Link } from "react-router-dom";
// import Modal1 from "./PreviewReport";

// const CompletedTests = () => {
//   const reports = [
//     { id: 1, name: "Full Body Test", date: "10/08/2024", price: 1500 },
//     { id: 2, name: "Blood Sugar Test", date: "12/08/2024", price: 800 },
//     { id: 3, name: "Cholesterol Test", date: "15/08/2024", price: 600 },
//     { id: 4, name: "Liver Function Test", date: "20/08/2024", price: 1000 },
//     { id: 5, name: "Kidney Function Test", date: "22/08/2024", price: 900 },
//   ];

//   const [currentPage, setCurrentPage] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedReport, setSelectedReport] = useState(null);
//   const reportsPerPage = 2;

//   const handleDownload = (reportId) => {
//     const fileUrl = `/path-to-your-report-file-${reportId}.pdf`;
//     const a = document.createElement("a");
//     a.href = fileUrl;
//     a.download = `Report-${reportId}.pdf`;
//     a.click();
//   };

//   const handleShare = (reportId) => {
//     if (navigator.share) {
//       navigator
//         .share({
//           title: `${reports[reportId - 1].name} Report`,
//           text: `Here is the report of your ${
//             reports[reportId - 1].name
//           } completed on ${reports[reportId - 1].date}.`,
//           url: window.location.href,
//         })
//         .then(() => console.log("Report shared successfully!"))
//         .catch((error) => console.log("Error sharing", error));
//     } else {
//       alert("Your browser does not support the Web Share API");
//     }
//   };

//   const displayedReports = reports.slice(
//     currentPage * reportsPerPage,
//     currentPage * reportsPerPage + reportsPerPage
//   );

//   const handleNextPage = () => {
//     if ((currentPage + 1) * reportsPerPage < reports.length) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePreviousPage = () => {
//     if (currentPage > 0) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handlePreview = (report) => {
//     setSelectedReport(report);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedReport(null);
//   };

//   return (
//     <div className="p-2">
//       {displayedReports.map((report) => (
//         <TestCard
//           key={report.id}
//           report={report}
//           handleDownload={() => handleDownload(report.id)}
//           handleShare={() => handleShare(report.id)}
//           handlePreview={() => handlePreview(report)} // Pass handlePreview
//         />
//       ))}
//       <div className="flex justify-between mt-6">
//         <button
//           className={`border-color2 font4 hover-color2 hover:text-white px-4 py-2 rounded-md font-museo ${
//             currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
//           }`}
//           onClick={handlePreviousPage}
//           disabled={currentPage === 0}
//         >
//           Previous
//         </button>
//         <button
//           className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo ${
//             (currentPage + 1) * reportsPerPage >= reports.length
//               ? "opacity-50 cursor-not-allowed"
//               : ""
//           }`}
//           onClick={handleNextPage}
//           disabled={(currentPage + 1) * reportsPerPage >= reports.length}
//         >
//           Next
//         </button>
//       </div>

//       {/* Modal to preview report */}
//       <Modal1
//         isOpen={isModalOpen}
//         onClose={closeModal}
//         report={selectedReport}
//       />
//     </div>
//   );
// };

// const TestCard = ({ report, handleDownload, handleShare, handlePreview }) => (
//   <div className="bg-white p-6 rounded-md shadow-lg mb-4 flex flex-col">
//     <div className="flex justify-between mb-4">
//       <h3 className="text-lg font-semibold font-museo">{report.name}</h3>
//       <p className="text-lg font-semibold font-museo">₹ {report.price}</p>
//     </div>

//     <div className="flex justify-between mb-4 font-mont">
//       <p className="font7">The report completed on {report.date}</p>
//       <Link to="#" className="font1 underline" onClick={handlePreview}>
//         Preview
//       </Link>
//     </div>

//     <div className="flex justify-between font-museo font-semibold">
//       <button
//         className="bg-card1 hover-color1 text-white px-4 py-2 rounded-lg"
//         onClick={handleDownload}
//       >
//         Download Report
//       </button>
//       <button
//         className="bg-card1 hover-color1 text-white px-4 py-2 rounded-lg"
//         onClick={handleShare}
//       >
//         Share Report
//       </button>
//     </div>
//   </div>
// );

// export default CompletedTests;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal1 from "./PreviewReport";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useDispatch } from "react-redux";
import { setRegisterActive } from "../../redux/registerSlice";

const CompletedTests = () => {
  const dispatch = useDispatch();
  const [completedTests, setCompletedTests] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const reportsPerPage = 2;
  const navigate = useNavigate();
  const {filepath} = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId"); // Get userId from local storage
    if (!token) {
      navigate("/");
      dispatch(setRegisterActive(true));
    } else {
      fetchUserBookings(userId); // Fetch bookings for the logged-in user
    }
  }, [navigate]);

  const fetchUserBookings = async (userId) => {
    try {
      const response = await axiosClient.get(
        `/tests-booking/test/booking/user/${userId}`
      );
      if (response.data.success) {
        const bookings = response.data.bookings;
        // console.log("bookings",bookings)
        const completedReports = (await Promise.all(bookings.map(fetchTestDetails))).flat();
        setCompletedTests(completedReports);
        
      }
    } catch (error) {
      console.error("Error fetching user bookings:", error);
    }
  };

  const fetchTestDetails = async (booking) => {
    if (booking.progress.reportReady) {
      const reportFiles = await Promise.all(
        booking.progress.reportFiles.map(async (file) => {
          const testResponse = await axiosClient.get(`/tests/test/${file.testId}`);
          const test = testResponse.data.test;
          
          return {
            id: file.testId,
            title: test.title,
            price: test.price,
            filePath: file.filePath,
            date: booking.progress.dates.reportReadyDate,
          };
        })
      );
      return reportFiles;
    }
    return [];
  };

  const handleDownload = async (filePath) => {
    try {
      const response = await fetch(`${filepath}reports/${filePath}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }
  
      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create a temporary URL for the blob
      const a = document.createElement("a");
      a.href = url;
      a.download = filePath.split("/").pop(); // Extract the file name from the path
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url); // Clean up the object URL after download
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  
  
  const handlePreview = (filepath) => {
    setSelectedReport(filepath);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  const displayedReports = completedTests
    .slice(
      currentPage * reportsPerPage,
      currentPage * reportsPerPage + reportsPerPage
    )
    .flat(); // Flatten the array to access individual reports

  const handleNextPage = () => {
    if ((currentPage + 1) * reportsPerPage < completedTests.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleShare = (filePath) => {
    const fileURL = `${filepath}reports/${filePath}?download=true`;
    const whatsappURL = `https://wa.me/?text=Your report is ready! Download it here: ${encodeURIComponent(fileURL)}`;
  
    window.open(whatsappURL, "_blank");
  };
  

  return (
    <div className="p-4 md:p-6 lg:p-8">
      {displayedReports.map((report) => (
        <TestCard
          key={report.id}
          report={report}
          date={report}
          handleDownload={() => handleDownload(report.filePath)}
          handlePreview={() => handlePreview(report.filePath)}
          handleShare={() => handleShare(report.filePath)}
        />
      ))}
      <div className="flex flex-col md:flex-row justify-between mt-6 space-y-4 md:space-y-0">
        <button
          className={`border-color2 font4 hover-color2 hover:text-white px-4 py-2 rounded-md font-museo ${
            currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
        <button
          className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo ${
            (currentPage + 1) * reportsPerPage >= completedTests.length
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={handleNextPage}
          disabled={(currentPage + 1) * reportsPerPage >= completedTests.length}
        >
          Next
        </button>
      </div>

      {/* Modal to preview report */}
      <Modal1
        isOpen={isModalOpen}
        onClose={closeModal}
        report={selectedReport}
      />
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const TestCard = ({ report, handleDownload, handlePreview, handleShare }) => (
  <div className="bg-white p-4 md:p-6 sm:p-0 rounded-md shadow-lg mb-4 flex flex-col">
    <div className="flex justify-between mb-4">
      <h3 className="text-lg font-semibold font-museo">{report.title}</h3>
      <p className="text-xs sm:text-sm md:text-lg font-semibold font-museo">
        <span className="whitespace-nowrap">₹ {report.price}</span>
      </p>
    </div>
    <div className="flex flex-col md:flex-row justify-between mb-4 font-mont">
      <p className="font7 mb-2 md:mb-0">
        The report was ready on {formatDate(report.date)}
      </p>
      <Link to="#" className="font1 underline" onClick={handlePreview}>
        Preview
      </Link>
    </div>
    <div className="flex flex-col md:flex-row justify-between font-museo font-semibold space-y-2 md:space-y-0">
      <button
        className="bg-card1 hover-color1 text-white lg:px-4 lg:py-2 md:p-1 sm:p-1 rounded-lg w-full sm:w-1/2 md:w-auto"
        onClick={handleDownload}
      >
        Download Report
      </button>
      <button
        className="bg-card1 hover-color1 text-white px-4 py-2 rounded-lg"
        onClick={() => handleShare(report.filePath)}
      >
        Share Report
      </button>
    </div>
  </div>
);

export default CompletedTests;
