import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import IncludedTests2 from "./IncludedTest2";
import { percentage } from "../../constants/constant";

const HealthPackages = () => {
  const navigate = useNavigate();
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const { addToCart, isInCart, filepath } = useAuth();
  const [filteredParameters, setFilteredParameters] = useState(null);
  const [filteredPackages, setFilteredPackages] = useState([]); // Filtered packages
  const [categories, setCategories] = useState([]); // Categories
  const [selectedCategory, setSelectedCategory] = useState("All"); // Track selected category

  const handleBuyNow = (testId) => {
    addToCart(testId);
  };

  const benefits = [
    {
      id: 1,
      imgSrc: "images/photo2.png",
      title: "Reports Consultation",
    },
    {
      id: 2,
      imgSrc: "images/photo1.png",
      title: "On-time Sample Collection",
    },
    {
      id: 3,
      imgSrc: "images/photo3.png",
      title: "Affordable Pricing",
    },
    {
      id: 4,
      imgSrc: "images/photo4.png",
      title: "Certified Labs",
    },
  ];

  const handleCheckupClick = (pkg) => {
    setSelectedCheckup(pkg);
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
  };

  // Fetch categories

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient(`/test-categories/test/categories`);
        const data = response.data.testCategory || [];
        setCategories([{ _id: "All", name: "All" }, ...data]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  // fetch all parameters for selected test:
  useEffect(() => {
    const fetchParameters = async () => {
      try {
        const response = await axiosClient.get(
          `/tests-parameters/test/parameters/test/${selectedCheckup?._id}`
        );
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const parameters = await response.data;
        setFilteredParameters(parameters.testParameters || []);
      } catch (error) {
        // setError("Failed to fetch parameters");
        console.error("There was a problem with the fetch parameters:", error);
      }
    };

    fetchParameters();
  }, [selectedCheckup?._id]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axiosClient("tests/tests");
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        let tests = data.test || [];

        // Sort by createdAt or another attribute (newest first)
        tests = tests.sort((a, b) => b._id.localeCompare(a._id)); // Sort by descending order of IDs
        // Filter tests where show is true
        tests = tests.filter((test) => test.show === true);
        setPackages(tests || []);

        // Fetch the parameters for each package (test)
        const fetchParameters = async (testId) => {
          try {
            const paramResponse = await axiosClient(
              `/tests-parameters/test/parameters/test/${testId}`
            );
            if (paramResponse.status === 404) {
              return 0; // If 404, return 0 for the totalParametersCount
            }
            return paramResponse.data.totalParametersCount || 0;
          } catch (error) {
            // In case of any error (e.g., network issues), return 0
            return 0;
          }
        };

        // Update each package with totalParametersCount
        const updatedPackages = await Promise.all(
          tests.map(async (pkg) => {
            const totalParametersCount = await fetchParameters(pkg._id);
            return { ...pkg, totalParametersCount };
          })
        );

        setPackages(updatedPackages);
        // let tests = response.data.test || [];
        // // Sort packages (optional)
        // tests = tests.sort((a, b) => b._id.localeCompare(a._id)); // Sort by descending order of IDs
        setPackages(updatedPackages);
        setFilteredPackages(updatedPackages); // Initially show all packages
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchPackages();
  }, []);

  // Filter packages based on selected category
  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredPackages(packages);
    } else {
      const filtered = packages.filter(
        (pkg) => pkg.testCategory && pkg.testCategory[0] === selectedCategory
      );
      setFilteredPackages(filtered);
    }
  }, [selectedCategory, packages]);
  console.log(selectedCheckup, 8787);

  return (
    <section id="health-packages">
      <div className="py-10 px-4 sm:px-8 lg:px-20 bg-tertiary1 max-w-screen-2xl mx-auto relative">
        <h2 className="lg:text-3xl text-2xl font-bold text-white text-center mb-8 font-museo mt-10">
          Check Our <span className="font6">Top Health Packages</span>
        </h2>
        <div className="d-flex gap-6 flex-wrap w-full justify-content-center relative">
          <div
            style={{ left: "-3.25rem" }}
            className="category-prev absolute  top-1/2 -translate-y-1/2 p-3 w-10  h-10 rounded-full border-2 border-color1 font6 hover:bg-color1 hover:text-white transition-all flex items-center justify-center cursor-pointer  hover-color1"
          >
            <FiArrowLeft className="text-lg" />
          </div>

          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            breakpoints={{
              640: { slidesPerView: 3, spaceBetween: 20 },
              768: { slidesPerView: 4, spaceBetween: 20 },
              1024: { slidesPerView: 6, spaceBetween: 20 },
            }}
            navigation={{
              nextEl: ".category-next",
              prevEl: ".category-prev",
            }}
            modules={[Navigation]}
            className="categoriesSwiper"
          >
            {categories.map((category, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`cursor-pointer font-museo font-semibold font4 hover:bg-indigo-100 px-4 py-2 rounded-full text-center ${
                    selectedCategory === category._id
                      ? "bg-indigo-100"
                      : "bg-white"
                  }`}
                  onClick={() => setSelectedCategory(category._id)}
                >
                  {category.name}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            style={{ right: "-3.25rem" }}
            className="category-next absolute top-1/2 -translate-y-1/2 p-3 w-10 h-10 rounded-full border-2 border-color1 font6 hover:bg-color1 hover:text-white transition-all flex items-center justify-center cursor-pointer  hover-color1"
          >
            <FiArrowRight className="text-lg" />
          </div>
        </div>

        {/* Swiper Carousel */}
        <div className="max-w-screen-2xl mx-auto relative mt-10">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={{
              nextEl: ".next-but",
              prevEl: ".prev-but",
            }} // Enable navigation
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 3 },
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            {/* {packages
            .filter((pkg) => pkg.show === true && pkg.testType == "package") */}
            {filteredPackages
              .filter((pkg) => pkg.show === true && pkg.testType == "package")
              .map((pkg, index) => (
                <SwiperSlide key={index} className="h-auto self-stretch">
                  <div
                    className="w-full h-full sm:w-80 md:w-[230px] lg:w-[100%] cursor-pointer  bg-card3 rounded-lg shadow-lg overflow-hidden"
                    onClick={() => handleCheckupClick(pkg)}
                    // onClick={() => setSelectedCheckup(pkg)}
                  >
                    <div
                      className="w-full h-full lg:py-8 md:py-3 py-6 lg:pl-6 md:pl-4 pl-4  flex flex-col justify-between relative bg-cover bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url('${
                          filepath + pkg.backgrounImg
                        }')`,
                      }}
                    >
                      <div className="relative z-[11] flex flex-col flex-grow">
                        <h3 className="text-lg font-semibold font-museo font4 mb-2 lg:w-[85%] md:w-[95%]">
                          {pkg.title}{" "}
                          <span className="font6">{pkg.subTitle}</span>
                        </h3>

                        <p className="md:text-xs text-sm font4 mb-4 font-mont lg:w-[75%] md:w-[98%] line-clamp-3">
                          {pkg.description}
                        </p>

                        <div className="flex lg:flex-row gap-2 text-xs font-mont lg:mb-4 mb-2 flex-grow">
                          <span className="font6">
                            {pkg.parameterCount}
                            <span className="font7 ml-1">Parameters</span>
                          </span>
                          <p className="font6">
                            <span className="font7 ml-1">Reports in </span>
                            {pkg.reportTime}
                          </p>
                        </div>

                        <div className="flex flex-col justify-end h-32">
                          <div className="flex justify-between items-center lg:mb-4 mb-2 w-[50%] bg-white lg:py-2 lg:px-4 p-2 rounded-lg shadow-sm font-museo">
                            <div className="flex flex-grow lg:text-lg text-sm font-semibold font6">
                              {pkg.offerPrice ? (
                                <>
                                  <div className="">
                                    <span className="block text-left">
                                      ₹{pkg.offerPrice}
                                    </span>
                                    <span className="text-gray-500 block text-left line-through text-base font-bold">
                                      ₹{pkg.price}
                                    </span>
                                  </div>
                                  <div className="border-l flex flex-col border-solid border-[#888888] flex-grow pl-2 font5 lg:text-sm text-xs font-medium ml-2">
                                    <span className="text-xl font-bold text-center">
                                      {percentage(pkg.price, pkg.offerPrice)}%
                                      off
                                    </span>
                                    {/* <div className="font5 lg:text-sm text-xs font-medium ml-2">
                                      60% off
                                     </div> */}
                                  </div>
                                </>
                              ) : (
                                <span>₹{pkg.price}</span>
                              )}
                            </div>
                          </div>
                          <button
                            className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBuyNow(pkg._id);
                            }}
                          >
                            {isInCart(pkg._id) ? (
                              <Link to="/steps" className="w-full block">
                                View Cart
                              </Link>
                            ) : (
                              "Buy Now"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            <button
              className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs block mx-auto mt-24 w-full max-w-[120px] transition-all"
              onClick={() => navigate("/test-packages")}
            >
              View All
            </button>
          </Swiper>

          <div className="absolute left-1/2 bottom-14 z-[1] transform -translate-x-1/2 flex space-x-4">
            <div className="prev-but text-white border-white border-2 p-3 rounded-full  hover:bg-white hover:text-blue-400   transition">
              <FiArrowLeft size={20} />
            </div>
            <div className="next-but text-white border-white border-2 p-3 rounded-full  hover:bg-white hover:text-blue-400  transition">
              <FiArrowRight size={20} />
            </div>
          </div>
        </div>

        <div>
          <div
            className={`fixed top-0 right-0 rounded-l-xl h-full bg-[#CEE0FF] shadow-lg transform transition-transform duration-500 ease-in-out z-50 ${
              isSheetOpen ? "translate-x-0" : "translate-x-full"
            } w-full sm:w-64 md:w-80 lg:w-96 z-50 overflow-y-auto max-h-screen`}
          >
            <div className=" font-museo">
              <div className="px-6 pt-4">
                <div className="text-black text-lg font-semibold">Details</div>
                <button
                  className="absolute top-5 right-8 text-black"
                  onClick={closeSheet}
                >
                  <IoMdClose />
                </button>
                <div className="border-t mb-4 border-gray-400"></div>
              </div>

              {selectedCheckup && (
                <div>
                  <div className="px-6 py-4">
                    <h3 className="text-lg font-bold text-blue-900">
                      <Link to={`/test/${selectedCheckup?.metaUrl}`}>
                        {selectedCheckup.title}{" "}
                        <span className="font6">
                          {selectedCheckup.subTitle}
                        </span>
                      </Link>
                    </h3>
                    <div className="flex justify-between items-center text-sm font7 mb-4 mt-2">
                      <span className="">
                        <span className="font6">
                          {selectedCheckup?.parameterCount ? selectedCheckup.parameterCount : 0}
                        </span>{" "}
                        Parameters
                      </span>
                      <span>
                        <span className="font6">
                          {selectedCheckup.reportTime}{" "}
                        </span>
                        Reports
                      </span>
                    </div>
                    <div className="flex justify-center items-center mb-4 bg-white rounded-xl p-2 gap-2">
                      {selectedCheckup.offerPrice ? (
                        <>
                          <div className="text-lg font-semibold font6">
                            ₹{selectedCheckup.offerPrice}
                          </div>

                          <div className="text-lg font-semibold line-through text-gray-500 block  ">
                            ₹{selectedCheckup.price}
                          </div>
                          <div className="text-sm font-medium text-[#0B234E]">
                            <span>
                              {percentage(
                                selectedCheckup.price,
                                selectedCheckup.offerPrice
                              )}
                              % off
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-lg font-semibold font6">
                            ₹{selectedCheckup.price}
                          </div>
                        </>
                      )}
                    </div>
                    <button
                      className="bg-[#DF4132] text-white text-center w-full py-2 rounded-lg hover:bg-red-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBuyNow(selectedCheckup._id);
                      }}
                    >
                      {isInCart(selectedCheckup._id) ? (
                        <Link to="/steps" className="w-full block">
                          View Cart
                        </Link>
                      ) : (
                        "Buy Now"
                      )}
                    </button>
                    <div className="text-lg mt-6 text-[#2E3192] font-museo font-bold mb-2">
                      What is it Used For ?
                    </div>
                    <ul>
                      {selectedCheckup.usedFor.map((item, index) => (
                        <li key={index} className="flex items-start mb-2 gap-2">
                          <img
                            src="others/star-svgrepo-com.svg"
                            alt="bullet-icon"
                            width="16px"
                            className="mt-1"
                          />
                          <div className="text-[#666363] text-[16px]">
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="band-gradient w-full">
                    <div className="flex items-center px-6">
                      <div className="text-white font-museo text-sm font-semibold">
                        <p>Home Sample Collection Available</p>
                      </div>
                      <div className="flex items-center justify-end">
                        <img src="others/nurse.png" alt="df" width={"30%"} />
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <h3 className="font-museo font4 text-lg font-semibold mt-3">
                      Package Instructions
                    </h3>
                    <span className="flex ">
                      {" "}
                      <img
                        src="others/star-svgrepo-com.svg"
                        alt="df"
                        width="16px"
                      />{" "}
                      <p className="font-mont font7 text-sm ml-2 font-medium mt-1">
                        {selectedCheckup.instructions}
                      </p>
                    </span>
                  </div>
                  <div className="pt-4 mb-6">
                    <div className="mx-auto px-6 band-gradient text-white">
                      <h3 className="font-museo text-lg font-semibold pb-6 pt-2 text-white">
                        Benefits:
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 pb-6">
                        {benefits.map((benefit) => (
                          <div
                            key={benefit.id}
                            className="text-white rounded-lg flex items-center justify-start shadow-lg"
                          >
                            <div className="bg-white rounded-full p-3">
                              <img
                                src={benefit.imgSrc}
                                alt={benefit.title}
                                className="w-4 h-4"
                              />
                            </div>
                            <h3 className="text-sm ml-1 font-mont">
                              {benefit.title}
                            </h3>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <IncludedTests2 parameters={filteredParameters} />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Sheet Overlay */}
          {isSheetOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={closeSheet}
            ></div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HealthPackages;
