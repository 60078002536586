import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useDispatch } from "react-redux";
import { setRegisterActive } from "../../redux/registerSlice";
import OtpTimer from "otp-timer";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isActiveOtp, setIsActiveOtp] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // Handle number-only input for mobile number
  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setMobileNumber(value);
      setError(""); // clear error if valid
    } else {
      setError("Please enter only numeric values");
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!mobileNumber) {
      setError("Mobile number is required");
    } else if (mobileNumber.length !== 10) {
      setError("Mobile number must be exactly 10 digits");
    } else {
      try {
        // Make API call to send OTP
        const response = await axiosClient.post("users/login", {
          phone: mobileNumber,
        });
        setSuccessMessage(response.data.message);
        setIsActiveOtp(true);
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message || "Failed to send OTP");
          setIsActiveOtp(false);
        } else {
          setError("An error occurred. Please try again.");
        }
      }
    }
  };

  // Handle OTP input
  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if a digit is entered
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    if (!otpString || otpString.length !== 6) {
      setError("OTP must be 6 digits");
    } else {
      try {
        const response = await axiosClient.post("users/verify-otp", {
          phone: mobileNumber,
          otp: otpString,
        });
        if (response.data.success) {
          setSuccessMessage("OTP Verified successfully!");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.user._id);
          setTimeout(() => {
            dispatch(setRegisterActive(false));
          }, 1000);
          // navigate("/"); // Redirect to home or another page
        } else {
          setError("Invalid OTP. Please try again.");
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axiosClient.post("users/resend-otp", {
        phone: mobileNumber,
      });

      if (response.data.success) {
        setSuccessMessage("OTP resent successfully!");
        setError("");
      } else {
        setError("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || "Error resending OTP");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div>
      <div className="w-full flex flex-col">
      <div className="w-full p-4 py-6">
          <h2 className="lg:text-5xl text-2xl font-bold text-center">
            Login / Register
          </h2>


          {!isActiveOtp ? (
            <div className="mt-4 pt-2 border-t border-solid border-top">
              <p className="text-center lg:text-xl text-sm lg:my-5 md:my-4 my-2 italic font-mont">
                Please enter your Mobile No
              </p>

              <form onSubmit={handleSendOtp} className="space-y-4">
                <div>
                  <label
                    htmlFor="mobileNumber"
                    className="block lg:text-xl text-sm font-medium font-mont lg:mb-3 mb-2 pt-3"
                  >
                    Enter Mobile No.
                  </label>
                  <input
                    type="tel"
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    className="w-full px-3 lg:py-3 py-2 border border-color3 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500 lg:text-lg text-sm"
                    placeholder="Enter Registered Mobile no."
                    pattern="[0-9]*"
                    required
                  />
                  {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full bg-card1 text-white font-semibold lg:text-lg text-base lg:py-3 py-2 rounded-lg hover-color1 transition duration-200 font-museo"
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            </div>

          ) : (
            <div className="mt-4 pt-2 border-t border-solid border-top">
              <p className="text-center lg:text-lg text-sm lg:my-5 md:my-4 my-2 italic font-mont">
                Please enter your OTP
              </p>
              <form onSubmit={handleVerifyOtp} className="space-y-4">
                <div>
                  <label
                    htmlFor="otp"
                    className="block lg:text-xl font-medium font-mont lg:mb-3 mb-2 pt-3"
                  >
                    Enter OTP
                  </label>
                  <div className="flex lg:space-x-2 md:space-x-2 space-x-2 items-center justify-start">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="tel"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className="w-8 h-8 xl:w-10 xl:h-10 p-2 border border-color3 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-center lg:text-lg text-sm font-bold"
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    ))}
                  </div>
                  {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                  {successMessage && (
                    <p className="text-green-500 text-sm mt-1">{successMessage}</p>
                  )}
                </div>

                <div className="pt-3">
                  <button
                    type="submit"
                    className="w-full bg-card1 text-white font-semibold lg:text-lg text-base lg:py-3 py-2 rounded-lg hover-color1 transition duration-200 font-museo"
                  >
                    Verify OTP
                  </button>
                </div>
                <span className="text-end">
                <OtpTimer
                  minutes={0}
                  seconds={30}
                  text={
                    <span className="otp-timer-text">Resend OTP In : </span>
                  }
                  ButtonText={
                    <span className="otp-timer-button">Resend OTP</span>
                  }
                  textColor={"#2e3192"}
                  buttonColor={"#2e3192"}
                  background={"#fff"}
                  resend={handleResendOtp}
                />
              </span>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
