import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { Dialog } from "@headlessui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useDispatch } from "react-redux";
import { setRegisterActive } from "../../redux/registerSlice";

function Prescriptions() {

  const dispatch = useDispatch();
  // State to handle the current page
  const [currentPage, setCurrentPage] = useState(1);
  const [prescriptions, setPrescriptions] = useState([]);
  const [expandedPrescription, setExpandedPrescription] = useState(null);
  const itemsPerPage = 3;
  const { filepath } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({
    name: "",
    prescriptionImage: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/"); // Redirect if no token
      setTimeout(() => {
        dispatch(setRegisterActive(true)); // Show the register modal
      }, 1000);
    }
  }, [navigate]);

  const userId = localStorage.getItem("userId");

  const fetchPrescriptions = async () => {
    try {
      const response = await axiosClient.get(`/users/details/${userId}`);
      const data = await response.data;
      if (data.success) {
        setPrescriptions(data.user.prescription);
      } else {
        console.error("Failed to fetch prescriptions");
      }
    } catch (error) {
      console.error("Error fetching prescriptions:", error);
    }
  };

  // Fetch user data when component mounts
  useEffect(() => {
    fetchPrescriptions();
  }, []);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = prescriptions.slice(indexOfFirstItem, indexOfLastItem);

  // Functions to navigate pages
  const nextPage = () => {
    if (currentPage < Math.ceil(prescriptions.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");

    // Validate form data
    if (!prescriptionData.name || !prescriptionData.prescriptionImage) {
      console.error("Please provide both name and prescription image.");
      return;
    }

    // Create FormData to handle file upload
    const formData = new FormData();
    formData.append("name", prescriptionData.name);
    formData.append("prescriptionImage", prescriptionData.prescriptionImage);

    try {
      const response = await axiosClient.post(
        `/users/user/${userId}/prescription`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        // Refresh the prescriptions list
        setPrescriptions([...prescriptions, response.data.prescription]);
        // Reset form data
        setPrescriptionData({
          name: "",
          prescriptionImage: null,
        });
        // Close the modal
        setIsModalOpen(false);
      } else {
        console.error("Failed to upload prescription.");
      }
    } catch (error) {
      console.error("Error uploading prescription:", error);
      console.error("An error occurred while uploading the prescription.");
    }
  };

  const togglePrescriptionExpansion = (index) => {
    if (expandedPrescription === index) {
      setExpandedPrescription(null);
    } else {
      setExpandedPrescription(index);
    }
  };

  // Handle input changes in the modal form
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "prescriptionImage") {
      const file = files[0];

      // Validate file type and size
      if (
        file &&
        !["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
          file.type
        )
      ) {
        iziToast.error({
          title: "Error",
          message: "Invalid file type. Please upload a JPG, PNG, or PDF file.",
          position: "center",
        });
        return;
      }

      if (file && file.size > 10 * 1024 * 1024) {
        iziToast.error({
          title: "Error",
          message: "File size exceeds 10 MB. Please upload a smaller file.",
          position: "center",
        });
        return;
      }

      setPrescriptionData({ ...prescriptionData, prescriptionImage: file });
    } else {
      setPrescriptionData({ ...prescriptionData, [name]: value });
    }
  };

  // Handle deletion of a prescription
  const handleDelete = async (prescriptionId) => {
    const userId = localStorage.getItem("userId");

    iziToast.question({
      timeout: 20000,
      close: false,
      overlay: true,
      displayMode: "once",
      id: "question",
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this prescription?",
      position: "center",
      buttons: [
        [
          "<button><b>YES</b></button>",
          async (instance, toast) => {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            setIsDeleting(true);
            try {
              const response = await axiosClient.delete(
                `/users/user/${userId}/prescription/${prescriptionId}`
              );

              if (response.data.success) {
                // Refresh prescriptions after deletion
                fetchPrescriptions();
                iziToast.success({
                  title: "Deleted",
                  message: "Prescription deleted successfully.",
                  position: "center",
                });
              } else {
                iziToast.error({
                  title: "Error",
                  message: "Failed to delete prescription.",
                  position: "center",
                });
              }
            } catch (error) {
              console.error("Error deleting prescription:", error);
            } finally {
              setIsDeleting(false);
            }
          },
          true,
        ],
        [
          "<button>NO</button>",
          (instance, toast) => {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
        ],
      ],
    });
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="flex-1 p-4 md:p-8">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <h1 className="text-2xl font-semibold font-museo font4 pb-5 sm:pb-0">
                Add Prescriptions
              </h1>
              <div className="w-full sm:w-auto">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="w-full sm:w-auto bg-card1 text-white px-4 py-2 rounded-md flex items-center justify-center space-x-2 hover:bg-red-600 font-museo font7"
                >
                  <FaPlus />
                  <span>Upload Prescription</span>
                </button>
              </div>
            </div>

            <div className="mt-6 h-[570px] overflow-y-auto">
              {currentItems.map((prescription, index) => (
                <div
                  key={index}
                  className="bg-[#F8F8F8] p-4 mb-4 border-b border-b-red-600"
                >
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                    <h3
                      className="font4 font-museo font-semibold mb-2 sm:mb-0"
                      onClick={() => togglePrescriptionExpansion(index)}
                    >
                      {prescription.name}
                    </h3>
                    <button
                      onClick={() => handleDelete(prescription._id)}
                      className="text-red-600 hover:text-red-800 mt-2 sm:mt-0"
                      disabled={isDeleting}
                      title="Delete Prescription"
                    >
                      <FaTrash />
                    </button>
                  </div>
                  {expandedPrescription === index && (
                    <div className="mt-4">
                      {prescription.prescriptionImage.endsWith(".pdf") ? (
                        <embed
                          src={`${filepath}${prescription.prescriptionImage}`}
                          type="application/pdf"
                          width="100%"
                          height="500px"
                          className="border rounded-md"
                        />
                      ) : (
                        <img
                          src={`${filepath}${prescription.prescriptionImage}`}
                          alt={`${prescription.name}`}
                          className="w-full h-auto"
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}

              <div className="flex justify-between mt-4">
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-3 py-2 rounded-md font-museo disabled:opacity-50 text-sm sm:text-base"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-3 sm:px-6 py-2 rounded-md font-museo disabled:opacity-50 text-sm sm:text-base"
                  onClick={nextPage}
                  disabled={
                    currentPage ===
                    Math.ceil(prescriptions.length / itemsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
      >
        <Dialog.Panel className="bg-white rounded-lg shadow-md w-full max-w-lg p-4 sm:p-6 mx-4">
          <Dialog.Title className="text-xl font-bold mb-4 font-museo border-b-2 pb-2">
            Upload Prescription
          </Dialog.Title>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prescription Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={prescriptionData.name}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Enter prescription name"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="prescriptionImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prescription Image
                </label>
                <input
                  type="file"
                  name="prescriptionImage"
                  id="prescriptionImage"
                  accept="image/jpeg, image/png, image/jpg, application/pdf"
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border rounded-md"
                  required
                />
              </div>
            </div>

            <div className="flex justify-end mt-6 space-x-4">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600"
              >
                Upload
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}

export default Prescriptions;
