import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import {
  Table,
  Card,
  Image,
  Button,
  Spinner,
  Alert,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useAuth } from "../../AuthContext";
import { FaDownload, FaEye, FaTrash } from "react-icons/fa";
import formatTime from "../../utils/formatTime";

const UserDetails = () => {
  const { id } = useParams(); // Extract the user ID from the URL
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { filepath } = useAuth();
  const [bookings, setBookings] = useState([]);

  // State for delete confirmation modal
  const [showModal, setShowModal] = useState(false);
  const [prescriptionToDelete, setPrescriptionToDelete] = useState(null);

  // State for image modal
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const navigate = useNavigate(); 

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosClient.get(`users/details/${id}`);

        if (response.data.success) {
          setUser(response.data.user); // Adjust according to your API response
          setLoading(false);
        } else {
          throw new Error("Failed to fetch user details.");
        }
      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch user details."
        );
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const [userResponse, bookingsResponse] = await Promise.all([
          axiosClient.get(`users/details/${id}`),
          axiosClient.get(`/tests-booking/test/booking/user/${id}`),
        ]);

        if (userResponse.data.success) {
          setUser(userResponse.data.user);
        } else {
          throw new Error("Failed to fetch user details.");
        }

        if (bookingsResponse.data.success) {
          setBookings(bookingsResponse.data.bookings);
        } else {
          throw new Error("Failed to fetch booking details.");
        }

        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  // Function to handle prescription deletion
  const deletePrescription = async () => {
    if (!prescriptionToDelete) return;

    try {
      await axiosClient.delete(
        `/users/user/${id}/prescription/${prescriptionToDelete}`
      );
      // After deletion, update the user state to remove the deleted prescription
      setUser({
        ...user,
        prescription: user.prescription.filter(
          (prescription) => prescription._id !== prescriptionToDelete
        ),
      });
      setShowModal(false);
    } catch (err) {
      console.error("Failed to delete prescription:", err);
      setError(err.response?.data?.message || "Failed to delete prescription.");
      setShowModal(false);
    }
  };

  // Function to calculate age based on date of birth
  const calculateAge = (dob) => {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  // Function to handle viewing prescription image in modal
  const viewImage = (imagePath) => {
    setCurrentImage(`${filepath}${imagePath}`);
    setShowImageModal(true);
  };

  const downloadImage = () => {
    fetch(currentImage)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;  // Blob URL of the image
        link.download = 'prescription.png';  // Default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);  // Clean up the object URL
      })
      .catch(error => console.error('Image download error:', error));
  };
  

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading user details...</span>
        </Spinner>
      </div>
    );


  if (!user)
    return (
      <div className="container my-4">
        <Alert variant="warning" className="text-center">
          No user found.
        </Alert>
      </div>
    );

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="container my-4">
          <div className="row">
            <div className="col-lg-12">
              {/* User Basic Information */}
              <Card className="mb-4 shadow-sm">
                <Card.Header as="h4">User Basic Information</Card.Header>
                <Card.Body>
                  <Table striped bordered hover responsive className="mb-0">
                    <tbody>
                      <tr>
                        <td>
                          <strong>First Name:</strong> {user.firstName}
                        </td>
                        <td>
                          <strong>Last Name:</strong> {user.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Phone:</strong> {user.phone}
                        </td>
                        <td>
                          <strong>Email:</strong> {user.email}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Date of Birth:</strong>{" "}
                          {new Date(user.dob).toLocaleDateString("en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                        </td>
                        <td>
                          <strong>Gender:</strong>{" "}
                          {user.gender.charAt(0).toUpperCase() +
                            user.gender.slice(1)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Age:</strong> {calculateAge(user.dob)}
                        </td>
                        <td>
                          <strong>Joined On:</strong>{" "}
                          {new Date(user.createdAt).toLocaleDateString("en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role:</strong>{" "}
                          {user.role.charAt(0).toUpperCase() +
                            user.role.slice(1)}
                        </td>
                        <td>
                          <strong>Verified:</strong>{" "}
                          {user.isVerified ? "Yes" : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              {/* Addresses Section */}
              <Card className="mb-4 shadow-sm">
                <Card.Header as="h5">Addresses</Card.Header>
                <Card.Body>
                  {user.addresses && user.addresses.length > 0 ? (
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>S.No.</th>
                          <th>Save As</th>
                          <th>House No</th>
                          <th>Street</th>
                          <th>Landmark</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Pincode</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.addresses.map((address, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{address.save_as}</td>
                            <td>{address.house_no}</td>
                            <td>{address.street}</td>
                            <td>{address.landmark}</td>
                            <td>{address.city}</td>
                            <td>{address.state}</td>
                            <td>{address.pincode}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No addresses available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>

              {/* Family Members Section */}
              <Card className="mb-4 shadow-sm">
                <Card.Header as="h5">Family Members</Card.Header>
                <Card.Body>
                  {user?.familyMemberRefs && user?.familyMemberRefs.length > 0 ? (
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>S.No.</th>
                          <th>Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Relation</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user?.familyMemberRefs.map((member, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{member.name}</td>
                            <td>{member.age}</td>
                            <td>
                              {member.gender.charAt(0).toUpperCase() +
                                member.gender.slice(1)}
                            </td>
                            <td>{member.relation}</td>
                            <td>{member.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No family members available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>

              {/* Prescriptions Section */}
              <Card className="mb-4 shadow-sm">
                <Card.Header as="h5">Prescriptions</Card.Header>
                <Card.Body>
                  {user.prescription && user.prescription.length > 0 ? (
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>S.No.</th>
                          <th>Name</th>
                          <th>Prescription Image</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.prescription.map((prescription, index) => (
                          <tr key={prescription._id}>
                            <td>{index + 1}</td>
                            <td>{prescription.name}</td>
                            <td className="text-center">
                              {prescription.prescriptionImage ? (
                                <Image
                                  src={`${filepath}${prescription.prescriptionImage}`}
                                  alt={prescription.name}
                                  thumbnail
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    viewImage(prescription.prescriptionImage)
                                  }
                                />
                              ) : (
                                "No Image"
                              )}
                            </td>
                            <td className="text-center">
                              {/* View Button with Tooltip */}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`tooltip-view-${prescription._id}`}
                                  >
                                    View Prescription
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="info"
                                  size="sm"
                                  className="me-2"
                                  onClick={() =>
                                    viewImage(prescription.prescriptionImage)
                                  }
                                >
                                  <FaEye />
                                </Button>
                              </OverlayTrigger>

                              {/* Delete Button with Tooltip */}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`tooltip-delete-${prescription._id}`}
                                  >
                                    Delete Prescription
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    setPrescriptionToDelete(prescription._id);
                                    setShowModal(true);
                                  }}
                                >
                                  <FaTrash />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No prescriptions available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>

              {/* Medical History Section */}
              <Card className="mb-4 shadow-sm">
                <Card.Header as="h5">Medical History</Card.Header>
                <Card.Body>
                  {user.height ||
                  user.weight ||
                  user.hasCovid !== undefined ||
                  user.diabetes !== undefined ||
                  user.thyroid !== undefined ||
                  user.bloodPressure ||
                  user.heartDisease !== undefined ||
                  user.pain !== undefined ||
                  user.otherRemark ? (
                    <Table striped bordered hover responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Height</strong>
                          </td>
                          <td>{user.height || "N/A"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Weight</strong>
                          </td>
                          <td>{user.weight || "N/A"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Had Covid</strong>
                          </td>
                          <td>{user.hasCovid ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Diabetes</strong>
                          </td>
                          <td>{user.diabetes ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Thyroid</strong>
                          </td>
                          <td>{user.thyroid ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Blood Pressure</strong>
                          </td>
                          <td>{user.bloodPressure || "N/A"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Heart Disease</strong>
                          </td>
                          <td>{user.heartDisease ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Pain</strong>
                          </td>
                          <td>{user.pain ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Other Remarks</strong>
                          </td>
                          <td>{user.otherRemark || "N/A"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No medical history available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-4 shadow-sm">
                <Card.Header as="h5">Test Booking Details</Card.Header>
                <Card.Body>
                  {bookings && bookings.length > 0 ? (
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>Booking ID</th>
                          <th>Total Amount</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Test Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((booking) => (
                          <tr key={booking._id}>
                          <td><Link to={`/bookings/${booking?._id}`}>{booking?.bookingId}</Link></td>
                            <td>₹{booking.totalAmount}</td>
                            <td>
                              {new Date(booking.createdAt).toLocaleDateString("en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                            </td>
                            <td>{booking?.timeSlot ? (`${formatTime(booking?.timeSlot?.from)} - ${formatTime(booking?.timeSlot?.to)}`) : ""}</td>
                            <td>
                              {new Date(booking.testDate).toLocaleDateString("en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No test bookings available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this prescription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deletePrescription}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Image Modal */}
      <Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
        
          <Modal.Title>Prescription Image</Modal.Title>
          <FaDownload
          onClick={downloadImage}
          style={{ marginLeft: '10px', cursor: 'pointer'}}  
          title="Download Image"
        />
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={currentImage} alt="Prescription" fluid />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserDetails;
