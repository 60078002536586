import { useState } from "react";

const OpenSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#1e4fa6"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 12l-7.5 7.5L4.5 12"
    />
  </svg>
);

const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#1e4fa6"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 12l7.5-7.5L19.5 12"
    />
  </svg>
);

function AccordionItem({ title, parameters }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-300 rounded-lg shadow-md overflow-hidden">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center cursor-pointer p-4 bg-white hover:bg-gray-100"
      >
        <h3 className="font-semibold text-lg font-museo font1">
          {/* {title} ({parameters.length}) */}
          {title}
        </h3>
        <span>{isOpen ? <OpenSvg /> : <CloseSvg />}</span>
      </div>
      {isOpen && (
        <div className="bg-gray-50 p-4">
          <ul className="space-y-1 text-sm font7 font-mont">
            {parameters.map((parameter, index) => (
              <li key={index} className="list-disc list-inside">
                {parameter}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default function IncludedTests2({ parameters }) {
  // If no parameters are passed, show loading or empty state
  if (!parameters || parameters.length === 0) {
    return (
      <div className="max-w-screen-2xl mx-auto py-2 px-6">
        <h2 className="text-lg font-semibold mb-2 font-museo font4">
          Included Tests
        </h2>
        <p className="font-mont font7 mb-8 text-base text-md">
          No parameters available
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-screen-2xl mx-auto py-2 px-6">
      <h2 className="text-lg font-semibold mb-2 font-museo font4">
        Included Tests
      </h2>
      <p className="font-mont font7 mb-4 text-base text-md">
        Package includes the following parameters:
      </p>
      <div className="grid grid-cols-1 gap-2">
        {parameters.map((paramSet, index) => (
          <AccordionItem
            key={index}
            title={paramSet.title}
            parameters={paramSet.parameters}
          />
        ))}
      </div>
    </div>
  );
}
