import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Description from "../Description";
import { Link } from "react-router-dom";
import SideSheet from "../SideSheet";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import {  percentage } from "../../constants/constant";

function FindTests() {
  const [visibleCards, setVisibleCards] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [tests, setTests] = useState([]);
  const [categoryLookup, setCategoryLookup] = useState({});
  const { addToCart, isInCart } = useAuth();
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleTestTitleClick = (test) => {
    setSelectedCheckup(test);
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
  };

  const handleBuyNow = (testId) => {
    addToCart(testId);
  };

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient.get("/test-categories/test/categories");
        const categories = response.data.testCategory || [];
        const lookup = {};
        categories.forEach((category) => {
          lookup[category._id] = category.name;
        });
        setCategoryLookup(lookup);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch all tests initially
  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("/tests/tests");
        setTests(response.data.test || []);
      } catch (error) {
        console.error("Error fetching tests:", error);
      }
    };

    fetchTests();
  }, []);

  // Debounced search
  useEffect(() => {
    const fetchFilteredTests = async () => {
      try {
        const response = await axiosClient.get("/tests/tests", {
          params: { keyword: searchTerm },
        });
        setTests(response.data.test || []);
      } catch (error) {
        console.error("Error fetching filtered tests:", error);
      }
    };

    const debounceTimeout = setTimeout(() => {
      if (searchTerm.trim()) {
        fetchFilteredTests();
      } else {
        // Fetch all tests again when search is cleared
        const fetchTests = async () => {
          try {
            const response = await axiosClient.get("/tests/tests");
            setTests(response.data.test || []);
          } catch (error) {
            console.error("Error fetching tests:", error);
          }
        };
        fetchTests();
      }
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);


  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex justify-center items-center space-x-4 mb-8">
          <div className="relative w-2/3">
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/images/search.png`}
                alt="Search"
                width="18px"
                height="18px"
                className="absolute left-3 top-3 sm:top-5"
              />
              <input
                type="text"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full py-2 sm:py-4 pl-9 pr-14 font-mont rounded-md border border-gray-300 shadow-md focus:outline-none focus:border-blue-500"
              />
            </span>
          </div>
        </div>

        {/* Test Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {tests.slice(0, visibleCards).map((test, index) => (
            <div
              key={test._id || index} // Use a unique key if available
              className=" flex flex-col max-w-md rounded-2xl shadow-lg border border-gray-200 p-6"
            >
              <h2
                className="text-lg font-bold font4 font-museo"
                onClick={() => handleTestTitleClick(test)}
              >
                {test.title}
                {test.subTitle && <span className="font6"> {test.subTitle}</span>}
              </h2>

              <Description text={test.description} />

              <div className="mt-4 text-sm flex-grow flex flex-col justify-end">
                <p className="font7 font-mont">
                  <span className="font-bold font6">Category:</span>{" "}
                  {categoryLookup[test.testCategory] || "Unknown"}
                </p>
                <div className="grid grid-cols-2 mt-2">
                  <span className="mr-4 font6 font-semibold font-mont">
                    {test?.parameterCount} <span className="font7">Parameters</span>
                  </span>
                  <span className="mr-4 font6 font-semibold font-mont">
                    {test.reportTime} <span className="font7">Reports</span>
                  </span>
                  <span className="mr-4 font6 font-semibold font-mont">
                    {test.fastingTime} <span className="font7">Fasting</span>
                  </span>
                  <span className="font6 font-semibold font-mont">
                    {test.tests} 10 <span className="font7">Tests</span>
                  </span>
                </div>
              </div>

              <div className="mt-4 flex justify-between items-end">
                <div className="bg-[#1E4FA642] px-4 lg:py-1 py-2 rounded-lg flex">
                  {test.offerPrice ? (
                    <>
                      <div className="">
                        <span className="block text-left">
                          ₹{test?.offerPrice}
                        </span>
                        <span className="text-gray-500 block text-left line-through text-base font-bold">
                          ₹{test.price}
                        </span>
                      </div>
                      <div className="border-l flex flex-col border-solid border-[#888888] flex-grow pl-2 font5 lg:text-sm text-xs font-medium ml-2">
                        <span className="text-xl font-bold text-center">{percentage(test.price,test.offerPrice)}% off</span>
                        {/* <span className="text-xs">for a limited period</span> */}
                      </div>
                    </>
                  ) : (
                    <span>₹{test.price}</span>
                  )}
                </div>

                <button
                  className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent opening the details sheet
                    handleBuyNow(test._id);
                  }}
                >
                  {isInCart(test._id) ? (
                    <Link to="/steps" className="w-full block">
                      View Cart
                    </Link>
                  ) : (
                    "Buy Now"
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* View More Button */}
        {visibleCards < tests?.length && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => setVisibleCards((prev) => prev + 6)}
              className="bg-card1 font-museo text-white rounded-lg px-6 py-2 font-semibold hover:bg-red-600 transition duration-200"
            >
              View More
            </button>
          </div>
        )}
      </div>
      <NewsLetter />
      <Footer />
      <SideSheet
        selectedCheckup={selectedCheckup}
        isSheetOpen={isSheetOpen}
        closeSheet={closeSheet}
      />
    </>
  );
}

export default FindTests;