import { useSelector } from "react-redux";

const useCheckPermission = () => {
  const { user } = useSelector((state) => state?.user?.user);
  console.log(user, 122);
  /**
   * Function to check if the user has a specific permission
   * @param {string} module - The module name (e.g., 'orders', 'users').
   * @param {string} action - The action name (e.g., 'view', 'edit', 'delete').
   * @returns {boolean} - True if the user has permission, otherwise false.
   */
  const hasPermission = (module, action) => {
    if (
      user &&
      user.permissions &&
      user.permissions[module] &&
      user.permissions[module][action]
    ) {
      return true;
    }
    return false;
  };

  return hasPermission;
};

export default useCheckPermission;
