import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

export const WhyChooseUsSwiper = ({ features, filepath }) => {
  return (
    <div className="w-full max-w-screen-2xl mx-auto relative py-8">
      {/* <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-black py-4 text-center font-museo">
        Why <span className="font1">Choose Us?</span>
      </h2> */}
      <div className="relative mt-3">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 5 },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {features.map((feature, index) => (
            <SwiperSlide key={index}>
              <div className="flex items-center justify-content-around">
                {/* Feature Content */}
                <div className="flex flex-col items-center justify-center gap-2">
                  <img
                    src={`${filepath}${feature.imgSrc}`}
                    alt={feature.title}
                    className="w-16 h-16"
                  />
                  <p className="font-mont text-center">{feature.title}</p>
                </div>

                {/* Vertical Divider */}
                {index < features.length - 1 && (
                  <div className="hidden md:block bg-[#1E4FA699] h-24 w-px mx-4"></div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Navigation Buttons */}
        <div className="swiper-prev p-3 rounded-full border border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all absolute left-4 top-1/2 transform -translate-y-1/2 z-10">
          <FiArrowLeft size={22} />
        </div>
        <div className="swiper-next p-3 rounded-full border border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all absolute right-4 top-1/2 transform -translate-y-1/2 z-10">
          <FiArrowRight size={22} />
        </div>
      </div>
    </div>
  );
};

// Main Component
const WhyChooseUs = () => {
  const [features, setFeatures] = useState([]);
  const { filepath } = useAuth();

  useEffect(() => {
    const fetchHomeTesting = async () => {
      try {
        const response = await axiosClient(`/why-choose-us/steps`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        setFeatures(response.data.whyChooseUs || []);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchHomeTesting();
  }, []);

  return (
    <div className="bg-grad flex flex-col items-center max-w-screen-2xl mx-auto relative">
      <WhyChooseUsSwiper features={features} filepath={filepath} />
    </div>
  );
};

export default WhyChooseUs;
