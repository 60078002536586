import { useEffect, useState } from "react";
import AddPatientsCart from "./AddPatientsCart";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { BsPersonPlusFill } from "react-icons/bs";
import iziToast from "izitoast";


const AddPatients = ({ nextStep }) => {
  const [allPatients, setAllPatients] = useState([]); // All available patients
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({
    name: "",
    relation: "",
    age: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { cart } = useAuth();

  const patientsPerPage = 3;

  const fetchTests = async () => {
    if (cart.length === 0) {
      setTests([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const promises = cart.map((testId) =>
        axiosClient.get(`/tests/test/${testId}`)
      );

      const results = await Promise.all(promises);
      const fetchedTests = results.map((result) => result.data.test);
      setTests(fetchedTests);
    } catch (err) {
      console.error("Error fetching tests:", err);
      setError("Failed to fetch tests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
  }, [cart]);

  useEffect(() => {
    if (tests.length > 0) {
      updateTotalAmount(selectedPatients.length);
    }
  }, [tests, selectedPatients.length]);  
  const calculateBasePrice = () => {
  // get price from tests
    if (tests.length === 0) {
      return 0;
    }       
    return tests.reduce((total) => total + (tests[0].offerPrice ),0);
  };

  const updateTotalAmount = (patientCount) => {
    const basePrice = calculateBasePrice();
    const newTotalAmount = patientCount * basePrice;
    setTotalAmount(newTotalAmount);
  };

  const fetchUserData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response = await axiosClient(`/users/details/${userId}`);
        const data = await response.data;
        if (data.success) {
          setAllPatients(data.user?.familyMemberRefs);
          setSelectedPatients(data.user?.familyMemberRefs); // Initially select all patients
          updateTotalAmount(data.user?.familyMemberRefs.length);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Create a Date object from the string
    const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month (0-indexed)
    const year = date.getFullYear(); // Get the full year

    return `${day}/${month}/${year}`; // Return in DD/MM/YYYY format
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const totalPages = Math.ceil(selectedPatients.length / patientsPerPage);

  const openEditModal = (patient) => {
    setCurrentPatient(patient);
    setEditModalOpen(true);
  };

  const handleRefresh = () => {
    fetchUserData();
  };

  const openAddModal = () => {
    setCurrentPatient(null);
    setAddModalOpen(true);
  };

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setCurrentPatient(null);
    fetchUserData();
  };

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSave = () => {
    if (isEditModalOpen && currentPatient) {
      setSelectedPatients((prev) =>
        prev.map((patient) =>
          patient.id === currentPatient.id ? currentPatient : patient
        )
      );
    } else if (isAddModalOpen) {
      setSelectedPatients((prev) => [
        ...prev,
        { ...currentPatient, id: prev.length + 1 },
      ]);
    }
    closeModal();
    updateTotalAmount(selectedPatients.length + (isAddModalOpen ? 1 : 0));
  };

  const handleContinue = async () => {
    const bookingIds = localStorage.getItem("bookingId");
    if (!bookingIds) {
      console.error("Booking ID not found in local storage");
      return;
    }

    const familyMembersId = selectedPatients.map((patient) => patient._id);

    try {
      const response = await axiosClient.put(
        `/tests-booking/test/booking/${bookingIds}`,
        {
          familyMembers_id: familyMembersId.length ? familyMembersId : [],
          totalAmount: totalAmount,
        }
      );

      if (response.data.success) {
        // Check if familyMembers_id is empty
        if (
          response.data.booking.familyMembers_id && // Ensure `familyMembers_id` exists in the response
          response.data.booking.familyMembers_id.length === 0
        ) {
          console.error("Error: familyMembers_id is empty.");
          // alert("Please select at least one family member before proceeding.");
          iziToast.error({
            message: "Please select at least one family member before proceeding",
            position: "topCenter"
          });
          return; // Stop execution to avoid moving to the next step
        }
    
        // Proceed to the next step if all conditions are met
        nextStep();
      }
    } catch (error) {
      console.error("Error updating booking:", error);
    }
  };

  const handlePatientRemove = async (patientId) => {
    try {
      // Update selected patients list
      const updatedSelectedPatients = selectedPatients.filter(
        (patient) => patient._id !== patientId
      );
      setSelectedPatients(updatedSelectedPatients);

      // Update total amount
      updateTotalAmount(updatedSelectedPatients.length);

      // Update booking with new patient list
      const bookingIds = localStorage.getItem("bookingId");
      if (bookingIds) {
        const familyMembersId = updatedSelectedPatients.map(
          (patient) => patient._id
        );

        await axiosClient.put(`/tests-booking/test/booking/${bookingIds}`, {
          familyMembers_id: familyMembersId,

          // familyMembers_id: updatedSelectedPatients.map((p) => p._id),
          totalAmount: calculateBasePrice() * updatedSelectedPatients.length,
        });
      }
      updateTotalAmount(updatedSelectedPatients.length);
    } catch (error) {
      console.error("Error updating booking:", error);
    }
  };

  return (
    <div className="p-4 sm:p-6 lg:p-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white border border-gray-300 p-6 rounded-xl shadow-md">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Add <span className="font1">Patients</span>
          </h3>

          <div className="space-y-4">
            {selectedPatients.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-10 bg-gray-50 rounded-lg">
                <BsPersonPlusFill className="w-20 h-20 text-gray-400 mb-4" />
                <h3 className="font-museo text-xl text-gray-600 mb-2">
                  No Patients Added
                </h3>
                <p className="font-museo text-gray-500 text-center mb-4">
                  Click the "Add More Patients" button to get started
                </p>
                <button
                  className="bg-card1 text-white px-8 py-3 rounded-md font-museo font-semibold"
                  onClick={openAddModal}
                >
                  + Add More Patients
                </button>
              </div>
            ) : (
              selectedPatients
                .slice(
                  currentPage * patientsPerPage,
                  (currentPage + 1) * patientsPerPage
                )
                .map((patient) => (
                  <div
                    key={patient?.id}
                    className="flex justify-between items-center py-3 px-4 bg-[#f8f8f8] border-b border-b-red-500"
                  >
                    <div>
                      <h3 className="font-semibold font-museo text-base">
                        {patient?.name}
                      </h3>
                      <div className="grid grid-cols-1 gap-2 font-museo mt-2 font-medium">
                        <p>
                          Age: <span className="font7">{patient?.age}</span>
                        </p>
                        <p>
                          DOB:{" "}
                          <span className="font7">
                            {patient?.dob ? formatDate(patient.dob) : "N/A"}
                          </span>
                        </p>
                        {patient?.email && (
                          <p>
                            Email:{" "}
                            <span className="font7">{patient.email}</span>
                          </p>
                        )}
                        {patient?.phone && (
                          <p>
                            Phone No.:{" "}
                            <span className="font7">{patient.phone}</span>
                          </p>
                        )}

                        <p>
                          Gender:{" "}
                          <span className="font7">{patient?.gender}</span>
                        </p>
                      </div>
                    </div>
                    <button
                      className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                      onClick={() => openEditModal(patient)}
                    >
                      Edit
                    </button>
                  </div>
                ))
            )}

            {selectedPatients.length > 0 && (
              <div className="flex justify-between mt-4">
                <button
                  className={`border-color2 font4 hover-color2 hover:text-white px-4 py-2 rounded-md font-museo ${
                    currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={currentPage === 0}
                  onClick={goToPrevPage}
                >
                  Previous
                </button>
                <button
                  className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo ${
                    currentPage === totalPages - 1
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={currentPage === totalPages - 1}
                  onClick={goToNextPage}
                >
                  Next
                </button>
              </div>
            )}
          </div>

          {selectedPatients.length > 0 && (
            <div className="flex items-center justify-center mt-4 font-museo font-semibold text-lg">
              <button
                className="w-full sm:w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg"
                onClick={openAddModal}
              >
                + Add More Patients
              </button>
            </div>
          )}
        </div>

        <AddPatientsCart
          isAdding={isAddModalOpen}
          isEditing={isEditModalOpen}
          setIsAdding={setAddModalOpen}
          setIsEditing={setEditModalOpen}
          currentPatient={currentPatient}
          handleSave={handleSave}
          onRefresh={handleRefresh}
          onRemove={handlePatientRemove} // Pass the remove handler instead of delete
          allPatients={allPatients} // Pass all available patients
          selectedPatients={selectedPatients} // Pass currently selected patients
        />

        <div className="bg-white p-6 rounded-xl shadow-md border border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          <div className="space-y-8">
            <div className="py-3 px-5 bg-[#f8f8f8] border-b border-b-red-500">
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Total Amount</p>
                <p className="font7">₹{totalAmount.toFixed(2)}</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between mt-1">
                <p className="font6">Amount to be paid</p>
                <p className="font6">₹{totalAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <button
              className="w-full sm:w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg hover:bg-blue-600"
              onClick={handleContinue}
            >
              Continue To Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatients;
