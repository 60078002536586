import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container, Alert } from "react-bootstrap";
import SetAdminUserPermissionUser from "./SetAdminUserPermission";
import axiosClient from "../../axios-client";

const defaultPermissionValue = {
  users: { view: false, add: false, edit: false, delete: false },
  labs: { view: false, add: false, edit: false, delete: false },
  doctor: { view: false, add: false, edit: false, delete: false },
  customerReview: { view: false, add: false, edit: false, delete: false },
  newsLetter: { view: false, add: false, edit: false, delete: false },
  generalDetails: { view: false, add: false, edit: false, delete: false },
  giftCard: { view: false, add: false, edit: false, delete: false },
  contactUs: { view: false, add: false, edit: false, delete: false },
  testBooking: { view: false, add: false, edit: false, delete: false },
  test: { view: false, add: false, edit: false, delete: false },
  policy: { view: false, add: false, edit: false, delete: false },
  frontendOptions: { view: false, add: false, edit: false, delete: false },
};

const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  dob: "",
  gender: "",
  role: "staff",
  age: 0,
  password: "",
  homeCollectionAgent: false,
};

const AddAdminUser = ({ show, setShow, selectedUser, fetchUsers }) => {
  const [showPermission, setShowPermission] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [permissions, setPermissions] = useState(
    selectedUser?.permissions || defaultPermissionValue
  );
  const [errors, setErrors] = useState({}); // To store validation errors

  const handleClose = () => {
    setShow(false);
    setFormData(defaultFormData);
    setPermissions(defaultPermissionValue);
    setErrors({}); // Reset errors
  };

  const handleShow = () => setShow(true);

  const resetPermissions = () => setPermissions(defaultPermissionValue);

  // Validation functions
  const validateForm = () => {
    const newErrors = {};

    // First Name
    if (!formData.firstName) newErrors.firstName = "First Name is required.";

    // Last Name
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    // Date of Birth (Age validation)
    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required.";
    }

    // Gender validation
    if (!formData.gender) newErrors.gender = "Gender is required.";

    // Role validation
    if (!formData.role) newErrors.role = "Role is required.";

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "role" && value === "admin") {
      setPermissions({
        users: { view: true, add: true, edit: true, delete: true },
        labs: { view: true, add: true, edit: true, delete: true },
        doctor: { view: true, add: true, edit: true, delete: true },
        customerReview: { view: true, add: true, edit: true, delete: true },
        newsLetter: { view: true, add: true, edit: true, delete: true },
        generalDetails: { view: true, add: true, edit: true, delete: true },
        giftCard: { view: true, add: true, edit: true, delete: true },
        contactUs: { view: true, add: true, edit: true, delete: true },
        testBooking: { view: true, add: true, edit: true, delete: true },
        test: { view: true, add: true, edit: true, delete: true },
        policy: { view: true, add: true, edit: true, delete: true },
        frontendOptions: { view: true, add: true, edit: true, delete: true },
      });
    } else if (name === "role" && value === "staff") resetPermissions();

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      homeCollectionAgent: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submitting
    if (validateForm()) {
      console.log("Form Data Submitted:", formData);
      console.log("Permissions:", permissions);
      if (selectedUser) {
        try {
          await axiosClient.post(`admin/users/update/${selectedUser?._id}`, {
            ...formData,
            permissions,
          });
          handleClose();
          fetchUsers();
        } catch (error) {
          console.error("Error updating user:", error);
        }
      } else {
        try {
          await axiosClient.post("admin/users/add", {
            ...formData,
            permissions,
          });
          fetchUsers();
          handleClose();
        } catch (error) {
          console.error("Error adding user:", error);
        }
      }
    }
  };

  const handlePermissionShow = () => {
    setShow(false); // Close the main modal
    setShowPermission(true); // Open the permission modal
  };

  const handlePermissionClose = () => {
    setShowPermission(false); // Close the permission modal
    setShow(true); // Reopen the main modal (if needed)
  };

  // Automatically calculate age when the 'dob' changes
  useEffect(() => {
    if (formData.dob) {
      const dob = new Date(formData.dob);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const month = today.getMonth();
      if (
        month < dob.getMonth() ||
        (month === dob.getMonth() && today.getDate() < dob.getDate())
      ) {
        age--;
      }
      setFormData((prevData) => ({
        ...prevData,
        age: age,
      }));
    }
  }, [formData.dob]); // Trigger the effect whenever 'dob' changes


  useEffect(() => {
    if (selectedUser) {
      console.log(selectedUser);
      setPermissions(selectedUser?.permissions);
      setFormData({
        firstName: selectedUser?.firstName,
        lastName: selectedUser?.lastName,
        email: selectedUser?.email,
        phone: selectedUser?.phone,
        dob: selectedUser?.dob,
        gender: selectedUser?.gender,
        role: selectedUser?.role,
        age: selectedUser?.age,
        password: selectedUser?.password,
        homeCollectionAgent: selectedUser?.homeCollectionAgent,
      });
    }
  }, [selectedUser]);

  return (
    <Container className="mt-4 text-end px-0">
      <Button
        variant="primary"
        className="ms-auto d-inline-block"
        onClick={handleShow}
      >
        Add User
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">User Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(errors).length > 0 && (
            <Alert variant="danger">
              <ul>
                {Object.values(errors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                isInvalid={errors.firstName}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                isInvalid={errors.lastName}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={errors.email}
              />
            </Form.Group>
            {selectedUser ? "" : (
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    isInvalid={errors.password}
                  />
                </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                isInvalid={errors.phone}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                isInvalid={errors.dob}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                isInvalid={errors.gender}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                isInvalid={errors.role}
              >
                <option value="staff">Staff</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </Form.Select>
            </Form.Group>

            {/* Conditional checkbox for 'homeCollectionAgent' when role is staff */}
            {formData.role === "staff" && (
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Home Collection Agent?"
                  name="homeCollectionAgent"
                  checked={formData.homeCollectionAgent}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            )}

            <div className="d-flex align-items-center justify-content-center pt-3">
              <Button
                variant="primary"
                type="submit"
                className="d-inline-block whitespace-nowrap"
              >
                {selectedUser ? "Update User" : "Add User"}
              </Button>
              {formData.role !== "admin" &&
                formData.role !== "user" &&
                selectedUser?.role !== "user" && (
                  <SetAdminUserPermissionUser
                    permissions={permissions || {}}
                    setPermissions={setPermissions}
                    show={showPermission}
                    setShow={setShowPermission}
                    resetPermissions={resetPermissions}
                  />
                )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AddAdminUser;
