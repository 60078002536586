import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import axiosClient from "../../axios-client";

const SearchSuggestions = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  // Close suggestions when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch suggestions from API
  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axiosClient.get(`/tests/tests`, {
        params: { keyword: query }, // Pass the keyword as a query parameter
      });
      const data = response.data;

      if (data.success && data.test) {
        setSuggestions(data.test);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce search input
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchSuggestions(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    const cleanedTitle = suggestion.title.replace(/\s*\(.*?\)\s*/g, "");
    setSearchQuery(suggestion.title);
    setShowSuggestions(false);
    navigate(`/test/${suggestion?.metaUrl}`);
  };

  const handleArrowClick = () =>{
    navigate(`/find-tests`);
  }

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <span>
        <img
          src={`${process.env.PUBLIC_URL}/images/search.png`}
          alt="search"
          width="18px"
          height="18px"
          className="absolute left-3 top-3 sm:top-5"
        />
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Find your test or package"
          className="w-full py-2 sm:py-4 pl-9 pr-14 font-mont rounded-md border border-gray-300 shadow-md focus:outline-none focus:border-blue-500"
        />
      </span>
      <button className="absolute right-3 top-3 sm:top-5 text-xl" onClick={handleArrowClick}>
        <img src="images/arrow.png" alt="arrow" width="17px" height="17px" />
      </button>

      {/* Suggestions dropdown */}
      {showSuggestions && (suggestions.length > 0 || loading) && (
        <div className="absolute z-10 w-full bg-white shadow-lg border border-gray-200 rounded-br-lg">
          {loading ? (
            <div className="p-4 text-center text-gray-500">Loading...</div>
          ) : (
            <ul className="max-h-60 overflow-y-auto">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion._id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="px-4 py-3 hover:bg-gray-100 hover:text-red-500 cursor-pointer flex items-center"
                >
                  <span className="flex-grow">{suggestion.title}</span>
                  <GoArrowUpRight />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchSuggestions;
