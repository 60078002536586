import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";

function Footer() {
  const [info, setInfo] = useState();
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axiosClient.get("details");

        if (response.data.success) {
          setInfo(response.data.generalDetails);
          // console.log("Info",response.data.generalDetails)
        } else {
          throw new Error("Failed to fetch info.");
        }
      } catch (err) {
        console.error(err.message || "Failed to fetch info. Please try again.");
      }
    };

    fetchInfo();
  }, []);

  return (
    <div className="bg-secondary1">
      <footer className="mx-auto max-w-screen-2xl px-4 md:px-12 lg:px-20 md:py-14 py-8">
        <div className="mb-16 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-5 lg:gap-10 md:gap-14 lg:pt-12 w-full">
          <div className="col-span-full lg:col-span-2">
            <div className="mb-4 lg:-mt-2">
              <a
                href="/"
                className="inline-flex items-center gap-2 text-xl font-bold text-white md:text-2xl"
                aria-label="logo"
              >
                <img
                  src={`${process.env.PUBLIC_URL}images/LabsLogo.png`}
                  alt="logo"
                  className="h-[131px] w-100"
                />
              </a>
            </div>

            <p className="mb-6 text-white text-sm leading-5 sm:pr-8 font-mont w-3/4">
              We are on a mission to provide quality healthcare services at
              affordable rates making diagnostics easily accessible and
              convenient to one and all.
            </p>

            <div className="flex md:gap-4 gap-2  h-10">
              <Link
                to="https://www.facebook.com/profile.php?id=100090442359211"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/Facebook.png`}
                  alt="gf"
                  className="bg-[#363B4766] p-4 hover:bg-[#23BDEE] w-10 h-full "
                />
              </Link>

              <Link
                to="https://www.instagram.com/palve_hospital/"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/Instragarm.png`}
                  alt="gf"
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE] w-10  h-full"
                />
              </Link>
              <Link
                to="https://www.linkedin.com/company/dr-palve-hospital-private-limited/?viewAsMember=true"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/Linkedin.png`}
                  alt="gf"
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE] w-10 h-full"
                />
              </Link>
              <Link
                to="https://api.whatsapp.com/send?phone=8149202506"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/Twitter.png`}
                  alt="gf"
                  width={"40"}
                  height={"40"}
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE] w-10 h-full"
                />
              </Link>
              <Link
                to="https://www.youtube.com/@drpalvehospitalpvtltd-jp1yi"
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/Youtube.png`}
                  alt="gf"
                  className="bg-[#363B4766] p-3 hover:bg-[#23BDEE] w-10 h-full"
                />
              </Link>
            </div>
          </div>

          <div className="md:col-span-1 col-span-full">
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Reach us
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link
                  to={`tel:${info?.phoneNumber}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/phn.png`}
                    alt="fg"
                    className="w-4 h-4"
                  />
                  <span className="text-white font-mont">
                    {info?.phoneNumber}
                  </span>
                </Link>
              </div>

              <div>
                <Link
                  to={`mailto:${info?.supportEmail}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mail.png`}
                    alt="fg"
                    className="w-4 h-3 flex-shrink-0"
                  />
                  <span className="text-white font-mont break-all">
                    {info?.supportEmail}
                  </span>
                </Link>
              </div>

              <div>
                <Link to={""} className="flex items-center gap-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/loc.png`}
                    alt="fg"
                    className="w-4 h-5"
                  />
                  <span className="text-white font-mont">
                    Pune, Maharashtra
                  </span>
                </Link>
              </div>
            </nav>
          </div>

          <div>
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Company
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link to={"/about"} className="font-mont text-white">
                  About
                </Link>
              </div>

              <div>
                <Link to={"/contact"} className="font-mont text-white">
                  Contact Us
                </Link>
              </div>

              {/* <div>
                <Link to={""} className="font-mont text-white">
                  Events
                </Link>
              </div> */}
            </nav>
          </div>

          <div>
            <div className="mb-4 font-bold font-museo tracking-widest text-gray-100">
              Legal
            </div>

            <nav className="flex flex-col gap-4">
              <div>
                <Link to={"/privacy"} className="font-mont text-white">
                  Privacy Policy
                </Link>
              </div>

              <div>
                <Link to={"/terms"} className="font-mont text-white">
                  Terms & Services
                </Link>
              </div>

              <div>
                <Link to={"/refund"} className="font-mont text-white">
                  Refund Policy
                </Link>
              </div>
            </nav>
          </div>

          <div></div>
        </div>
      </footer>
      <div className="font-museo bg-primary1 py-7 w-full text-center text-sm font leading-6 font-medium">
        © 2024 Dr Palve Labs.
      </div>
      <div className="fixed bottom-5 right-5 z-50">
        {/* <a
          href="https://api.whatsapp.com/send?phone=8149202506&text=Hello,%20I%20need%20some%20help!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/Whatsapp-Chatbot.png`}
            alt="WhatsApp Chat"
            className="w-16 h-16 hover:scale-105 transition-transform rounded-full"
          />
        </a> */}
        <a
          href="https://web.whatsapp.com/send?phone=918149202506&amp;text="
          target="_blank"
          rel="nofollow noopener"
          aria-label="Whatsapp"
          class="chaty-whatsapp-channel pos-left has-on-hover chaty-tooltip"
          data-form="chaty-form-0-Whatsapp"
          data-hover="WhatsApp"
          data-text="Contact us"
        >
          <span class="chaty-icon channel-icon-Whatsapp">
            <span class="chaty-svg">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  class="color-element"
                  cx="19.4395"
                  cy="19.4395"
                  r="19.4395"
                  fill="#49E670"
                ></circle>
                <path
                  d="M12.9821 10.1115C12.7029 10.7767 11.5862 11.442 10.7486 11.575C10.1902 11.7081 9.35269 11.8411 6.84003 10.7767C3.48981 9.44628 1.39593 6.25317 1.25634 6.12012C1.11674 5.85403 2.13001e-06 4.39053 2.13001e-06 2.92702C2.13001e-06 1.46351 0.83755 0.665231 1.11673 0.399139C1.39592 0.133046 1.8147 1.01506e-06 2.23348 1.01506e-06C2.37307 1.01506e-06 2.51267 1.01506e-06 2.65226 1.01506e-06C2.93144 1.01506e-06 3.21063 -2.02219e-06 3.35022 0.532183C3.62941 1.19741 4.32736 2.66092 4.32736 2.79397C4.46696 2.92702 4.46696 3.19311 4.32736 3.32616C4.18777 3.59225 4.18777 3.59224 3.90858 3.85834C3.76899 3.99138 3.6294 4.12443 3.48981 4.39052C3.35022 4.52357 3.21063 4.78966 3.35022 5.05576C3.48981 5.32185 4.18777 6.38622 5.16491 7.18449C6.42125 8.24886 7.39839 8.51496 7.81717 8.78105C8.09636 8.91409 8.37554 8.9141 8.65472 8.648C8.93391 8.38191 9.21309 7.98277 9.49228 7.58363C9.77146 7.31754 10.0507 7.1845 10.3298 7.31754C10.609 7.45059 12.2841 8.11582 12.5633 8.38191C12.8425 8.51496 13.1217 8.648 13.1217 8.78105C13.1217 8.78105 13.1217 9.44628 12.9821 10.1115Z"
                  transform="translate(12.9597 12.9597)"
                  fill="#FAFAFA"
                ></path>
                <path
                  d="M0.196998 23.295L0.131434 23.4862L0.323216 23.4223L5.52771 21.6875C7.4273 22.8471 9.47325 23.4274 11.6637 23.4274C18.134 23.4274 23.4274 18.134 23.4274 11.6637C23.4274 5.19344 18.134 -0.1 11.6637 -0.1C5.19344 -0.1 -0.1 5.19344 -0.1 11.6637C-0.1 13.9996 0.624492 16.3352 1.93021 18.2398L0.196998 23.295ZM5.87658 19.8847L5.84025 19.8665L5.80154 19.8788L2.78138 20.8398L3.73978 17.9646L3.75932 17.906L3.71562 17.8623L3.43104 17.5777C2.27704 15.8437 1.55796 13.8245 1.55796 11.6637C1.55796 6.03288 6.03288 1.55796 11.6637 1.55796C17.2945 1.55796 21.7695 6.03288 21.7695 11.6637C21.7695 17.2945 17.2945 21.7695 11.6637 21.7695C9.64222 21.7695 7.76778 21.1921 6.18227 20.039L6.17557 20.0342L6.16817 20.0305L5.87658 19.8847Z"
                  transform="translate(7.7758 7.77582)"
                  fill="white"
                  stroke="white"
                  stroke-width="0.2"
                ></path>
              </svg>
            </span>
          </span>
          {/* <span class="on-hover-text">Contact us</span> */}
        </a>
      </div>
    </div>
  );
}

export default Footer;
