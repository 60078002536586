import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const checkPermission = useCheckPermission();
  const navigate = useNavigate();

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const isUserModuleVisible = checkPermission(
    ADMIN_MODULES.USERS,
    PERMISSIONS.VIEW
  );
  const isLabModuleVisible = checkPermission(
    ADMIN_MODULES.LABS,
    PERMISSIONS.VIEW
  );
  const isDoctorModuleVisible = checkPermission(
    ADMIN_MODULES.DOCTOR,
    PERMISSIONS.VIEW
  );
  const isCustomerReviewsModuleVisible = checkPermission(
    ADMIN_MODULES.CUSTOMER_REVIEW,
    PERMISSIONS.VIEW
  );
  const isNewsLetterModuleVisible = checkPermission(
    ADMIN_MODULES.NEWSLETTER,
    PERMISSIONS.VIEW
  );
  const isGeneralDetailsModuleVisible = checkPermission(
    ADMIN_MODULES.GENERAL_DETAILS,
    PERMISSIONS.VIEW
  );
  const isGiftCardModuleVisible = checkPermission(
    ADMIN_MODULES.GIFT_CARD,
    PERMISSIONS.VIEW
  );
  const isContactUsModuleVisible = checkPermission(
    ADMIN_MODULES.CONTACT_US,
    PERMISSIONS.VIEW
  );
  const isTestBookingsModuleVisible = checkPermission(
    ADMIN_MODULES.TEST_BOOKING,
    PERMISSIONS.VIEW
  );
  const isTestModuleVisible = checkPermission(
    ADMIN_MODULES.TEST,
    PERMISSIONS.VIEW
  );
  const isPolicyModuleVisible = checkPermission(
    ADMIN_MODULES.POLICY,
    PERMISSIONS.VIEW
  );

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleLogout = async () => {
    try {
      await axiosClient.get("/users/logout");
      localStorage.removeItem("admintoken");
      localStorage.removeItem("adminname");
      localStorage.removeItem("adminemail");
      localStorage.removeItem("adminId");
      localStorage.removeItem("token");
      navigate("/admin/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <img
          src="/images/final 2-03 (1).png"
          alt="Logo"
          className="h-13 w-20"
        />
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <i className="bi bi-menu-down"></i>
        </Link>

        <ul className="sidebar-list space-y-4">
          {" "}
          {/* Added spacing between items */}
          <li>
            <div className="title">
              <Link to="/admin/dashboard" className="link">
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/admin/dashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>
          {isUserModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/usersedit" className="link">
                  <i className="bi bi-person"></i>
                  <span className="name">Users</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Users</Link>
              </div>
            </li>
          )}
          {isLabModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/labsedit" className="link">
                  <i className="bi bi-building"></i>
                  <span className="name">Labs</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Labs</Link>
              </div>
            </li>
          )}
          {isDoctorModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/doctoredit" className="link">
                  <i className="bi bi-person-square"></i>
                  <span className="name">Our Doctors</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Our Doctors</Link>
              </div>
            </li>
          )}
          {isCustomerReviewsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/customerreviewedit" className="link">
                  <i className="bi bi-chat-left-text"></i>
                  <span className="name">Customer Reviews</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Customer Reviews</Link>
              </div>
            </li>
          )}
          {isNewsLetterModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/newsletteredit" className="link">
                  <i className="bi bi-envelope-at"></i>
                  <span className="name">News Letter</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">News Letter</Link>
              </div>
            </li>
          )}
          {isGeneralDetailsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/general-details" className="link">
                  <i className="bi bi-info-square"></i>
                  <span className="name">General Details</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">General Details</Link>
              </div>
            </li>
          )}
          {isGiftCardModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/giftcardedit" className="link">
                  <i className="bi bi-card-checklist"></i>
                  <span className="name">Gift Card</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Gift Card</Link>
              </div>
            </li>
          )}
          {isContactUsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/contactus" className="link">
                  <i className="bi bi-card-checklist"></i>
                  <span className="name">Contact Us</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Contact Us</Link>
              </div>
            </li>
          )}
          {isTestBookingsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/bookingedit" className="link">
                  <i className="bi bi-list-ul"></i>
                  <span className="name">Test Bookings</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Bookings</Link>
              </div>
            </li>
          )}
          {isTestModuleVisible && (
            <li
              className={`dropdown ${activeSubmenu === "test" ? "active" : ""}`}
              onClick={() => {
                toggleSubmenu("test");
              }}
            >
              <div className="title">
                <Link className="link">
                  <i className="bi bi-bookshelf"></i>
                  <span className="name">Test</span>
                </Link>
                <i className="bi bi-chevron-down"></i>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Test</Link>
                <Link to="/admin/test-category-details" className="link">
                  Test Category
                </Link>
                <Link to="/admin/all-test" className="link">
                  Test
                </Link>
                <Link to="/admin/all-test-parameter" className="link">
                  Test Parameter
                </Link>
              </div>
            </li>
          )}
          {/* Frontend Options */}
          {
            <li
              className={`dropdown ${
                activeSubmenu === "frontend" ? "active" : ""
              }`}
              onClick={() => {
                toggleSubmenu("frontend");
              }}
            >
              <div className="title">
                <Link className="link">
                  <i className="bi bi-box2-heart"></i>
                  <span className="name">Front End Options</span>
                </Link>
                <i className="bi bi-chevron-down"></i>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Test</Link>
                <Link to="/admin/home-testing-details" className="link">
                  Home Testing
                </Link>
                <Link to="/admin/why-choose-us-details" className="link">
                  Why Choose Us
                </Link>
                <Link to="/admin/benefits-details" className="link">
                  Benefits
                </Link>
                <Link to="/admin/hero-block-image-details" className="link">
                  Hero Block Images
                </Link>
                <Link to="/admin/time-slot-details" className="link">
                  Time Slot
                </Link>
                <Link to="/admin/meta-tags" className="link">
                  Meta Tags
                </Link>
                <Link to="/admin/familycarepackageedit" className="link">
                  Family Package
                </Link>
              </div>
            </li>
          }
          {isUserModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/usersedit" className="link">
                  <i className="bi bi-person"></i>
                  <span className="name">Users</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Users</Link>
              </div>
            </li>
          )}
          {isLabModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/labsedit" className="link">
                  <i className="bi bi-building"></i>
                  <span className="name">Labs</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Labs</Link>
              </div>
            </li>
          )}
          {isDoctorModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/doctoredit" className="link">
                  <i className="bi bi-person-square"></i>
                  <span className="name">Our Doctors</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Our Doctors</Link>
              </div>
            </li>
          )}
          {isCustomerReviewsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/customerreviewedit" className="link">
                  <i className="bi bi-chat-left-text"></i>
                  <span className="name">Customer Reviews</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Customer Reviews</Link>
              </div>
            </li>
          )}
          {/* {isFamilyPackageModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/familycarepackageedit" className="link">
                  <i className="bi bi-chat-left-text"></i>
                  <span className="name">Family Package</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Family Package</Link>
              </div>
            </li>
          )} */}
          {isNewsLetterModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/newsletteredit" className="link">
                  <i className="bi bi-envelope-at"></i>
                  <span className="name">News Letter</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">News Letter</Link>
              </div>
            </li>
          )}
          {isGeneralDetailsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/general-details" className="link">
                  <i className="bi bi-info-square"></i>
                  <span className="name">General Details</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">General Details</Link>
              </div>
            </li>
          )}
          {isGiftCardModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/giftcardedit" className="link">
                  <i className="bi bi-card-checklist"></i>
                  <span className="name">Gift Card</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Gift Card</Link>
              </div>
            </li>
          )}
          {isContactUsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/contactus" className="link">
                  <i className="bi bi-card-checklist"></i>
                  <span className="name">Contact Us</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Contact Us</Link>
              </div>
            </li>
          )}
          {isTestBookingsModuleVisible && (
            <li>
              <div className="title">
                <Link to="/admin/bookingedit" className="link">
                  <i className="bi bi-list-ul"></i>
                  <span className="name">Test Bookings</span>
                </Link>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Bookings</Link>
              </div>
            </li>
          )}
          {isTestModuleVisible && (
            <li
              className={`dropdown ${activeSubmenu === "test" ? "active" : ""}`}
              onClick={() => {
                toggleSubmenu("test");
              }}
            >
              <div className="title">
                <Link className="link">
                  <i className="bi bi-bookshelf"></i>
                  <span className="name">Test</span>
                </Link>
                <i className="bi bi-chevron-down"></i>
              </div>
              <div className="submenu">
                <Link className="link submenu-title">Test</Link>
                <Link to="/admin/test-category-details" className="link">
                  Test Category
                </Link>
                <Link to="/admin/all-test" className="link">
                  Test
                </Link>
                <Link to="/admin/all-test-parameter" className="link">
                  Test Parameter
                </Link>
              </div>
            </li>
          )}
          {/* New Policy Dropdown Menu */}
          {isPolicyModuleVisible && (
            <li
              className={`dropdown ${
                activeSubmenu === "policy" ? "active" : ""
              }`}
              onClick={() => toggleSubmenu("policy")}
            >
              <div className="title">
                <Link className="link">
                  <i className="bi bi-shield-lock"></i>
                  <span className="name">Policy</span>
                </Link>
                <i className="bi bi-chevron-down"></i>
              </div>

              <div className="submenu space-y-2">
                {" "}
                {/* Added spacing within submenu */}
                <Link to="/admin/termsedit" className="link">
                  Terms and Conditions
                </Link>
                <Link to="/admin/privacyedit" className="link">
                  Privacy Policy
                </Link>
                <Link to="/admin/refundedit" className="link">
                  Refund Policy
                </Link>
              </div>
            </li>
          )}
          <li>
            <div className="title">
              <Link to="#" className="link" onClick={handleLogout}>
                <i className="bi bi-box-arrow-left"></i>
                <span className="name">Logout</span>
              </Link>
            </div>
            {/* <div className="submenu">
              <Link to="#" className="link submenu-title">
                Dashboard
              </Link>
            </div> */}
          </li>
          {/* <li className="logout">
            <div className="title">
              <Link to="#" className="link" onClick={handleLogout}>
                <i className="bi bi-box-arrow-left"></i>
                <span className="name">Logout</span>
              </Link>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
