import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { percentage } from "../../constants/constant";

const TopOffers = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { filepath } = useAuth();
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axiosClient(`/tests/tests`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setOffers(data.test || []);
        // Fetch the parameters for each package (test)
        const fetchParameters = async (testId) => {
          try {
            const paramResponse = await axiosClient(
              `/tests-parameters/test/parameters/test/${testId}`
            );
            if (paramResponse.status === 404) {
              return 0; // If 404, return 0 for the totalParametersCount
            }
            return paramResponse.data.totalParametersCount || 0;
          } catch (error) {
            // In case of any error (e.g., network issues), return 0
            return 0;
          }
        };

        // Update each package with totalParametersCount
        const updatedPackages = await Promise.all(
          data.test.map(async (pkg) => {
            const totalParametersCount = await fetchParameters(pkg._id);
            return { ...pkg, totalParametersCount };
          })
        );

        setOffers(updatedPackages);
      } catch (error) {
        setError("Failed to fetch offers");
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (offers.length === 0)
    return <div className="text-center">No offers available</div>;

  return (
    <div className="md:py-24 py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto">
      <h2 className="lg:text-3xl md:text-2xl text-xl font-bold text-center md:mb-8 font-museo">
        Check Our <span className="font1">Top Individual Tests</span>
      </h2>

      {/* Swiper Carousel */}
      <div className="max-w-screen-2xl mx-auto relative lg:mt-20 mt-12">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          navigation={{
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {offers
            .filter((pkg) => pkg.show === true && pkg.testType == "test")
            .map((offer, index) => (
              <SwiperSlide key={index}>
                <Link to={`/test/${offer?.metaUrl}`}>
                  <div className="w-full h-full sm:w-80 md:w-[230px]  lg:w-[100%] lg:h-[350px] md:h-[340px] bg-card2 border border-gray-300 rounded-lg shadow-lg overflow-hidden ">
                    <div
                      className="w-full h-full lg:pl-5 pl-1 pt-3 flex flex-col justify-between relative  bg-cover bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url('${
                          filepath + offer.backgrounImg
                        }')`,
                      }}
                    >
                      <div className="relative z-[11] flex flex-col flex-grow">
                        <h3 className="lg:text-xl text-lg font-semibold font-museo font4 lg:w-[60%] lg:py-2">
                          {offer.title}
                          <span className="font6">{offer.title2}</span>
                        </h3>
                        <p className="lg:text-sm text-xs font4 font-medium mb-2 font-mont lg:w-[50%] md:w-full w-[80%] line-clamp-3">
                          {offer.description}
                        </p>
                        <div className="flex lg:flex-row flex-col justify-between lg:items-center text-xs font7 font-mont lg:mb-4 mb-2 w-[50%] flex-grow">
                          <span className="font6">
                            {offer.totalParametersCount}{" "}
                            <span className="font7">Parameters</span>
                          </span>
                          <span className="font6">
                            {offer.reportTime}{" "}
                            <span className="font7">Reports</span>
                          </span>
                        </div>
                        <div className="flex flex-col justify-end h-32">
                          <div className="flex justify-between items-center lg:mb-4 mb-2 w-[50%] bg-white lg:py-2 lg:px-4 p-2 rounded-lg shadow-sm font-museo">
                            <div className="flex flex-grow lg:text-lg text-sm font-semibold font6">
                              {offer.offerPrice ? (
                                <>
                                  <div className="">
                                    <span className="block text-left">
                                      ₹{offer.offerPrice}
                                    </span>
                                    <span className="text-gray-500 block text-left line-through text-base font-bold">
                                      ₹{offer.price}
                                    </span>
                                  </div>
                                  <div className="border-l flex flex-col border-solid border-[#888888] flex-grow pl-2 font5 lg:text-sm text-xs font-medium ml-2">
                                    <span className="text-xl font-bold text-center">
                                      {percentage(
                                        offer.price,
                                        offer.offerPrice
                                      )}
                                      % off
                                    </span>
                                    {/* <span className="font5 lg:text-sm text-xs font-medium ml-1">
                                      60% off
                                      </span> */}
                                  </div>
                                </>
                              ) : (
                                <span>₹{offer.price}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          <button
            className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs block mx-auto mt-24 w-full max-w-[120px] transition-all"
            onClick={() => navigate("/test-individual")}
          >
            View All
          </button>
        </Swiper>

        <div className="absolute left-1/2 bottom-14 z-[1] transform -translate-x-1/2 flex space-x-4 mb-4 md:mb-1">
          <div className="btn-prev font6 md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-al">
            <FiArrowLeft size={22} />
          </div>
          <div className="btn-next md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-all">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopOffers;
