import React, { useEffect, useState } from "react";
import { percentage } from "../../constants/constant";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

function Instructions({ offers }) {
  const [beterOffer, setBestOffer] = useState(offers.upscaling || []);
  const { filepath, addToCart } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParameters = async (testId) => {
      try {
        const paramResponse = await axiosClient(`/tests-parameters/test/parameters/test/${testId}`);
        if (paramResponse.status === 404) {
          return 0; // If 404, return 0 for the totalParametersCount
        }
        return paramResponse.data.totalParametersCount || 0;
      } catch (error) {
        // In case of any error (e.g., network issues), return 0
        return 0;
      }
    };
    const updateData = async () => {
      if (offers.upscaling) {
        const update = await Promise.all(
          offers?.upscaling.map(async (pkg) => {
            const totalParametersCount = await fetchParameters(pkg._id);
            return { ...pkg, totalParametersCount };
          })
        );
        setBestOffer(update);
      }
    };
    updateData();
  }, [offers.upscaling]);
  console.log("beterOffer", beterOffer)
  return (
    <div className="max-w-screen-2xl mx-auto py-8 px-4 md:px-12 md:py-14 lg:px-20 lg:py-20">
      <div className="mb-8">
        <h3 className="font-museo  font4  text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Package Instructions
        </h3>
        <div className="flex items-start mt-4">
          <ul className="list-group p-0">
            {offers.instructions &&
              offers.instructions.map((word, index) => (
                <li key={index} className={`flex gap-4 justify-between p-0 mt-2 relative`}>
                  <img
                    src={`${process.env.PUBLIC_URL}/others/star-svgrepo-com.svg`}
                    alt="star"
                    className="w-4 h-4 mt-1"
                  />
                  <span
                    className="w-2/4 flex-grow font-mont font7 text-sm sm:text-base lg:text-lg font-medium"
                    title={word}
                  >
                    {word}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">       
        {beterOffer.map((item, index) => (
          <div
          key={index}
            className={` ${
              item.testType == "package" ? "bg-card2" : "bg-card3"
            } rounded-xl shadow-lg border-2 border-gray-300 overflow-hidden`}
          >
            <div className="flex flex-col h-full relative  bg-cover bg-center bg-no-repeat"  style={{backgroundImage: `url('${filepath + item.backgrounImg}')`}}>
              <div className="flex flex-col pr-4 flex-grow p-6">
                <h2 className="font-museo text-xl lg:text-2xl  font4 font-bold mb-3">
                  {item.title}
                  <span className="font-semibold">{item.subTitle}</span>
                </h2>
                <p className="font4 font-mont text-sm lg:text-base mb-4 flex-grow line-clamp-3">
                  {item.description}
                </p>
                <div className="font7 font-mont text-sm lg:text-base mb-4 space-x-4">
                  <span className="mr-3">
                    <span className="font6">{item.totalParametersCount}</span> Parameters
                  </span>
                  <span>
                    <span className="font6">{item.reportTime}</span> Reports
                  </span>
                </div>
                <div className="max-w-fit flex mb-4 items-center bg-white rounded-lg p-3">
                  {item.offerPrice ? (
                    <>
                      <div className="">
                        <span className="block text-left">
                          ₹{item.offerPrice}
                        </span>
                        <span className="text-gray-500 block text-left line-through text-base font-bold">
                          ₹{item.price}
                        </span>
                      </div>
                      <div className="border-l flex flex-col border-solid border-[#888888] flex-grow pl-2 font5 lg:text-sm text-xs font-medium ml-2">
                        <span className="text-xl font-bold text-center">{percentage(item.price,item.offerPrice)}% off</span>
                        {/* <span className="text-xs">for a limited period</span> */}
                      </div>
                    </>
                  ) : (
                    <span className="text-xl lg:text-2xl font-bold font6">₹{item.price}</span>
                  )}
                </div>
                <button
                  className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                  onClick={() => {
                    addToCart(item._id);
                    navigate("/steps");
                  }}
                >
                  {" "}
                  Add Cart
                </button>               
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Instructions;
