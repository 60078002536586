import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const HomeTesting = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [testings, setTestings] = useState([]);
  const [selectedTesting, setSelectedTesting] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    path: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalShow, setModalShow]= useState(false);

  const checkPermission = useCheckPermission();

  const canEditTestCat = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.EDIT);
  const canDeleteTestCat= checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.DELETE);
  const canAddTestCat = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("home-testing/steps");
      // console.log("response:",response.data)
      setTestings(response.data.homeTesting || []);
      setLoading(false);
      setError("");
    } catch (err) {
      setLoading(false);
      setTestings([]);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [testings]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModalShow = (categoryId = null) => {
    if (categoryId) {
      setSelectedTesting(categoryId)
      // Editing mode
      const testingToEdit = testings.find((t) => t._id === categoryId);
      setFormData({
        id: testingToEdit.id,
        title: testingToEdit.title,
        description: testingToEdit.description,
        path: testingToEdit.path,
      });
      setIsEditMode(true);
    } else {
      // Create mode

       // Set the last available ID as the default
      const lastId = Math.max(...testings.map((t) => t.id), 0);
      setFormData({
        id: lastId + 1,
        title: "",
        description: "",
        path: "",
      });
      setIsEditMode(false);
    }
    setModalShow(true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        // Update an existing testing step
        await axiosClient.put(`home-testing/steps/${selectedTesting}`, formData);
        setSuccess("Home testing step updated successfully!");
      } else {
        // Create a new testing step
        await axiosClient.post("home-testing/steps/add", formData);
        setSuccess("Test testing step created successfully!");
      }
      fetchCategories();
      handleModalClose(); // Close the modal after success

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);

    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedTesting(null);
  };
  

  const handleShow = (categoryId) => {
    setSelectedTesting(categoryId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedTesting(null);
  };

  // Handle delete category
  const handleDelete = async () => {
    try {
      await axiosClient.delete(
        `home-testing/steps/${selectedTesting}`
      );
      setTestings(
        testings.filter((category) => category._id !== selectedTesting)
      );
      setSuccess("Home Test Step deleted successfully!");
      setError("");
      handleClose();
      fetchCategories();

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (err) {
      setError("Failed to delete Home Test Step.");
      setSuccess("");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Home Testing Step</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this home testing step?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>


        {/* Modal for add and edit home testing steps */}
        <Modal show={modalShow} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Edit Home Testing Step" : "Add Home Testing Step"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="id">Step No.</label>
                    <input
                    type="number"
                    className="form-control"
                    id="id"
                    name="id"
                    value={formData.id}
                    onChange={handleInputChange}
                    required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="path">Path</label>
                    <input
                    type="text"
                    className="form-control"
                    id="path"
                    name="path"
                    placeholder="(e.g.) book-test/new "
                    value={formData.path}
                    onChange={handleInputChange}
                    // required
                    />
                </div>
                <Modal.Footer>
                    <button type="submit" className="modal-footer-btn">
                    {isEditMode ? "Update" : "Create"}
                    </button>
                    <button className="modal-footer-btn" onClick={handleModalClose}>
                    Cancel
                    </button>
                </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
        {/*  */}

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Home Testing Steps</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {canAddTestCat && <div className="add-button">
                {/* <Link to="/admin/add-test-category"> */}
                {canAddTestCat && (
                    <div className="add-button">
                        <button onClick={() => handleModalShow()}>Add Home Testing Steps</button>
                    </div>
                    )}

                {/* </Link> */}
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Step No.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testings.length > 0 ? (
                      testings.map((t, index) => (
                        <tr key={t._id}>
                          <td>{index + 1}</td>
                          <td>{t.title}</td>
                          <td>{t.description}</td>
                          <td>{t.id}</td>
                          <td>
                          {canEditTestCat && (
                            <i
                                className="fa-regular fa-pen-to-square"
                                onClick={() => handleModalShow(t._id)}
                            ></i>
                          )}
                            &nbsp;
                            {canDeleteTestCat && <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(t._id)}
                            ></i>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No home testing steps available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTesting;
