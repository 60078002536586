function formatTime(time) {
    // Split the time into hours and minutes
    const [hours, minutes] = time.split(':');
    
    // Create a new Date object (using a fixed date because we're only interested in time)
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
  
    // Use toLocaleString to format the time as AM/PM
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleString('en-US', options);
    
    return formattedTime;
  }

  export default formatTime;