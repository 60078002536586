import React from "react";

function Banner() {
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center "
      style={{
        backgroundImage: 'url("images/blood-sugar-test.webp")',
        width: "100%",
        height: "461px",
      }}
    >
      {/* Header Text */}
      <div className="mt-8 sm:mt-12 flex flex-col justify-center items-center sm:text-left">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white font-museo leading-tight sm:leading-10">
          About <span className="font6">Dr Palve Labs</span>
        </h1>
        <p className="text-base sm:text-lg mt-4 sm:mt-8 text-white font-mont font-medium">
          An Integrated Approach
        </p>
      </div>
    </div>
  );
}

export default Banner;
