import { useState } from "react";
import { useAuth } from "../../AuthContext";
import axiosClient from "../../axios-client";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useDispatch } from "react-redux";
import { setRegisterActive } from "../../redux/registerSlice";
import {  percentage } from "../../constants/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const CheckTests = ({ nextStep, error, loading, tests, upscaling }) => {
  const dispatch = useDispatch();

  const { cart, removeFromCart, filepath, addToCart } = useAuth(); // Get the cart array and remove function from useAuth

  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingError, setBookingError] = useState(null);
  const [packages, setPackages] = useState([]); // State to manage additional packages  
  // Function to add more packages (up to 3 max visible)
  const addPackage = () => {
    if (packages.length < 3) {
      const newPackageId = packages.length > 0 ? packages[packages.length - 1].id + 1 : 1; // Increment ID
      const newPackage = {
        id: newPackageId,
        name: `New Package ${newPackageId}`,
        price: 1299
      };
      setPackages([...packages, newPackage]);
    } else {
      iziToast.error({
        message: "You can only add up to 3 packages.",
        position: "topCenter"
      });
    }
  };

  // Function to handle booking and advancing to the next step
  const handleContinue = async () => {
    setBookingLoading(true);
    setBookingError(null);

    try {
      // Retrieve user_id from localStorage
      const userId = localStorage.getItem("userId");

      if (!userId) {
        iziToast.error({
          message: "Please login first before going to next step.",
          position: "topCenter"
        });

        setTimeout(() => {
          // navigate("/register");
          dispatch(setRegisterActive(true)); // Show the register modal
        }, 1000);

        throw new Error("User not authenticated.");
      }

      // Prepare the payload
      const payload = {
        user_id: userId,
        test_id: cart
      };

      // Make the POST request
      const response = await axiosClient.post("/tests-booking/test/booking/add", payload);

      if (response.data.success) {
        const bookingId = response.data.booking._id;

        // Store bookingId in localStorage
        localStorage.setItem("bookingId", bookingId);

        // Advance to the next step
        nextStep();
      } else {
        throw new Error("Booking failed.");
      }
    } catch (err) {
      console.error("Booking Error:", err);
      setBookingError(err.response?.data?.message || err.message || "Booking failed.");
    } finally {
      setBookingLoading(false);
    }
  };

  // Handle loading state
  if (loading) {
    return <div className="text-center mt-10">Loading tests...</div>;
  }

  // Handle error state
  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {/* Selected Packages */}
        <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Selected <span className="font1">Packages</span>
          </h3>

          {/* Display package items dynamically */}
          <div className="space-y-4">
            {tests.length > 0 ? (
              tests.map((item) => (
                <div
                  key={item._id}
                  className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
                >
                  <div>
                    <h4 className="font-semibold font-museo font4 text-base">
                      {item.title} - {item.subTitle}
                    </h4>
                    <p className="font6 font-medium mt-2 font-museo">
                      ₹{item.offerPrice}
                    </p>
                  </div>
                  <button
                    className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                    onClick={() => removeFromCart(item._id)} // Removes the test from packages
                  >
                    Remove
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No tests selected.</p>
            )}
          </div>

          {/* Add More Tests Button */}
          <Link to={"/testBrowse"}>
            <div className="flex items-center justify-center mt-4 font-museo font-semibold text-lg">
              <button
                className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg"
                onClick={addPackage}
              >
                + Add More Tests
              </button>
            </div>
          </Link>
        </div>

        {/* Offers Section */}
        <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>
          <div className="mb-6">
            {/* Swiper component */}
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
              pagination={{
                clickable: true
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="check-offers-slider  h-full"
            >
              {upscaling?.map((item, index) => (
                <SwiperSlide key={index} className="h-auto self-stretch">
                  <div className="w-full h-full relative">
                    <div
                      className={` ${
                        item.testType == "package" ? "bg-card2" : "bg-card3"
                      } rounded-xl shadow-lg border-2 border-gray-300 overflow-hidden h-full relative`}
                    >
                      <div className="flex flex-col sm:flex-row h-full relative bg-cover bg-center bg-no-repeat"  style={{backgroundImage: `url('${filepath + item.backgrounImg}')`}} >
                        <div className="flex flex-col pr-4 p-6">
                          <Link to={`/test/${item?.metaUrl}`}>
                            <h2 className="font-museo text-xl lg:text-2xl  font4 font-bold mb-3">
                              {item.title}
                              <span className="font-semibold">{item.subTitle}</span>
                            </h2>
                          </Link>
                          <p className="font4 flex-grow font-mont text-sm lg:text-base mb-4 line-clamp-3">
                            {item.description}
                          </p>
                          <div className="font7 font-mont text-sm lg:text-base mb-4 space-x-4">
                            <span>
                              <span className="font6">{item.reportTime}</span> Reports
                            </span>
                          </div>
                          <div className="max-w-fit flex items-center bg-white rounded-lg p-3 mb-3">
                            {item.offerPrice ? (
                              <>
                                <div className="">
                                  <span className="block text-left">
                                    ₹{item.offerPrice}
                                  </span>
                                  <span className="text-gray-500 block text-left line-through text-base font-bold">
                                    ₹{item.price}
                                  </span>
                                </div>
                                <div className="border-l flex flex-col border-solid border-[#888888] flex-grow pl-2 font5 lg:text-sm text-xs font-medium ml-2">
                                  <span className="text-xl font-bold text-center">
                                    {percentage(item.price,item.offerPrice)}% off
                                  </span>
                                  {/* <span className="text-xs">for a limited period</span> */}
                                </div>
                              </>
                            ) : (
                              <span className="text-xl lg:text-2xl font-bold font6">
                                ₹{item.price}
                              </span>
                            )}
                          </div>
                          <button
                            className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                            onClick={() => {
                              addToCart(item._id);
                            }}
                          >
                            {" "}
                            Add Cart
                          </button>
                        </div>                       
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* Display Offers */}
          <div className="space-y-8">
            {[1].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold pl-3">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="nurse" width={"27%"} />
                </div>
              </div>
            ))}
          </div>
          <div className="space-y-8">
            <div className="py-3 px-5 bg-[#f8f8f8] border-b mt-10 font-museo font-semibold text-center text-[#1E4FA6]">
              Amount would be calculated in the next step
            </div>
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-9 mb-2 font-museo font-semibold text-lg">
            <button
              onClick={handleContinue}
              className={`w-3/4 mt-6 py-3 rounded-lg ${
                bookingLoading
                  ? "bg-blue-300 cursor-not-allowed"
                  : "bg-card1 text-white hover-color1"
              }`}
              disabled={bookingLoading}
            >
              {bookingLoading ? "Processing..." : "Continue To Next Step"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckTests;
