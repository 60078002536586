import React, { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";
import { useDispatch } from "react-redux";
import { setRegisterActive } from "../../redux/registerSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const { cart } = useAuth();
  // // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // // const [user, setUser] = useState(null);

  const cartCount = cart.length;

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleOpenRegister = () => {
    dispatch(setRegisterActive(true)); // Show the register modal
  };

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();

  const token = localStorage.getItem("token");
  // const adminname = localStorage.getItem('adminname');
  // const adminemail = localStorage.getItem('adminemail');
  // const admintoken = localStorage.getItem('admintoken');
  // if(token === null || token === ""){
  //   navigate("/");
  // }

  const handleLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    // adminLogout();
    // logout();

    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/";
  };

  return (
    <nav className="bg-secondary1 text-white py-5 max-w-screen-2xl mx-auto px-4 lg:px-20 md:px-12 h-[110px]">
      <div className="flex justify-between items-center">
        {/* Left Section - Logo */}
        <div className="flex items-center space-x-4">
          {/* Logo */}
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/LabsLogo.png`}
              alt="Logo"
              className="lg:h-20 h-16"
            />
          </Link>
        </div>
        {/* Right Section */}
        <div className="flex items-center space-x-4">
          {/* Cart Icon with Badge */}
          <Link to="/steps">
            <div className="relative border-2 border-white p-2 rounded-full">
              <FaShoppingCart className="w-7 h-7" />
              {cartCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-xs text-white rounded-full px-1">
                  {cartCount}
                </span>
              )}
            </div>
          </Link>

          {/* Login/Register Button */}
          {token ? (
            <Link to="/profile">
              <img
                src={`${process.env.PUBLIC_URL}/images/profile1.svg`}
                alt="User Profile"
                width={"45px"}
                height={"45px"}
                className="bg-white p-2 rounded-full"
              />
            </Link>
          ) : (
            // <Link to="/register">
            <button
              onClick={handleOpenRegister}
              className="bg-white font1 font-museo font-semibold text-base md:px-4 lg:py-3 md:py-2 px-2 py-1.5 rounded-md"
            >
              Login/Register
            </button>
            // </Link>
          )}

          {/* Button to Add Item to Cart (for testing) */}
          {/* <button
            onClick={addItemToCart}
            className="bg-green-500 px-4 py-2 rounded-md text-white hidden md:block"
          >
            Add to Cart
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
