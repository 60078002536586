import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const WhyChooseUs = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [img, setImg] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const { filepath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    image: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalShow, setModalShow]= useState(false);

  const checkPermission = useCheckPermission();

  const canEditSteps = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.EDIT);
  const canDeleteSteps= checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.DELETE);
  const canAddSteps = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("hero-block-image/img");
      //console.log("response:",response?.data)
      setImg(response.data.heroBlockImage);
      setLoading(false);
      setError("");
    } catch (err) {
      setLoading(false);
      setImg([]);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [img]);

  const handleModalShow = (stepId = null) => {
    if (stepId) {
      setSelectedImg(stepId)
      // Editing mode
      const testingToEdit = img.find((t) => t._id === stepId);
      setFormData({
        image: testingToEdit.image,
      });
      setIsEditMode(true);
    } else {
      // Create mode
      setFormData({
        image: ""
      });
      setIsEditMode(false);
    }
    setModalShow(true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        // Update an existing hero block image
        await axiosClient.put(`hero-block-image/img/${selectedImg}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for file upload
          },
        });
        setSuccess("Hero Block Image updated successfully!");
      } else {
        // Create a new hero block image
        await axiosClient.post("hero-block-image/img/add", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type for file upload
            },
          });
        setSuccess("Hero Block Image created successfully!");
      }
      fetchCategories();
      handleModalClose(); // Close the modal after success

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);

    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedImg(null);
  };

  const handleShow = (stepId) => {
    setSelectedImg(stepId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedImg(null);
  };

  // Handle delete image
  const handleDelete = async () => {
    try {
      await axiosClient.delete(
        `hero-block-image/img/${selectedImg}`
      );
      setImg(
        img.filter((image) => image._id !== selectedImg)
      );
      setSuccess("Hero Block Image deleted successfully!");
      setError("");
      handleClose();
      fetchCategories();

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (err) {
      setError("Failed to delete Hero Block Image.");
      setSuccess("");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }


  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Hero Block Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this Hero Block image?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>


        {/* Modal for add and edit Hero Block Image */}
        <Modal show={modalShow} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Edit Hero Block Image" : "Add Hero Block Image"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="Image Src" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                      required
                    />

                    {/* Display image preview if image is a URL or string */}
                    {isEditMode && formData.image && typeof formData.image === 'string' && (
                    <div>
                        <p className="text-xs ">{formData.image}</p>
                    </div>
                    )}
                  </div>
                <Modal.Footer className="mt-2">
                    <button type="submit" className="modal-footer-btn">
                    {isEditMode ? "Update" : "Create"}
                    </button>
                    <button className="modal-footer-btn" onClick={handleModalClose}>
                    Cancel
                    </button>
                </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Hero Block Images</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {canAddSteps && (
                <div className="add-button">
                  <button onClick={() => handleModalShow()}>Add Hero Block Images</button>
                </div>
              )}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th className="w-[15%] text-center align-middle">S. No.</th>
                      <th className="w-[65%] align-middle">Image</th>
                      <th className="w-[20%] align-middle">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {img.length > 0 ? (
                      img.map((t, index) => (
                        <tr key={t._id}>
                          <td className="w-[15%] text-center align-middle">{index + 1}</td>
                          <td className="w-[65%] align-middle">
                            <img
                              src={`${filepath}${t.image}`}
                              alt="img"
                              width="120px"
                              height="120px"
                              className="block h-[80px] object-cover"
                            />
                          </td>
                          <td className="w-[20%] align-middle">
                          {canEditSteps && (
                            <i
                                className="fa-regular fa-pen-to-square"
                                onClick={() => handleModalShow(t._id)}
                            ></i>
                          )}
                            &nbsp;
                            {canDeleteSteps && <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(t._id)}
                            ></i>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No hero block image available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs;
