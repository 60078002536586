import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { Table, Card, Button, Alert, Form } from "react-bootstrap";
import AddressCard from "./AddressCard";
import FamilyMembersDetails from "./FamilyMembers";
import TestDetails from "./TestDetails";
import iziToast from 'izitoast';
import formatTime from "../../utils/formatTime";

const BookingDetails = () => {
  const { id } = useParams();
  const [bookings, setBookings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const navigate = useNavigate();

  const staffId = localStorage.getItem('adminId');
  const [homeCollectionUser, setHomeCollectionUser] = useState(false)

  const [isPaymentReceived, setIsPaymentReceived] = useState(false); // For payment received status
  const [receivedAmount, setReceivedAmount] = useState(0); // Amount received by the agent
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [reportFiles, setReportFiles] = useState([]);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  // Progress state
  const [progress, setProgress] = useState({
    testConfirmed: false,
    sampleCollected: false,
    sampleProcessing: false,
    reportReady: false,
    delivered: false,
    dates: {
      testConfirmedDate: "",
      sampleCollectedDate: "",
      sampleProcessingDate: "",
      reportReadyDate: "",
      deliveredDate: "",
    },
  });

  // Handle input change for progress toggle
  const handleToggleChange = (field) => {
    if (field === "reportReady" && !progress[field]) {
      const allReportsUploaded =
        bookings?.test_id?.every((testId) => reportFiles[testId]) || false;
  
      if (!allReportsUploaded) {
        iziToast.error({
          message: "Please upload the report first.",
          position: "topCenter",
        });
        return;
      }
    }
  
    setProgress((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Handle date change for progress dates
  const handleDateChange = (field, date) => {
    setProgress((prev) => ({
      ...prev,
      dates: {
        ...prev.dates,
        [field]: date,
      },
    }));
  };

  const handleReportAction = ({ type, testId, reportFiles }) => {
    setReportFiles((prevFiles) => {
      if (type === "upload" || type === "update") {
        return {
          ...prevFiles,
          [testId]: reportFiles, // Map testId to its report file
        };
      } else if (type === "delete") {
        const updatedFiles = { ...prevFiles };
        delete updatedFiles[testId]; // Remove report for the test
        return updatedFiles;
      }
      return prevFiles;
    });
  };

  useEffect(() => {
    const fetchbookingDetails = async () => {
      try {
        const response = await axiosClient.get(
          `tests-booking/test/booking/${id}`
        );

        if (response.data.success) {
          setBookings(response.data.booking);
          setUserId(response.data.booking.user_id);
          setLoading(false);
          const progressData = response.data.booking.progress || {};
          setProgress({
            testConfirmed: progressData.testConfirmed || false,
            sampleCollected: progressData.sampleCollected || false,
            sampleProcessing: progressData.sampleProcessing || false,
            reportReady: progressData.reportReady || false,
            delivered: progressData.delivered || false,
            dates: {
              testConfirmedDate: progressData.dates?.testConfirmedDate || "",
              sampleCollectedDate:
                progressData.dates?.sampleCollectedDate || "",
              sampleProcessingDate:
                progressData.dates?.sampleProcessingDate || "",
              reportReadyDate: progressData.dates?.reportReadyDate || "",
              deliveredDate: progressData.dates?.deliveredDate || "",
            },
          });
          setIsPaymentReceived(response.data?.booking?.paymentProgress?.paymentReceived || false);
          setReceivedAmount(response.data?.booking?.paymentProgress?.paidAmount || 0);
          setRemainingAmount(response.data?.booking?.paymentProgress?.remainingAmount || 0);
          setReportFiles(
            response.data.booking.progress.reportFiles?.reduce((acc, file) => {
              acc[file.testId] = file; // Map testId to its report file
              return acc;
            }, {}) || {}
          ); 
        } else {
          throw new Error("Failed to fetch booking details.");
        }
      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch booking details."
        );
        setLoading(false);
      }
    };

    fetchbookingDetails();
  }, [id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId) {
        // Only fetch user details when userId is available
        try {
          const response = await axiosClient.get(`users/details/${userId}`);

          if (response.data.success) {
            setUser(response.data.user); // Adjust according to your API response
            setLoading(false);
            
            // Fetch payment details
            const paymentResponse = await axiosClient.get(`/payment/mobile/${user.phone}`);
            if (paymentResponse.data.success) {
              const filteredPayments = paymentResponse.data.data.filter(
                (payment) => payment.bookingId === bookings._id
              );
              setPaymentDetails(filteredPayments);
            } else {
              throw new Error("Failed to fetch payment details.");
            }
          } else {
            throw new Error("Failed to fetch user details.");
          }
        } catch (err) {
          setError(
            err.response?.data?.message || "Failed to fetch user details."
          );
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [userId, bookings]);

  // fetch home collection user
  const fetchHomeCollectionUser = async () =>{
    try{
      const response = await axiosClient.get(`users/details/${staffId}`);
      if (response.data?.user?.homeCollectionAgent === true)
        setHomeCollectionUser(true);
      else {
        setHomeCollectionUser(false);
      }
    } catch(error){
      console.error("Error fetching home collection user:", error);
    }
  }

  useEffect(()=>{
    fetchHomeCollectionUser();
  },[]);

  // change in due amount if recieved amount change
  useEffect(() => {
    const totalAmount = bookings?.totalAmount || 0;
    const remaining = totalAmount - (receivedAmount|| 0);
    setRemainingAmount(Math.round(remaining * 100) / 100 || remaining); // Round to two decimal places
  }, [receivedAmount, bookings?.totalAmount]);

 // Handle payment update
 const handlePaymentUpdate = async () => {
  const totalAmount = bookings?.totalAmount || 0;
  const paidAmount = receivedAmount;
  let remaining = totalAmount - paidAmount;

  remaining = Math.round(remaining * 100) / 100; 
  setRemainingAmount(remaining);

  // Prepare the data to update the payment
  const paymentData = {
    paymentReceived: isPaymentReceived,
    paidAmount: paidAmount,
    remainingAmount: remaining,
  };

  try {
    // Call API to update payment status
    const response = await axiosClient.put(
      `tests-booking/test/booking/payment-update/${id}`, paymentData
    );

    if (response.data.success) {
      iziToast.success({
        message: "Payment updated successfully!",
        position: "topCenter",
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    iziToast.error({
      message: error.message || "Failed to update payment",
      position: "topCenter",
    });
  }}


  // Submit progress update to backend
  const handleUpdateProgress = async () => {
    try {
      const response = await axiosClient.put(
        `tests-booking/test/booking/${id}`,
        { progress }
      );
      if (response.data.success) {
        iziToast.success({
          message: "Progress updated successfully",
          position: "topCenter"
        });
      }
    } catch (err) {
      iziToast.error({
        message: "Failed to update progress",
        position: "topCenter"
      });
    }
  };

  // Format date to DD/MM/YYYY
  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <section className="container my-4">
          <div className="row">
            <div className="col-lg-12">
              {/* Basic details Card */}
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-lg font-bold">
                  Test Booking Details
                </Card.Header>
                <Card.Body>
                  {bookings ? (
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>Booking ID</th>
                          <th>Total Amount</th>
                          <th>Booking Date</th>
                          <th>Time Slot</th>
                          <th>Test Date</th>
                          <th>Payment Mode</th>
                          <th>Physical Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={bookings._id}>
                          <td>{bookings.bookingId}</td>
                          <td>₹ {bookings.totalAmount}</td>
                          <td>
                            {new Date(bookings.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td>{bookings?.timeSlot ? (`${formatTime(bookings?.timeSlot?.from)} - ${formatTime(bookings?.timeSlot?.to)}`) : ""}</td>
                          <td>
                            {new Date(bookings.testDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td>{bookings.paymentMethod}</td>
                          <td>{bookings.physicalCopy ? "Yes" : "No"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No test bookings available.
                    </Alert>
                  )}
                </Card.Body>
              </Card>

               {/* Payment Details Card */}
               {paymentDetails.length > 0 && (
                <Card className="mb-4 shadow-sm">
                  <Card.Header className="text-lg font-bold">
                    Payment Details
                  </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover responsive>
                      <thead className="table-light">
                        <tr>
                          <th>Transaction ID</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentDetails.map((payment) => (
                          <tr key={payment._id}>
                            <td>{payment.transactionId}</td>
                            <td>
                              {new Date(payment.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </td>
                            <td>
                              {new Date(payment.createdAt).toLocaleTimeString(
                                "en-GB"
                              )}
                            </td>
                            <td>₹ {payment.amount}</td>
                            <td>{payment.status === 'Success' ? 'Paid' : payment.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              )}

              {/* Progress Card */}
              <Card className="mb-4 shadow-sm">
                <Card.Header className="text-lg font-bold">
                  Progress Details
                </Card.Header>
                <Card.Body className="col">
                  <div className="row">
                    <div className="col-md-8">
                      {/* Progress Toggles */}
                      <Form>
                        {/* Progress Fields */}
                        {[
                          {
                            label: "Test Confirmed",
                            field: "testConfirmed",
                            dateField: "testConfirmedDate",
                          },
                          {
                            label: "Sample Collected",
                            field: "sampleCollected",
                            dateField: "sampleCollectedDate",
                          },
                          {
                            label: "Sample Processing",
                            field: "sampleProcessing",
                            dateField: "sampleProcessingDate",
                          },
                          {
                            label: "Report Ready",
                            field: "reportReady",
                            dateField: "reportReadyDate",
                          },
                          {
                            label: "Delivered",
                            field: "delivered",
                            dateField: "deliveredDate",
                          },
                        ].map((item) => (
                          <div className="row mb-3" key={item.field}>
                            <div className="col-md-3">{item.label}</div>
                            <div className="col-md-8">
                              <div className="flex gap-9 justify-center items-center">
                                <Form.Check
                                  type="switch"
                                  label={progress[item.field] ? "" : ""}
                                  checked={progress[item.field]}
                                  onChange={() =>
                                    handleToggleChange(item.field)
                                  }
                                />
                                <div>
                                  <span>
                                    <strong>Date:</strong>
                                  </span>
                                  <span>
                                    {formatDate(progress.dates[item.dateField])}
                                  </span>
                                </div>
                                <Form.Control
                                  type="date"
                                  value={progress.dates[item.dateField] || ""}
                                  onChange={(e) =>
                                    handleDateChange(
                                      item.dateField,
                                      e.target.value
                                    )
                                  }
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                        ))}

                        {/* Update Button */}
                        <Button
                          variant="primary"
                          onClick={handleUpdateProgress}
                        >
                          Update Progress
                        </Button>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              {/* Payment Details Update Section */}
              { homeCollectionUser && bookings && bookings?.paymentMethod === "COD" && (
                <Card className="mb-4 shadow-sm">
                  <Card.Header className="text-lg font-bold">
                    Payment Details Update (Home Collection Agent)
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      {/* Payment Received Switch */}
                      <Form.Check
                        type="switch"
                        label="Payment Received"
                        checked={isPaymentReceived}
                        onChange={(e) => setIsPaymentReceived(e.target.checked)}
                      />

                      {/* Show Amount Received, Remaining Amount, and Update Payment Button only if Payment is Received */}
                      {isPaymentReceived && (
                        <>
                          <Form.Group className="mt-3">
                            <Form.Label>Amount Received</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter amount received"
                              value={receivedAmount}
                              onChange={(e) => setReceivedAmount(parseFloat(e.target.value))}
                            />
                          </Form.Group>
                          <div className="mt-3">
                            <strong>Due Amount: </strong>₹ {remainingAmount}
                          </div>
                          <Button
                            variant="primary"
                            onClick={handlePaymentUpdate}
                            disabled={!receivedAmount || receivedAmount <= 0 || receivedAmount > bookings?.totalAmount} // disable Update Payment button if received amount is undefined, null , 0 or exceeding total amount
                            className="mt-3"
                          >
                            Update Payment
                          </Button>
                        </>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              )}

              {/* Addresses Section */}
              <AddressCard bookings={bookings} />

              {/* Family Members Section */}
              <FamilyMembersDetails
                bookingFamilyMemberId={bookings?.familyMembers_id}
                userFamilyMembers={user?.familyMemberRefs}
              />

              {/* Selected Tests */}
              <TestDetails
                testIds={bookings?.test_id}
                id={id}
                axiosClient={axiosClient}
                onReportAction={handleReportAction}
              />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default BookingDetails;
