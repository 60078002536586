import React, { useState } from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Banner from "./FAQBanner";
import "../../../src/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const GeneralInfo = [
    {
      question: "Why is Dr. Palve Labs the best Pathology Lab?",
      answer:
        "Dr. Palve Labs is renowned for its state-of-the-art technology, highly qualified staff, and commitment to delivering accurate and timely results. Our comprehensive range of tests, stringent quality control measures, and patient-centric approach set us apart in the diagnostic landscape.",
    },
    {
      question: "What services does Dr. Palve Labs offer?",
      answer:
        "We provide a wide array of diagnostic services including blood tests, imaging services, pathology tests, and specialized health check-up packages tailored to meet individual health needs.",
    },
    {
      question: "How does my prescription get validated?",
      answer:
        "Prescriptions are validated through our secure system where our healthcare professionals review your medical history and prescribed tests to ensure accuracy and relevance.",
    },
  ];

  const TestingProcedures = [
    {
      question: "Do I need to fast before the test?",
      answer:
        "Fasting requirements vary by test. For certain blood tests, fasting for 8-12 hours may be necessary. Please refer to the specific instructions provided during booking or consult with our staff.",
    },
    {
      question: "What are the general instructions before the test?",
      answer:
        "Follow any fasting instructions if applicable. Bring any previous test results or medical records. Wear comfortable clothing for tests that require physical examination. Stay hydrated unless instructed otherwise.",
    },
    {
      question: "How is sample collection conducted?",
      answer:
        "Sample collection can be done at our lab or through home collection services by trained professionals, ensuring convenience for our patients.",
    },
  ];

  const CostsAndPackages = [
    {
      question: "How much does a full body checkup cost at Dr. Palve Labs?",
      answer:
        "The cost of a full body checkup varies based on the specific tests included in the package. Please visit our website or contact customer service for detailed pricing information.",
    },
    {
      question: "Are there any health check-up packages available?",
      answer:
        "Yes, we offer various health check-up packages that cater to different age groups and health concerns, providing comprehensive evaluations at competitive prices.",
    },
  ];

  const ResultsAndConsultations = [
    {
      question: "How long does it take to receive test results?",
      answer:
        "Most routine test results are available within 24 hours, while specialized tests may take longer. You will be notified via email or SMS when your results are ready.",
    },
    {
      question: "Can I consult with a doctor after receiving my test results?",
      answer:
        "Yes, we encourage consultations with our healthcare professionals to discuss your results and receive personalized medical advice.",
    },
  ];

  const PaymentAndInsurance = [
    {
      question: "What payment methods are accepted at Dr. Palve Labs?",
      answer:
        "We accept various payment methods including cash, credit/debit cards, and online payment options for your convenience.",
    },
    {
      question: "Does Dr. Palve Labs accept health insurance?",
      answer:
        "Yes, we accept several health insurance plans. Please check with our customer service for details on coverage and claims processing.",
    },
  ];

  const AdditionalSupport = [
    {
      question: "How can I contact customer support?",
      answer:
        "You can reach our customer support team via phone or email as listed on our website for any inquiries or assistance needed.",
    },
    {
      question: "Is home sample collection available?",
      answer:
        "Yes, we offer home sample collection services for your convenience; please confirm availability in your area when booking your appointment.",
    },
  ];

  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />

      {/* Main content container with responsive design */}
      <div className="flex flex-col md:flex-row justify-center items-start max-w-7xl mx-auto mt-20 px-4 md:px-8 lg:px-10 gap-y-10 md:gap-x-14">
        {/* Left Section with Gradient */}
        <div className="w-full md:w-2/3 lg:w-auto bg-gradient-to-b from-[#2E3192] to-[#63D1F3] flex flex-col items-center text-white p-2  rounded-l-[29px] rounded-tr-[29px] rounded-br-[29px]">
          <img
            src={`${process.env.PUBLIC_URL}/images/faq.png`}
            alt="Help Icon"
            className="mb-3 w-full max-w-xs md:max-w-l h-auto rounded-full" // Adjusted max-w here
          />
          <div className="text-center w-full">
            <h2 className="text-xl font-bold mb-2 font-mont">
              Still no luck? We can help!
            </h2>
            <p className="mb-4 font-mont">
              Contact us and we’ll get back to you as soon as possible.
            </p>
          </div>
          <div className="flex justify-center w-full">
            <Link to="/contact">
              <button className="bg-red-500 text-white py-2 px-10 rounded-full font-mont w-full md:w-auto mb-2">
                SUBMIT A REQUEST
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full flex flex-col">
          {/* Right Section with FAQ */}
          <div className="w-full bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              General Information
            </h2>
            <div className="space-y-6 ">
              {/* First FAQ Item */}
              {GeneralInfo.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Right Section with FAQ */}
          <div className="w-full bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              Testing Procedures
            </h2>
            <div className="space-y-6">
              {TestingProcedures.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full  bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              Costs and Packages
            </h2>
            <div className="space-y-6">
              {/* First FAQ Item */}
              {CostsAndPackages.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              Results and Consultations
            </h2>
            <div className="space-y-6">
              {/* First FAQ Item */}
              {ResultsAndConsultations.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              Payment and Insurance
            </h2>
            <div className="space-y-6">
              {/* First FAQ Item */}
              {PaymentAndInsurance.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full bg-white mb-10">
            <h2 className="text-xl md:text-3xl font-bold mb-4 font-museo">
              Additional Support
            </h2>
            <div className="space-y-6">
              {/* First FAQ Item */}
              {AdditionalSupport.map((item, index) => (
                <div
                  key={index}
                  className={`border-gray-300 rounded-lg overflow-hidden w-full ${
                    activeIndex === index
                      ? "bg-[#1E4FA6] text-white"
                      : "bg-[#42424280] text-white p-2"
                  }`}
                >
                  <button
                    className={`w-full text-left p-4 focus:outline-none flex items-center justify-between ${
                      activeIndex === index
                        ? "bg-[#1E4FA6] text-white"
                        : "text-white"
                    }`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span className="font-medium font-museo">
                      {item.question}
                    </span>
                    <span className="arrow-icon">
                      <div
                        className={`transform ${
                          activeIndex === index ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      >
                        <FontAwesomeIcon
                          icon={activeIndex === index ? faAngleUp : faAngleDown}
                          style={{ color: "#e5e7eb" }}
                        />
                      </div>
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-6 bg-[#1E4FA6] text-white font-mont">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
}

export default FAQ;
