const ADMIN_MODULES = {
  USERS: "users",
  LABS: "labs",
  DOCTOR: "doctor",
  CUSTOMER_REVIEW: "customerReview",
  NEWSLETTER: "newsLetter",
  GENERAL_DETAILS: "generalDetails",
  GIFT_CARD: "giftCard",
  CONTACT_US: "contactUs",
  TEST_BOOKING: "testBooking",
  TEST: "test",
  POLICY: "policy",
  FRONTEND_OPTIONS: "frontendOptions",
  FAMILY_PACKAGE: "familyPackage",
};

const PERMISSIONS = {
  VIEW: "view",
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};

const percentage = (orignalPrice, offerPrice) => {
  const discountprice = orignalPrice - offerPrice;
  const percentageValue = Math.ceil(discountprice / orignalPrice * 100);
  return percentageValue;
};

const discountprice = (price, discount) => {
  const discountedPrice = price - (price * discount) / 100;
  return discountedPrice.toFixed(0);
};

export { ADMIN_MODULES, PERMISSIONS, discountprice,percentage };
