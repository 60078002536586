import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRegisterActive: false, // Default value
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterActive: (state, action) => {
      state.isRegisterActive = action.payload; // Set the register form visibility based on the payload (true/false)
    },
  },
});

export const { setRegisterActive } = registerSlice.actions;
export default registerSlice.reducer;
