import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const FamilyPackageUpdated = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const { filepath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    image: null,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const checkPermission = useCheckPermission();

  const canEdit = checkPermission(
    ADMIN_MODULES.FRONTEND_OPTIONS,
    PERMISSIONS.EDIT
  );
  const canDelete = checkPermission(
    ADMIN_MODULES.FRONTEND_OPTIONS,
    PERMISSIONS.DELETE
  );
  const canAdd = checkPermission(
    ADMIN_MODULES.FRONTEND_OPTIONS,
    PERMISSIONS.ADD
  );

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [navigate]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("family-package/allpackage");
      console.log(response.data);
      setData(response.data.package);
      setLoading(false);
      setError("");
    } catch (err) {
      setLoading(false);
      setData([]);
      setError(
        "Failed to fetch data: " +
          (err.response?.data?.error || "Unknown error")
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }
      $(dataTableRef.current).DataTable();
    }
  }, [data]);

  const handleModalShow = (itemId = null) => {
    if (itemId) {
      setSelectedItem(itemId);
      const itemToEdit = data.find((item) => item._id === itemId);
      setFormData({
        title: itemToEdit.title,
        link: itemToEdit.link,
        image: itemToEdit.image,
      });
      setIsEditMode(true);
    } else {
      setFormData({ title: "", link: "", image: null });
      setIsEditMode(false);
    }
    setModalShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("title", formData.title);
    formDataToSubmit.append("link", formData.link);
    if (formData.image) {
      formDataToSubmit.append("image", formData.image);
    }

    try {
      if (isEditMode) {
        await axiosClient.put(
          `family-package/package/${selectedItem}`,
          formDataToSubmit,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setSuccess("Item updated successfully!");
      } else {
        await axiosClient.post("family-package/package", formDataToSubmit, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setSuccess("Item created successfully!");
      }
      fetchData();
      handleModalClose();
      setTimeout(() => setSuccess(""), 2000);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedItem(null);
  };

  const handleShow = (itemId) => {
    setSelectedItem(itemId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const handleDelete = async () => {
    try {
      await axiosClient.delete(`family-package/package/${selectedItem}`);
      setData(data.filter((item) => item._id !== selectedItem));
      setSuccess("Item deleted successfully!");
      handleClose();
      fetchData();
      setTimeout(() => setSuccess(""), 2000);
    } catch (err) {
      setError("Failed to delete item.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this item?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal for add and edit Item */}
        <Modal show={modalShow} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit Item" : "Add Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="Title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Link" className="form-label">
                  Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="link"
                  value={formData.link}
                  onChange={(e) =>
                    setFormData({ ...formData, link: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="Image" className="form-label">
                  Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  onChange={(e) =>
                    setFormData({ ...formData, image: e.target.files[0] })
                  }
                  required
                />
              </div>
              <Modal.Footer className="mt-2">
                <button type="submit" className="modal-footer-btn">
                  {isEditMode ? "Update" : "Create"}
                </button>
                <button className="modal-footer-btn" onClick={handleModalClose}>
                  Cancel
                </button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Items</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {canAdd && (
                <div className="add-button">
                  <button onClick={() => handleModalShow()}>Add Item</button>
                </div>
              )}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th className="w-[15%] text-center align-middle">
                        S. No.
                      </th>
                      <th className="w-[50%] align-middle">Title</th>
                      <th className="w-[20%] align-middle">Link</th>
                      <th className="w-[15%] align-middle">Image</th>
                      <th className="w-[20%] align-middle">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={item._id}>
                          <td className="w-[15%] text-center align-middle">
                            {index + 1}
                          </td>
                          <td className="w-[50%] align-middle">{item.title}</td>
                          <td className="w-[20%] align-middle">{item.link}</td>
                          <td className="w-[15%] align-middle">
                            <img
                              src={`${filepath}${item.image}`}
                              alt="item"
                              width="120px"
                              height="120px"
                              className="block h-[80px] object-cover"
                            />
                          </td>
                          <td className="w-[20%] align-middle">
                            {canEdit && (
                              <i
                                className="fa-regular fa-pen-to-square"
                                onClick={() => handleModalShow(item._id)}
                              ></i>
                            )}
                            &nbsp;
                            {canDelete && (
                              <i
                                className="fa-solid fa-trash"
                                onClick={() => handleShow(item._id)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No items available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyPackageUpdated;
