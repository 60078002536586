import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import CustomDropdown from "./CustomSelect";

const EditTest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { filepath } = useAuth();
  // State for form fields
  const [title, setTitle] = useState("");
  const [tests, setTests] = useState([]);
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [fastingTime, setFastingTime] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [usedFor, setUsedFor] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [testCategory, setTestCategory] = useState("");
  const [labIds, setLabIds] = useState([]); // Selected lab IDs
  const [categories, setCategories] = useState([]);
  const [labs, setLabs] = useState([]); // All available labs
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [show, setShow] = useState(false); // Boolean for "Show on Home Page"
  const [loading, setLoading] = useState(true);
  const [loadingLabs, setLoadingLabs] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [offerPrice, setOfferPrice] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [currentInstructions, setCurrentInstructions] = useState("");
  const [currentPurpose, setCurrentPurpose] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editType, setEditType] = useState(null);
  const [labsCity, setLabCity] = useState([]);
  const [selectCity, setSelectCity] = useState([]);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [suggestedTest, setSuggestedTest] = useState([]);
  const [metaTilte, setMetaTitle] = useState("");
  const [metaurl, setMetaUrl] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaOgImg, setMetaOgImg] = useState("");
  const [currentogImg, setCurrentOgImg] = useState("");
  const [backgroungImg, setBackgroungImg] = useState("");
  const [currentBackgroungImg, setCurrentBackgroungImg] = useState("");
  const [parameterCount, setParameterCount] = useState("");

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);
  // Fetch test data and associated labs
  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await axiosClient.get(`/tests/test/${id}`);

        const test = response.data.test;
        setTitle(test.title);
        setSubTitle(test.subTitle);
        setDescription(test.description);
        setPrice(test.price);
        setOfferPrice(test.offerPrice);
        setFastingTime(test.fastingTime);
        setReportTime(test.reportTime);
        setUsedFor(test.usedFor);
        setInstructions(test.instructions);
        setShow(test.show);
        setTestCategory(test.testCategory);
        setLabIds(test.labId || []); // Assuming `labId` is an array of lab IDs
        setCurrentImage(test.testImage);
        setSelectedType(test?.testType);
        setMetaTitle(test.metaTitle);
        setMetaUrl(test.metaUrl);
        setMetaDescription(test.metaDescription);
        setCurrentOgImg(test.metaOgImg);
        setCurrentBackgroungImg(test.backgrounImg);
        setParameterCount(test.parameterCount);
        // upscaling in get all data that pass only array of id
        const upscaling = test?.upscaling.map((upscaling) => upscaling._id);
        setSuggestedTest(upscaling);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching test data:", error);
        setError("Failed to fetch test data.");
        setLoading(false);
      }
    };
    const interval = setTimeout(() => {
      fetchTestData();
    }, 400);
    return () => clearTimeout(interval);
  }, [id]);

  // Fetch test categories and labs and test
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient.get(
          "test-categories/test/categories"
        );
        setCategories(response.data.testCategory || []);
        setLoadingCategories(false);
      } catch (err) {
        setError("Failed to fetch test categories.");
        setLoadingCategories(false);
      }
    };

    const interval = setTimeout(() => {
      fetchCategories();
      fetchLabs();
      fetchTests();
    }, 400);
    return () => clearInterval(interval);
  }, []);

  const fetchLabs = async () => {
    try {
      const response = await axiosClient.get("labs");
      setLabs(response.data.labs || []);
      // uniquec city
      const uniqueCity = [
        ...new Map(
          response.data.labs.map((lab, index) => [
            lab.city.toLowerCase(),
            { _id: index, name: lab.city },
          ])
        ).values(),
      ];
      setLabCity(uniqueCity);
      setLoadingLabs(false);
    } catch (err) {
      setError("Failed to fetch labs.");
      setLoadingLabs(false);
    }
  };
  const fetchTests = async () => {
    try {
      const response = await axiosClient.get("tests/tests");
      const updatedTests = response.data.test.filter((test) => test._id !== id);
      setTests(updatedTests);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch tests. Please try again.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectCity.length > 0) {
      const selectedCities = labsCity
        .filter((city) => selectCity.includes(city._id))
        .map((city) => city.name);
      const filterlabs = labs.filter((lab) =>
        selectedCities.includes(lab.city)
      );
      setFilteredLabs(filterlabs);
    } else {
      setFilteredLabs(labs); // Show all labs if no city is selected
    }
  }, [labs]);
  // Handle checkbox changes for labs

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentImage(imageUrl);
  };

  const handleOgImgChange = (e) => {
    const file = e.target.files[0];
    setMetaOgImg(file);

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentOgImg(imageUrl);
  };

  const handleBackgroundImgChange = (e) => {
    const file = e.target.files[0];
    setBackgroungImg(file);

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentBackgroungImg(imageUrl);
  };
  // Handle changes in the show dropdown
  const handleShowChange = (e) => {
    setShow(e.target.value === "true");
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate that at least one lab is selected
    if (labIds.length === 0) {
      setError("Please select at least one lab.");
      return;
    }

    // Create FormData to handle text and file data together
    const formData = new FormData();
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("description", description);
    formData.append("price", Number(price));
    formData.append("offerPrice", Number(offerPrice) || 0);
    formData.append("fastingTime", fastingTime.toString());
    formData.append("reportTime", reportTime.toString());
    formData.append("usedFor", JSON.stringify(usedFor));
    formData.append("instructions", JSON.stringify(instructions));
    formData.append("show", show);
    // formData.append("testCategory", testCategory);
    formData.append("testType", selectedType);
    formData.append("testCategory", JSON.stringify(testCategory));
    formData.append("labId", JSON.stringify(labIds)); // convert array to JSON string
    formData.append("upscaling", JSON.stringify(suggestedTest));
    formData.append("metaTitle", metaTilte);
    formData.append("metaUrl", metaurl);
    formData.append("metaDescription", metaDescription);
    formData.append("parameterCount", parameterCount);
    // Append the image if it exists
    if (image) {
      formData.append("testImage", image); // Ensure 'testImage' matches the backend field
    }
    if (metaOgImg) {
      formData.append("metaOgImg", metaOgImg);
    }
    if (backgroungImg) {
      formData.append("backgrounImg", backgroungImg);
    }
    try {
      await axiosClient.put(`/tests/test/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSuccess("Test updated successfully!");
      setError("");

      setTimeout(() => {
        navigate("/admin/all-test");
      }, 1000);
    } catch (error) {
      console.error("Error updating test data:", error);
      setError(
        error.response ? error.response.data.message : "Failed to update test."
      );
      setSuccess("");
    }
  };

  const handleInputChange = (event, type) => {
    if (type === "purpose") {
      setCurrentPurpose(event.target.value);
      // Reset editType if switching from instructions to purpose
      if (editType !== "purpose") {
        setEditType(null);
        setEditIndex(null);
      }
    } else if (type === "instructions") {
      setCurrentInstructions(event.target.value);
      // Reset editType if switching from purpose to instructions
      if (editType !== "instructions") {
        setEditType(null);
        setEditIndex(null);
      }
    }
  };

  // Add a new word
  const handleAddWord = (type) => {
    if (type === "purpose" && currentPurpose.trim() !== "") {
      setUsedFor((prev) => [...prev, currentPurpose]);
      setCurrentPurpose(""); // Reset the input field
    } else if (type === "instructions" && currentInstructions.trim() !== "") {
      setInstructions((prev) => [...prev, currentInstructions]);
      setCurrentInstructions(""); // Reset the input field
    }
  };

  // Edit an existing word
  const handleEdit = (index, type) => {
    if (type === "purpose") {
      setCurrentPurpose(usedFor[index]);
    } else if (type === "instructions") {
      setCurrentInstructions(instructions[index]);
    }
    setEditIndex(index);
    setEditType(type);
  };

  // Remove a word from the list
  const handleRemove = (index, type) => {
    if (type === "purpose") {
      setUsedFor((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "instructions") {
      setInstructions((prev) => prev.filter((_, i) => i !== index));
    }
  };

  // Update a word after editing
  const handleUpdateWord = () => {
    if (
      editType === "purpose" &&
      currentPurpose.trim() !== "" &&
      editIndex !== null
    ) {
      setUsedFor((prev) => {
        const updatedList = [...prev];
        updatedList[editIndex] = currentPurpose;
        return updatedList;
      });
      setCurrentPurpose("");
    } else if (
      editType === "instructions" &&
      currentInstructions.trim() !== "" &&
      editIndex !== null
    ) {
      setInstructions((prev) => {
        const updatedList = [...prev];
        updatedList[editIndex] = currentInstructions;
        return updatedList;
      });
      setCurrentInstructions("");
    }
    setEditIndex(null); // Reset edit index
    setEditType(null); // Reset edit type
  };

  if (loading || loadingCategories || loadingLabs) return <p>Loading...</p>;

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Test</h5>
            <div className="row nav-column m-0 mt-5">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                  {/* Test Category */}
                  <div className="col-lg-6">
                    <CustomDropdown
                      categories={categories}
                      testCategory={testCategory}
                      setTestCategory={setTestCategory}
                      label={"Test Category"}
                      listShow={true}
                    />
                  </div>

                  {/* select labs */}
                  <div className="col-lg-6">
                    <CustomDropdown
                      categories={labsCity}
                      testCategory={selectCity}
                      setTestCategory={setSelectCity}
                      label={"Select City"}
                      listShow={true}
                    />
                  </div>

                  {/* Associated Labs as Checkboxes */}
                  <div className="col-lg-6">
                    <CustomDropdown
                      categories={filteredLabs}
                      testCategory={labIds}
                      setTestCategory={setLabIds}
                      label={"Associated Labs"}
                      listShow={true}
                    />
                  </div>

                  {/* Test Title */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Test Title"
                      required
                    />
                  </div>

                  {/* Test type */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Type</label>
                    <select
                      className="form-control"
                      value={selectedType} // Convert boolean to string for select value
                      onChange={(e) => setSelectedType(e.target.value)}
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="package">Package</option>
                      <option value="test"> Individual Test</option>
                    </select>
                  </div>

                  {/* Test Subtitle */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test Subtitle</label>
                    <input
                      type="text"
                      className="form-control"
                      value={subTitle}
                      onChange={(e) => setSubTitle(e.target.value)}
                      placeholder="Test Subtitle"
                      required
                    />
                  </div>

                  {/* Description */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      rows="3"
                      required
                    ></textarea>
                  </div>

                  {/* Price */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="Price"
                      required
                      min="0"
                      step="0.01"
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Offer Price</label>
                    <input
                      type="text"
                      className="form-control appearance-none focus:outline-none"
                      value={offerPrice || 0}
                      onChange={(e) => setOfferPrice(e.target.value)}
                      placeholder="Offer Price"
                    />
                  </div>

                  {/* Fasting Time */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Fasting Time (hours)</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fastingTime}
                      onChange={(e) => setFastingTime(e.target.value)}
                      placeholder="Fasting Time"
                      required
                    />
                  </div>

                  {/* Report Time */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Report Time (days)</label>
                    <input
                      type="text"
                      className="form-control"
                      value={reportTime}
                      onChange={(e) => setReportTime(e.target.value)}
                      placeholder="Report Time"
                      required
                    />
                  </div>
                  {/* Show on Home Page as Dropdown */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Show on Home Page</label>
                    <select
                      className="form-control"
                      value={show.toString()} // Convert boolean to string for select value
                      onChange={handleShowChange}
                      required
                    >
                      <option value="">Select Option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <CustomDropdown
                      categories={tests}
                      testCategory={suggestedTest}
                      setTestCategory={setSuggestedTest}
                      label={"Upscaling"}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">No of Parameters</label>
                    <input
                      type="number"
                      className="form-control"
                      value={parameterCount}
                      onChange={(e) => setParameterCount(e.target.value)}
                      placeholder="No of Parameters"
                    />
                  </div>

                  {/* meta Tilte */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Meta Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={metaTilte}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      placeholder="Meta Title"
                    />
                  </div>
                  {/* meta URL slug */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Meta URL</label>
                    <input
                      type="text"
                      className="form-control"
                      value={metaurl}
                      onChange={(e) => setMetaUrl(e.target.value)}
                      placeholder="Meta Title"
                    />
                  </div>
                  {/* meta Description */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Meta Description</label>
                    <textarea
                      className="form-control"
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                      placeholder="Meta Description"
                      rows="2"
                    ></textarea>
                  </div>

                  {/* Used For */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Used For</label>
                    <div className="flex gap-4 items-center mb-2">
                      <input
                        type="text"
                        className="form-control"
                        value={currentPurpose}
                        onChange={(e) => handleInputChange(e, "purpose")}
                        placeholder="Purpose"
                      />
                      {editIndex === null || editType !== "purpose" ? (
                        <button
                          className="btn m-0"
                          type="button"
                          onClick={() => handleAddWord("purpose")}
                        >
                          Add
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success m-0"
                          onClick={handleUpdateWord}
                        >
                          Update
                        </button>
                      )}
                    </div>
                    <ul className="list-group p-0">
                      {usedFor.map((word, index) => (
                        <li
                          key={index}
                          className="flex gap-4 justify-between items-center p-0 mt-2 relative pl-4 before:absolute before:top-3 before:left-1 before:w-1.5 before:h-1.5 before:rounded-full before:block before:bg-black"
                        >
                          <span className="w-2/4 flex-grow" title={word}>
                            {word}
                          </span>
                          <div className="flex gap-3">
                            <span
                              className="bg-transparent m-0 inline-block cursor-pointer"
                              onClick={() => handleEdit(index, "purpose")}
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            <span
                              className="bg-transparent m-0 inline-block cursor-pointer"
                              onClick={() => handleRemove(index, "purpose")}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Instructions */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Instructions</label>
                    <div className="flex gap-4 items-center mb-2">
                      <input
                        type="text"
                        className="form-control"
                        value={currentInstructions}
                        onChange={(e) => handleInputChange(e, "instructions")}
                        placeholder="Instructions"
                      />
                      {editIndex === null || editType !== "instructions" ? (
                        <button
                          type="button"
                          className="btn m-0"
                          onClick={() => handleAddWord("instructions")}
                        >
                          Add
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success m-0"
                          onClick={handleUpdateWord}
                        >
                          Update
                        </button>
                      )}
                    </div>
                    <ul className="list-disc">
                      {instructions.map((word, index) => (
                        <li
                          key={index}
                          className="flex gap-4 justify-between items-start mb-2 relative pl-4 before:absolute before:top-3 before:left-1 before:w-1.5 before:h-1.5 before:rounded-full before:block before:bg-black"
                        >
                          <span className="w-2/4 flex-grow" title={word}>
                            {word}
                          </span>
                          <div className="flex gap-3">
                            <span
                              className="bg-transparent m-0 inline-block cursor-pointer"
                              onClick={() => handleEdit(index, "instructions")}
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            <span
                              className="bg-transparent m-0 inline-block cursor-pointer"
                              onClick={() =>
                                handleRemove(index, "instructions")
                              }
                            >
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    {/* Image Upload */}
                    <div className=" mb-3">
                      <label className="form-label">Test Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="testImage"
                        onChange={handleImageChange}
                      />
                    </div>

                    {currentImage && (
                      <div className="col-lg-12 mb-3">
                        <label className="form-label">Image Preview</label>
                        <img
                          src={image ? currentImage : filepath + currentImage} // use local preview if a new image is chosen
                          alt="Test Preview"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    {/* og image  */}
                    <div className="mb-3">
                      <label className="form-label">Og Image</label>
                      <input
                        name="metaOgImg"
                        type="file"
                        className="form-control"
                        onChange={handleOgImgChange}
                        accept="image/*"
                      />
                    </div>
                    {currentogImg && (
                      <div className="col-lg-12 mb-3">
                        <label className="form-label">Image Preview</label>
                        <img
                          src={
                            metaOgImg ? currentogImg : filepath + currentogImg
                          } // use local preview if a new image is chosen
                          alt="Test Preview"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    {/* back groung img */}
                    <div className="mb-3">
                      <label className="form-label">Background Image</label>
                      <input
                        name="backgrounImg"
                        type="file"
                        className="form-control"
                        onChange={handleBackgroundImgChange}
                        accept="image/*"
                      />
                    </div>
                    {currentBackgroungImg && (
                      <div className="mb-3">
                        <label className="form-label">Image Preview</label>
                        <img
                          src={
                            backgroungImg
                              ? currentBackgroungImg
                              : filepath + currentBackgroungImg
                          } // use local preview if a new image is chosen
                          alt="Test Preview"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                  {/* Error and Success Messages */}
                  {error && (
                    <div className="col-lg-12 alert alert-danger">{error}</div>
                  )}
                  {success && (
                    <div className="col-lg-12 alert alert-success">
                      {success}
                    </div>
                  )}

                  {/* Submit Button */}
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-success m-0">
                      Update Test
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTest;
