import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";

const HomeTestingSteps = () => {
  const navigate = useNavigate();

  const steps = [
    {
      id: 1,
      title: "Choose Your Test",
      description:
        "Find the right test for you online from our affordable packages.",
      path: "/book-test", // Route path for this step
      imgSrc: "images/choose-your-test.png",
    },
    {
      id: 2,
      title: "Hassle-Free Collection",
      description:
        "No need to leave your home with our phlebotomist visiting your doorstep.",
      path: "/sample-collection", // Route path for this step
      imgSrc: "images/hassle-free.png",
    },
    {
      id: 3,
      title: "Fast Results",
      description: "Download your reports instantly & securely.",
      path: "/download-reports", // Route path for this step
      imgSrc: "images/fast-results.png",
    },
    {
      id: 4,
      title: "Consultation",
      description: "Consult with our doctors to understand your reports.",
      path: "/consultation", // Route path for this step
      imgSrc: "images/consultation.png",
    },
  ];
  // const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchHomeTesting = async () => {
      try {
        const response = await axiosClient(`/home-testing/steps`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        // setSteps(response.data.homeTesting || []);
        // console.log("fetch data", response.data.homeTesting);
      } catch (error) {
        console.error("There was a problem with the fetch operation 1:", error);
      }
    };

    fetchHomeTesting();
  }, []);

  // const handleCardClick = (path) => {
  //   navigate(path);
  // };

  return (
    <div className="max-w-screen-2xl mx-auto px-4 sm:px-8 lg:px-20 mt-10">
      {/* Title */}
      <h2 className="text-center text-2xl sm:text-2xl lg:text-3xl font-bold font-museo md:mb-8 py-10 ">
        Testing Made Easy in{" "}
        <span className="font1">
          {steps?.length > 0 ? steps.length : 0} Steps
        </span>
      </h2>

      {/* Steps */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {steps.map((step) => (
          <div
            key={step.id}
            className="bg-card1 text-white rounded-2xl lg:p-6 px-3 py-4 shadow-2xl font-museo flex flex-col items-center text-center cursor-pointer hover-color1 transition"
            // onClick={() => handleCardClick(`/${step.path}`)} // Redirect on click
          >
            <div className="w-14 h-14 flex items-center justify-center bg-white rounded-full font6 font-semibold text-lg mb-4">
              <img src={step.imgSrc} className="h-10 w-10"></img>
              {/* <div>{step.id}</div> */}
            </div>
            <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-2">
              {step.title}
            </h3>
            <p className="text-sm sm:text-base font-mont opacity-80">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeTestingSteps;
