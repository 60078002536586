import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useAuth } from "../../AuthContext";
import axiosClient from "../../axios-client";
import { Link } from "react-router-dom";

const FamilyCarePackage = () => {
  // Dynamic review data
  // const reviewData = [
  //   {
  //     name: "Dr. Vykunta Raju K. N",
  //     role: "Pediatrician",
  //     location: "Delhi",
  //     image: "images/Ellipse 1.png",
  //     review:
  //       "I had a fantastic experience with this lab testing service. Booking my appointment was seamless, and the results were delivered quickly and accurately.",
  //   },
  //   {
  //     name: "Dr. John Doe",
  //     role: "Cardiologist",
  //     location: "Mumbai",
  //     image: "images/Ellipse 2.png",
  //     review:
  //       "The service was professional, and I received my results sooner than expected. I highly recommend them for medical tests.",
  //   },
  //   {
  //     name: "Dr. Jane Smith",
  //     role: "Dermatologist",
  //     location: "Bangalore",
  //     image: "images/Ellipse 3.png",
  //     review:
  //       "This lab provided excellent service and precise results. The staff was very helpful, and I had a smooth experience.",
  //   },
  //   {
  //     name: "Dr. Jane Smith",
  //     role: "Dermatologist",
  //     location: "Bangalore",
  //     image: "images/Ellipse 3.png",
  //     review:
  //       "This lab provided excellent service and precise results. The staff was very helpful, and I had a smooth experience.",
  //   },
  // ];
  const [packageData, setPackageData] = useState([]);
  const { filepath } = useAuth();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient.get(`family-package/allpackage`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setPackageData(data.package || []);
        console.log("fetch data", data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto relative">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <h2 className="mb-4 text-center text-2xl font-bold font-museo md:mb-8 lg:text-3xl xl:mb-12">
          Ongoing <span className="font1">Offers</span>
        </h2>

        {/* Swiper Carousel for Customer Reviews */}
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".next-bt",
            prevEl: ".prev-bt",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
          className="mySwiper min-h-[40vh]"
        >
          {packageData.map((pkg, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex flex-col gap-3 h-[220px] lg:h-[260px] overflow-hidden shadow-xl w-fit">
                <Link to={pkg.link} className="flex h-full w-full">
                  <div className="flex h-full w-full">
                    <img
                      src={`${filepath}/${pkg.image}`}
                      alt={pkg.title}
                      className="h-full w-full"
                    />
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="absolute left-1/2 -bottom-4 pb-4 transform -translate-x-1/2 flex space-x-8 mb-4">
          <div className="prev-bt font6 md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-al">
            <FiArrowLeft size={22} />
          </div>
          <div className=" next-bt md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-all">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyCarePackage;
