import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";

const HelpModal = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ name: "", phone: "" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // Add API call or custom logic here
    handleClose(); // Close the modal after submitting
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("show");
      handleShow(); // Show modal
    }, 5000); // 10 seconds (10000ms)

    // Cleanup timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Need Help?
      </Button> */}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-black">Can we help?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="font-medium text-2xl mt-3">
            Need help in booking a lab test?
          </h5>
          <p className="font-semibold text-[#4C555D] text-md mt-1">
            Share your details, we will call you!
          </p>
          <Form onSubmit={handleSubmit}>
            {/* Name Field */}
            <Form.Group controlId="formName" className="mt-8">
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
                required
                className="d-flex border-0 p-0 focus:outline-none focus:border-none"
              />
            </Form.Group>
            <hr className="my-2 border-black" />

            {/* Phone Field */}
            <Form.Group controlId="formPhone" className="mt-8">
              <div className="d-flex align-items-center ">
                {/* <Form.Label>Phone</Form.Label> */}
                <span className="text-bold font-xs w-20 ">IND +91</span>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone *"
                  required
                  className="d-flex border-0 p-0 focus:outline-none focus:border-none"
                />
              </div>
            </Form.Group>

            <hr className="my-2 border-black" />
            <div className="d-flex justify-end mt-8">
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpModal;
