import React, { useState, useEffect } from "react";
import { BiSolidPhone } from "react-icons/bi";
import Sidebar from "./Sidebar";
import axiosClient from "../../axios-client";
import { Link } from "react-router-dom";

function Navbar2() {
  const [info, setInfo] = useState();
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axiosClient.get("details");

        if (response.data.success) {
          setInfo(response.data.generalDetails);
        } else {
          throw new Error("Failed to fetch info.");
        }
      } catch (err) {
        console.error(err.message || "Failed to fetch info. Please try again.");
      }
    };

    fetchInfo();
  }, []);

  return (
    <nav className="bg-primary1 text-white h-[75px] py-4 max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-12 lg:px-20 flex flex-wrap justify-between items-center">
      <div className="flex items-center">
        {/* Sidebar */}
        <Sidebar />
      </div>

      {/* Menu */}
      <ul className="hidden sm:flex items-center space-x-6 font-jost text-sm sm:text-base cursor-pointer">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">About Us</a>
        </li>
        <li>
          <a href="/testBrowse">Popular Packages</a>
        </li>
        <li>
          <a href="/contact">Contact Us</a>
        </li>
      </ul>

      {/* Phone Number */}
      <div className="flex items-center space-x-2 font-jost">
        <BiSolidPhone />
        <span className="text-sm sm:text-base">
          <a href={`tel:${info?.phoneNumber}`} className="hover:underline">
            {info?.phoneNumber}
          </a>
        </span>
      </div>
    </nav>
  );
}

export default Navbar2;
