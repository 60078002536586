import React, { useState, useEffect } from "react";
import HealthPackages from "./Health";
import CustomerReviews from "./Customers";
import BookTest from "./BookTest";
import WhyChooseUs from "./WhyChooseUs";
import TopOffers from "./TopOffers";
import CheckupSection from "./Checkup";
import HomeTestingSteps from "./Testing";
import StatsSection from "./StatsSection";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "./NewsLetter";
import Footer from "../layout/Footer";
import OurDirectors from "./OurDirectors";
import SearchSuggestion from "./SearchSuggestion";
import { Link } from "react-router-dom";
import { LuHeartPulse } from "react-icons/lu";
import HelpModal from "./HelpModal";
import HealthNew from "./HealthNew";
import FamilyCarePackage from "./FamiilyCarePackage";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const TestPage = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHelpModal(true); // Trigger modal after 10 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  const [images, setImages] = useState([]);
  const { filepath } = useAuth();

  useEffect(() => {
    const fetchHomeTesting = async () => {
      try {
        const response = await axiosClient(`/hero-block-image/img`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        // console.log("fetch images", response.data.heroBlockImage);
        setImages(response.data.heroBlockImage || []);
      } catch (error) {
        console.error("There was a problem with the fetch images:", error);
      }
    };

    fetchHomeTesting();
  }, []);

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="flex relative items-start bg-blue-800 sm:bg-cover sm:bg-center overflow-hidden">
        <div className="absolute h-full w-full hidden sm:block">
          {/* Swiper component */}
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="drp-hero-slider static h-full"
          >
            {images?.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="w-full h-full relative before:pt-[50%] before:w-full before:block">
                  <img
                    src={`${filepath}${image.image}`}
                    className="w-full h-full object-cover object-center absolute top-0 left-0 max-w-full"
                    alt="banner"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="relative z-10 max-w-screen-2xl py-20 px-4 sm:px-6 lg:px-20">
          {/* Header Text */}
          <div className="mt-8 sm:mt-12 text-center sm:text-left">
            <h1 className="text-2xl sm:text-2xl lg:text-3xl font-bold text-white font-museo leading-tight sm:leading-10">
              Accurate Diagnosis,{" "}
              <span className="font6">Timely Treatment.</span>
            </h1>
            {/* <p clasz> आसान जाँच, घर बैठे</p> */}
            <p className="text-base sm:text-lg mt-4 sm:mt-8 text-white font-museo font-medium opacity-80">
              Want to take a test?
            </p>
          </div>

          {/* <HelpModal
          // show={showHelpModal}
          // onClose={() => setShowHelpModal(false)}
          /> */}

          <div className="relative mt-5 sm:mt-7">
            <SearchSuggestion />
          </div>

          <div className="relative flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 mt-6 sm:mt-9">
            <Link to="/prescription">
              <button className="flex items-center justify-center sm:justify-start space-x-4 bg-white border border-gray-300 border-dashed px-8 py-5 rounded-lg shadow-md font-museo font1 hover:bg-blue-600 hover:text-white transition">
                <img
                  src={`${process.env.PUBLIC_URL}/images/upload.png`}
                  alt="Upload"
                  width="28px"
                  height="28px"
                />
                <span className="font-semibold pt-1 text-sm sm:text-lg">
                  Upload Your Prescription
                </span>
              </button>
            </Link>
            <Link to={"/testBrowse"} className="relative">
              <button className="bg-white border border-dashed border-gray-300 flex font-museo font6 hover-color1 hover:bg-red-600 hover:text-white items-center justify-center px-8 py-5 rounded-lg shadow-md sm:justify-start space-x-4 transition">
                <LuHeartPulse className="w-8 h-8" />
                <span className="font-semibold pt-1 text-base sm:text-lg">
                  Popular Packages
                </span>
              </button>
            </Link>
          </div>
        </div>
        {/* <div className="items-center flex mt-1 h-full text-white font-2xl">
          नए साल में, सही निदान - डॉ. पालवे लैब्स
        </div> */}
      </div>
      <WhyChooseUs />
      <CheckupSection />
      {/* <HealthNew /> */}
      <HealthPackages />
      <FamilyCarePackage />

      {/* <OurDirectors /> */}

      <HomeTestingSteps />
      <TopOffers />
      <StatsSection />

      <BookTest />
      <CustomerReviews />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default TestPage;
