import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";

const Benefits = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const { filepath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    imgSrc: "",
    title: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalShow, setModalShow]= useState(false);

  const checkPermission = useCheckPermission();

  const canEditSteps = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.EDIT);
  const canDeleteSteps= checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.DELETE);
  const canAddSteps = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("benefits/steps");
      // console.log("response:",response.data)
      setSteps(response.data.benefits || []);
      setLoading(false);
      setError("");
    } catch (err) {
      setLoading(false);
      setSteps([]);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [steps]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModalShow = (stepId = null) => {
    if (stepId) {
      setSelectedStep(stepId)
      // Editing mode
      const testingToEdit = steps.find((t) => t._id === stepId);
      setFormData({
        imgSrc: testingToEdit.imgSrc,
        title: testingToEdit.title
      });
      setIsEditMode(true);
    } else {
      // Create mode
      setFormData({
        imgSrc: "",
        title: ""
      });
      setIsEditMode(false);
    }
    setModalShow(true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        // Update an existing benfit step
        await axiosClient.put(`benefits/steps/${selectedStep}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for file upload
          },
        });
        setSuccess("Benefits step updated successfully!");
      } else {
        // Create a new benfit step
        await axiosClient.post("benefits/steps/add", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type for file upload
            },
          });
        setSuccess("Benefits step created successfully!");
      }
      fetchCategories();
      handleModalClose(); // Close the modal after success

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);

    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedStep(null);
  };

  const handleShow = (stepId) => {
    setSelectedStep(stepId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedStep(null);
  };

  // Handle delete category
  const handleDelete = async () => {
    try {
      await axiosClient.delete(
        `benefits/steps/${selectedStep}`
      );
      setSteps(
        steps.filter((category) => category._id !== selectedStep)
      );
      setSuccess("Benefit step deleted successfully!");
      setError("");
      handleClose();
      fetchCategories();

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (err) {
      setError("Failed to delete Benefit step.");
      setSuccess("");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Benefit step</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this benefit step?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal for add and edit benefit steps */}
        <Modal show={modalShow} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Edit Benefits Step" : "Add Benefits Step"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="Image Src" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imgSrc"
                      onChange={(e) => setFormData({ ...formData, imgSrc: e.target.files[0] })}
                      {...(!isEditMode && { required: true })} // input not required in edit modal
                    />

                    {/* Display image preview if imgSrc is a URL or string */}
                    {isEditMode && formData.imgSrc && typeof formData.imgSrc === 'string' && (
                    <div>
                        <p className="text-xs ">{formData.imgSrc}</p>
                    </div>
                    )}
                  </div>
                <Modal.Footer className="mt-2">
                    <button type="submit" className="modal-footer-btn">
                    {isEditMode ? "Update" : "Create"}
                    </button>
                    <button className="modal-footer-btn" onClick={handleModalClose}>
                    Cancel
                    </button>
                </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Benefits Steps</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {canAddSteps && (
                <div className="add-button">
                  <button onClick={() => handleModalShow()}>Add Benefits Steps</button>
                </div>
              )}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th className="w-[10%]">S. No.</th>
                      <th className="w-[35%]">Title</th>
                      <th className="w-[35%]">Image</th>
                      <th className="w-[20%]">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {steps.length > 0 ? (
                      steps.map((t, index) => (
                        <tr key={t._id}>
                          <td className="w-[10%]">{index + 1}</td>
                          <td className="w-[35%]">{t.title}</td>
                          <td className="w-[35%]">
                            <img
                              src={`${filepath}${t.imgSrc}`}
                              alt="img"
                              width="50px"
                              height="50px"
                            />
                          </td>
                          <td className="w-[20%]">
                          {canEditSteps && (
                            <i
                                className="fa-regular fa-pen-to-square"
                                onClick={() => handleModalShow(t._id)}
                            ></i>
                          )}
                            &nbsp;
                            {canDeleteSteps && <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(t._id)}
                            ></i>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No benefits steps available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benefits;
