import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import AddAdminUser from "./AddAdminUser";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";
import useCheckPermission from "../../hooks/useCheckPermission";

const UsersEdit = () => {
  const [show, setShow] = useState(false);
  const dataTableRef = useRef();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    gender: "",
  });

  const checkPermission = useCheckPermission();

  const canEditUser = checkPermission(ADMIN_MODULES.USERS, PERMISSIONS.EDIT);
  const canDeleteUser = checkPermission(
    ADMIN_MODULES.USERS,
    PERMISSIONS.DELETE
  );
  const canAddUser = checkPermission(ADMIN_MODULES.USERS, PERMISSIONS.ADD);

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axiosClient.get("users/details");
      console.log(response.data.users, 555);
      setUsers(response.data.user);
      setLoading(false);

      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable();
      }
    } catch (err) {
      setError("Failed to fetch users. Please try again.");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleShowEdit = (user) => {
    setSelectedUserId(user?._id);
    setEditUser({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
      dob: user?.dob
        ? isNaN(new Date(user?.dob))
          ? ""
          : new Date(user?.dob).toISOString().split("T")[0]
        : "",
      gender: user?.gender,
    });
    setShowEdit(true);
  };

  const handleShowEditUser = (user) => {
    console.log("user", user);
    setSelectedUser(user);
    setShowAddModal(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setError("");
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.put(
        `users/details/${selectedUserId}`,
        editUser
      );

      // Update the users list with the edited user
      setUsers(
        users?.map((user) =>
          user?._id === selectedUserId ? { ...user, ...editUser } : user
        )
      );

      setShowEdit(false);
    } catch (err) {
      setError("Failed to update user. Please try again.");
    }
  };

  // Show the delete confirmation modal
  const handleShow = (userId) => {
    setSelectedUserId(userId);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validation rules
    let valid = true;
    let errorMessage = "";

    if (name === "firstName" || name === "lastName") {
      if (!/^[A-Za-z\s]+$/.test(value)) {
        valid = false;
        errorMessage = `${
          name === "firstName" ? "First Name" : "Last Name"
        } should contain only alphabets and spaces.`;
      }
    }

    if (name === "phone") {
      if (!/^\d{0,10}$/.test(value)) {
        valid = false;
        errorMessage =
          "Phone number must be 10 digits and contain only numbers.";
      }
    }

    if (name === "email") {
      if (!/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,7}$/.test(value)) {
        valid = false;
        errorMessage = "Invalid email address.";
      }
    }

    // Update the state if valid
    if (valid) {
      setEditUser((prev) => ({
        ...prev,
        [name]: value,
      }));
      setError(""); // Clear any previous error if the input is valid
    } else {
      setError(errorMessage); // Set error message
    }
  };

  const handleClose = () => setShow(false);

  // Handle user deletion
  const deleteUser = async () => {
    try {
      await axiosClient.delete(`users/details/${selectedUserId}`);

      setUsers(users.filter((user) => user?._id !== selectedUserId));
      setShow(false);
    } catch (err) {
      setError("Failed to delete the user. Please try again.");
      setShow(false);
    }
  };

  // Handle viewing user details
  const handleViewDetails = (userId) => {
    navigate(`/users/${userId}`); // Navigate to the UserDetails page
  };

  useEffect(() => {
    if (!showAddModal) {
      setSelectedUser(null);
    }
  }, [showAddModal]);

  if (loading) return <p>Loading users...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this user?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteUser}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit User Modal */}
        <Modal show={showEdit} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateUser}>
              <div className="mb-3">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={editUser?.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={editUser?.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={editUser?.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={editUser?.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">DOB</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  value={editUser?.dob}
                  onChange={handleInputChange}
                  max={new Date().toISOString().split("T")[0]}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Gender</label>
                <select
                  className="form-control"
                  name="gender"
                  value={editUser?.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>User Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                {canAddUser && (
                  <AddAdminUser
                    show={showAddModal}
                    setShow={setShowAddModal}
                    selectedUser={selectedUser}
                    fetchUsers={fetchUsers}
                  />
                )}
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Joined On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user, index) => (
                      <tr key={user?._id}>
                        <td>{index + 1}</td>
                        <td>{user?.firstName}</td>
                        <td>{user?.lastName}</td>
                        <td>{user?.phone}</td>
                        <td>{user?.email}</td>
                        <td>
                          {new Date(user?.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>
                          {canDeleteUser && (
                            <i
                              className="fa-solid fa-trash mr-2"
                              onClick={() => handleShow(user?._id)}
                              style={{ cursor: "pointer" }}
                              title="Delete User"
                            ></i>
                          )}
                          {canEditUser && (
                            <i
                              onClick={() => {
                                handleShowEditUser(user);
                              }}
                              className="fa-solid fa-pen-to-square mr-2"
                              title="Edit User"
                              style={{ cursor: "pointer" }}
                            ></i>
                          )}
                          <i
                            className="fa-solid user-eye bi-eye"
                            onClick={() => handleViewDetails(user?._id)}
                            style={{ cursor: "pointer" }}
                            title="View Details"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UsersEdit;
