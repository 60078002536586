import React, { useState, useEffect } from "react";

const CustomDropdown = ({ categories, testCategory, setTestCategory, label,listShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(testCategory);
  const ulref = React.useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelection = (categoryId) => {
    setSelectedCategories((prevSelected) => {
      const isSelected = prevSelected.includes(categoryId);
      if (isSelected) {
        // Remove the category from selected if already present
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        // Add the category to selected
        return [...prevSelected, categoryId];
      }
    });
  };
  useEffect(() => {
    setTestCategory(selectedCategories);
  }, [selectedCategories, setTestCategory]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ulref.current && !ulref.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const Labtype = (category) => {
    // check lable type provider Category
    switch (label) {
      case "Associated Labs":
        return `${category?.name} - ${category?.city} ,${category?.state}`;
      case "Select City":
        return `${category.name}`;
      case "Select Provider":
        return `${category.name}`;
      case "Upscaling":
        return `${category.title}`;
      default:
        return `${category.name}`;
    }
  };

  return (
    <div className="w-full mb-3">
      <label className="form-label">{label}</label>
      {/* Display Selected Categories */}
      
      {selectedCategories?.length>0 && listShow  && (
        <div className="selected-categories mb-3 d-flex gap-3">
          {categories
            .filter((category) => selectedCategories.includes(category._id))
            .map((selectedCategory) => (
              <p key={selectedCategory._id} className="text-sm text-gray-700">
                {selectedCategory.name}
              </p>
            ))}
        </div>
      )}

      {/* Custom Dropdown */}
      <div className="relative" ref={ulref}>
        <div className="w-full px-4 py-2 text-left border rounded-md " onClick={toggleDropdown}>
          Select 
        </div>

        {isOpen && (
          <div className="absolute left-0 w-full mt-2 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
            {categories.map((category) => (
              <label
                key={category._id}
                className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-indigo-100"
              >
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category._id)}
                  onChange={() => handleSelection(category._id)}
                  className="mr-2"
                />
                {Labtype(category)}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
