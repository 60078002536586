import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { Link, useNavigate } from "react-router-dom";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";
import formatTime from "../../utils/formatTime";

const BookingEdit = () => {
  const dataTableRef = useRef();
  const tableInstance = useRef(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [editBooking, setEditBooking] = useState({
    serviceType: "",
    paymentMethod: "",
    totalAmount: 0,
    bookingId: "",
    paymentStatus: "",
    bookingStatus: "",
    testDate: "",
    timeSlot: null,
    isHome: false,
    homeAddress: "",
    labAddress: null,
    physicalCopy: false,
  });
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const checkPermission = useCheckPermission();
  const [labs, setLabs] = useState([]);

  const canEditBooking = checkPermission(ADMIN_MODULES.TEST_BOOKING, PERMISSIONS.EDIT);
  const canDeleteBooking = checkPermission(ADMIN_MODULES.TEST_BOOKING, PERMISSIONS.DELETE);

  const [assignTo, setAssignTo]= useState(null);
  const [showAssign, setShowAssign]= useState(false);
  const [staff, setStaff]= useState([]);
  const userId = localStorage.getItem('adminId');
  const [homeCollectionUser, setHomeCollectionUser] = useState(false)

  const fetchLabs = async () => {
    try {
      const response = await axiosClient.get("labs");
      setLabs(response.data.labs);
    } catch (error) {
      console.error("Error fetching labs:", error);
    }
  };

  // fetch home collection user
  const fetchHomeCollectionUser = async () =>{
    try{
      const response = await axiosClient.get(`users/details/${userId}`);
      if (response.data?.user?.homeCollectionAgent === true)
        setHomeCollectionUser(true);
      else {
        setHomeCollectionUser(false);
      }
    } catch(error){
      console.error("Error fetching home collection user:", error);
    }
  }

  useEffect(()=>{
    fetchLabs();
    fetchHomeCollectionUser();
  },[]);

  const handleLabChange = (event) => {
    const selectedLab = labs.find(
      (slot) => slot._id === event.target.value
    );
    setEditBooking((prev) => ({
      ...prev,
      labAddress: selectedLab,
    }));
  };

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axiosClient.get("/tests-booking/test/booking");               
        setBookings(response.data.booking);
        setLoading(false);        
      } catch (err) {
        setError("Failed to fetch bookings. Please try again.");
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);
  useEffect(() => {
    
    if (bookings.length > 0) {
      if (tableInstance.current) {
        tableInstance.current.destroy(); 
      }

      tableInstance.current = $(dataTableRef.current).DataTable({        
        ordering: true, 
        searching: true, 
        paging: true,         
        order: [[ 'desc']], 
      });
    }
  }, [bookings]);

  // fetch staff members
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axiosClient.get("users/staff");
        setStaff(response.data?.user);

      } catch (err) {
        console.log("error:", error)
      }
    };

    fetchStaff();
  }, []);

  const handleShowEdit = (booking) => {
    setSelectedBookingId(booking?._id);
    setEditBooking({
      serviceType: booking?.serviceType || "",
      paymentMethod: booking?.paymentMethod || "",
      totalAmount: booking?.totalAmount || 0,
      bookingId: booking?.bookingId || "",
      paymentStatus: booking?.paymentStatus || "",
      bookingStatus: booking?.bookingStatus || "",
      testDate: booking?.testDate
        ? new Date(booking?.testDate).toISOString().split("T")[0]
        : "",
      timeSlot: booking?.timeSlot || null,
      isHome: booking?.isHome || false,
      homeAddress: booking?.homeAddress || "",
      labAddress: booking?.labAddress || null,
      physicalCopy: booking?.physicalCopy || false,
    });
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setError("");
  };

  // show assign staff modal
  const handleShowAssign = (booking)=>{
    setSelectedBookingId(booking?._id);
    setAssignTo(booking?.assignTo || null);
    setShowAssign(true);
  }

  // close assign staff modal
  const handleCloseAssign = ()=>{
    setShowAssign(false);
    setError("");
  }

  // assign staff
  const updateAssignStaff = async (e)=>{
    e.preventDefault();
    try {
      const response = await axiosClient.put(
        `/tests-booking/test/booking/assignstaff/${selectedBookingId}`,
        {assignTo}
      );

      // Update the bookings list with the assigned booking
      setBookings(
        bookings.map((booking) => {
          if (booking?._id === selectedBookingId) {
            const updatedBooking = { ...booking, ...response.data.booking };
            updatedBooking.test_id = booking.test_id;
            return updatedBooking;
          }
          return booking;
        })
      );
      setShowAssign(false);
    } catch (err) {
      setError("Failed to update booking. Please try again.");
    }
  }

  const updateBooking = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.put(
        `/tests-booking/test/booking/${selectedBookingId}`,
        editBooking
      );

      // Update the bookings list with the edited booking
      setBookings(
        bookings.map((booking) =>
          booking?._id === selectedBookingId
            ? { ...booking, ...editBooking }
            : booking
        )
      );
      setShowEdit(false);
      fetchAllSlots();
    } catch (err) {
      setError("Failed to update booking. Please try again.");
    }
  };

  const handleShow = (bookingId) => {
    setSelectedBookingId(bookingId);
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setEditBooking((prev) => ({
      ...prev,
      [name]: inputValue,
    }));
  };

  const today = new Date().toISOString().split("T")[0];
  const isPastDate = editBooking?.testDate && new Date(editBooking.testDate) < new Date(today);

  // fetch all slots
  const fetchAllSlots= async()=>{
    try{
      const response = await axiosClient.get("/time-slot/slot");
      setAvailableTimeSlots(response.data.timeSlot)
    }catch(err){
      console.log(err);
      setAvailableTimeSlots([]);
    }
  };

  useEffect(()=>{
    fetchAllSlots();
  },[])

  const handleTimeSlotChange = (event) => {
    const selectedTimeSlot = availableTimeSlots.find(
      (slot) => slot._id === event.target.value
    );
    setEditBooking((prev) => ({
      ...prev,
      timeSlot: selectedTimeSlot,
    }));
  };

  // fetch all available slots when date change
  const handleDateChange = async (event) => {
    const selectedDate = event.target.value;
    setEditBooking((prev) => ({
      ...prev,
      testDate: selectedDate,
    }));
  };

  const handleClose = () => setShow(false);

  const deleteBooking = async () => {
    try {
      await axiosClient.delete(`/tests-booking/test/booking/${selectedBookingId}`);

      setBookings(bookings.filter((booking) => booking?._id !== selectedBookingId));
      setShow(false);
    } catch (err) {
      setError("Failed to delete the booking. Please try again.");
      setShow(false);
    }
  };

  if (loading) return <p>Loading bookings...</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this booking?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteBooking}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Booking Modal */}
        <Modal show={showEdit} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateBooking}>
              {/* <div className="mb-3">
                <label className="form-label">Service Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="serviceType"
                  value={editBooking?.serviceType}
                  onChange={handleInputChange}
                  required
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label">Payment Method</label>
                <input
                  type="text"
                  className="form-control"
                  name="paymentMethod"
                  value={editBooking?.paymentMethod}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Total Amount</label>
                <input
                  type="number"
                  className="form-control"
                  name="totalAmount"
                  value={editBooking?.totalAmount}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="bookingId"
                  value={editBooking?.bookingId}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Payment Status</label>
                <input
                  type="text"
                  className="form-control"
                  name="paymentStatus"
                  value={editBooking?.paymentStatus}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Booking Status</label>
                <input
                  type="text"
                  className="form-control"
                  name="bookingStatus"
                  value={editBooking?.bookingStatus}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Test Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="testDate"
                  value={editBooking?.testDate}
                  onChange={handleDateChange}
                  required
                  min={today}
                />
              </div>
              <select
                className="w-full py-3 px-2 border-2 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={editBooking?.timeSlot?._id}
                onChange={handleTimeSlotChange}
                disabled={isPastDate}
              >
                <option value="">Select Time Slot</option>
                {availableTimeSlots.map((slot, index) => (
                    <option key={index} value={slot._id}> {/* Use _id or any unique value */}
                      {formatTime(slot.from)} - {formatTime(slot.to)} {/* Display timeSlot text */}
                    </option>
                  ))}
              </select>
              <div className="mb-3">
                <label className="form-label">Is Home Test</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="isHome"
                  checked={editBooking?.isHome}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Home Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="homeAddress"
                  value={editBooking?.homeAddress}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="mb-3">
                <label className="form-label">Lab Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="labAddress"
                  value={editBooking?.labAddress}
                  onChange={handleInputChange}
                  required
                />
              </div> */}
              <select
                className="w-full py-3 px-2 border-2 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={editBooking?.labAddress?._id}
                onChange={handleLabChange}
              >
                <option value="">Select Lab Address</option>
                {labs.map((lab, index) => (
                    <option key={index} value={lab._id}> {/* Use _id or any unique value */}
                      {lab.name} {/* Display timeSlot text */}
                    </option>
                  ))}
              </select>
              <div className="mb-3">
                <label className="form-label">Physical Copy Required</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="physicalCopy"
                  checked={editBooking?.physicalCopy}
                  onChange={handleInputChange}
                />
              </div>
              {error && <p className="text-danger">{error}</p>}

              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </form>
          </Modal.Body>
        </Modal>

        {/* Assign Staff modal */}
        <Modal show={showAssign} onHide={handleCloseAssign} centered>
          <Modal.Header closeButton>
            <Modal.Title>Assign Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateAssignStaff}>
              <div className="mb-3">
                <label className="form-label">Assign Staff</label>
                <select className="form-control" name="assignTo" value={assignTo} onChange={(e)=>setAssignTo(e.target.value)}>
                  <option value="">Select Staff</option>
                  {staff.map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.firstName} {user.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn btn-primary">
                Assign
              </button>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Booking Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="mt-4 overflow-auto max-w-full">
                <table id="datatable" className="table table-hover 2xl:w-[1200px] caption-bottom border-collapse" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap text-base ">B.Id</th>
                      {/* <th>Service Type</th> */}
                      <th className="whitespace-nowrap text-base ">Payment Method</th>
                      <th className="whitespace-nowrap text-base">Tests</th>
                      <th className="whitespace-nowrap text-base text-center">Total Amount</th>
                      <th className="whitespace-nowrap text-base">Payment Status</th>
                      <th className="whitespace-nowrap text-base">Booking Status</th>
                      <th className="whitespace-nowrap text-base">Booking Date</th>
                      <th className="whitespace-nowrap text-base text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td className="text-sm">
                          <Link to={`/bookings/${booking?._id}`}>{booking?.bookingId}</Link>
                        </td>
                        <td className="text-sm">{booking?.paymentMethod}</td>
                        <td className="text-sm ">
                          {booking?.test_id.map((item, index) => (
                            <p key={index}>
                              {" "}
                              {index + 1} . {item}
                            </p>
                          ))}
                        </td>
                        <td className="text-sm text-center">{booking?.totalAmount}</td>
                        <td className="text-sm">{booking?.paymentStatus}</td>
                        <td className="text-sm">{booking?.bookingStatus}</td>
                        <td className="text-sm">
                          {new Date(booking?.testDate).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                          })}
                        </td>
                        <td className="text-sm text-center">
                          <div className="flex items-center">
                          {canEditBooking && (
                            <i
                              onClick={() => handleShowEdit(booking)}
                              className="fa-solid fa-pen-to-square mr-2"
                              title="Edit Booking"
                              style={{ cursor: "pointer" }}
                            ></i>
                          )}
                          {canDeleteBooking && (
                            <i
                              className="fa-solid fa-trash mr-2"
                              onClick={() => handleShow(booking?._id)}
                              style={{ cursor: "pointer" }}
                              title="Delete Booking"
                            ></i>
                          )}
                          
                          {booking?.isHome && !homeCollectionUser &&  (<i
                            onClick={() => handleShowAssign(booking)}
                            className="fa-solid fa-user mr-2"
                            title="Asign Staff"
                            style={{ cursor: "pointer" }}
                          ></i>)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingEdit;
