import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import useCheckPermission from "../../hooks/useCheckPermission";
import { ADMIN_MODULES, PERMISSIONS } from "../../constants/constant";
import formatTime from "../../utils/formatTime";

const TimeSlot = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    from: "",
    to: "",
    maxEntry: "",
  });
  const [dates, setDates]= useState([]);
  const [newDate, setNewDate] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalShow, setModalShow]= useState(false);
  const [disModalShow, setDisModalShow]= useState(false);

  const checkPermission = useCheckPermission();

  const canEditTestCat = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.EDIT);
  const canDeleteTestCat= checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.DELETE);
  const canAddTestCat = checkPermission(ADMIN_MODULES.FRONTEND_OPTIONS, PERMISSIONS.ADD)

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get("time-slot/slot");
      setSlots(response.data.timeSlot || []);
      setLoading(false);
      setError("");
    } catch (err) {
      setLoading(false);
      setSlots([]);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [slots]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModalShow = (categoryId = null) => {
    if (categoryId) {
      setSelectedSlot(categoryId)
      // Editing mode
      const testingToEdit = slots.find((t) => t._id === categoryId);
      setFormData({
        from: testingToEdit.from,
        to: testingToEdit.to,
        maxEntry: testingToEdit.maxEntry,
      });
      setIsEditMode(true);
    } else {
      // Create mode
      setFormData({
        from: "",
        to: "",
        maxEntry: "",
      });
      setIsEditMode(false);
    }
    setModalShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        // Update an existing testing step
        await axiosClient.put(`time-slot/slot/${selectedSlot}`, formData);
        setSuccess("Time slot updated successfully!");
      } else {
        // Create a new testing step
        await axiosClient.post("time-slot/slot/add", formData);
        setSuccess("Time slot created successfully!");
      }
      fetchCategories();
      handleModalClose(); // Close the modal after success

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);

    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
      setTimeout(() => {
        setError(""); // Clear error message
      }, 2000);
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedSlot(null);
  };
  
  const handleShow = (categoryId) => {
    setSelectedSlot(categoryId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedSlot(null);
  };

  // Handle delete category
  const handleDelete = async () => {
    try {
      await axiosClient.delete(
        `time-slot/slot/${selectedSlot}`
      );
      setSlots(
        slots.filter((category) => category._id !== selectedSlot)
      );
      setSuccess("Time slot deleted successfully!");
      setError("");
      handleClose();
      fetchCategories();

      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } catch (err) {
      setError("Failed to delete time slot.");
      setSuccess("");
    }
  };

  // get today
  const today = new Date().toISOString().split("T")[0];

  // Fetch existing disabled dates when the modal is shown
  useEffect(() => {
    if (selectedSlot) {
      fetchDisabledDates(selectedSlot);
    }
  }, [selectedSlot]);

  // fetch all disable dates
  const fetchDisabledDates = async (timeSlotId) => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`/time-slot/disable/get/${timeSlotId}`);
      if (response.data.success && response.data.data.length > 0) {
        // Filter the dates to show only today and future dates
        const today = new Date().toISOString().split("T")[0];
        const futureDates = response.data.data.filter((entry) => entry.date >= today);
        setDates(futureDates.map((entry) => entry.date)); // Set dates to the future and today
      }
    } catch (error) {
      console.error("Error fetching disabled dates:", error);
    } finally {
      setLoading(false);
    }
  };

  // Add a new date input field
  const addDateInput = () => {
    setDates([...dates, newDate]);
    setNewDate(""); // Clear the input field
  };

  // Remove a date input field
  const removeDateInput = (index) => {
    const updatedDates = dates.filter((_, i) => i !== index);
    setDates(updatedDates);
  };

  // show modal for disabling slots
  const handleDisModalShow= (categoryId) => {
    setSelectedSlot(categoryId);
    setDisModalShow(true);

    fetchDisabledDates(categoryId)
  };

  // close modal for disabling slots
  const handleDisModalClose = () => {
    setDates([]);
    setNewDate("")
    setDisModalShow(false);
    setSelectedSlot(null);
  };

  // function for disabling slots
  const handleDisable = async () => {
    try {
      // Filter out duplicate dates from the dates array
      const uniqueDates = [...new Set(dates)];
  
      // Get the previous disabled dates
      const response = await axiosClient.get(`/time-slot/disable/get/${selectedSlot}`);
  
      if (response.data.data.length > 0) {
        const existingDisabledDates = response.data.data.map(entry => entry.date);
  
        // Find the dates to remove
        const datesToRemove = existingDisabledDates.filter(date => !uniqueDates.includes(date));
  
        // Find the dates to add (dates that are not already in the existing disabled dates)
        const datesToAdd = uniqueDates.filter(date => !existingDisabledDates.includes(date));
  
        // Send the removal request for old dates
        if (datesToRemove.length > 0) {
          await axiosClient.post(`/time-slot/disable/remove/${selectedSlot}`, { dates: datesToRemove });
        }
  
        // Send the addition request for new dates
        if (datesToAdd.length > 0) {
          await axiosClient.post(`/time-slot/disable/${selectedSlot}`, { dates: datesToAdd });
        }
      } else {
        console.log("No previous dates found. Adding new dates.");
        await axiosClient.post(`/time-slot/disable/${selectedSlot}`, { dates: uniqueDates });
      }
  
      setSuccess('Timeslot disabled for selected dates');
      handleDisModalClose(); // Close the modal on success
  
      setTimeout(() => {
        setSuccess(""); // Clear success message after 2 seconds
      }, 2000);
  
    } catch (error) {
      console.error("Error disabling timeslot:", error);
      setError('Error disabling timeslot');
      setSuccess('');
      setTimeout(() => {
        setError(""); // Clear error message
      }, 2000);
    }
  };
  
  
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Modal for delete confirmation */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Time Slot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this time slot?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>


        {/* Modal for add and edit home testing steps */}
        <Modal show={modalShow} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Edit Home Testing Step" : "Add Home Testing Step"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="from">From:</label>
                    <input
                      type="time"
                      className="form-control"
                      id="from"
                      name="from"
                      value={formData.from}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="to">To:</label>
                    <input
                      type="time"
                      className="form-control"
                      id="to"
                      name="to"
                      value={formData.to}
                      onChange={handleInputChange}
                      required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="maxEntry">Max Entry</label>
                    <input
                    type="mumber"
                    className="form-control"
                    id="maxEntry"
                    name="maxEntry"
                    value={formData.maxEntry}
                    onChange={handleInputChange}
                    required
                    />
                </div>

                <Modal.Footer>
                    <button type="submit" className="modal-footer-btn">
                    {isEditMode ? "Update" : "Create"}
                    </button>
                    <button className="modal-footer-btn" onClick={handleClose}>
                    Cancel
                    </button>
                </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
        {/*  */}

      {/*  */}
      <Modal show={disModalShow} onHide={handleDisModalClose} centered >
        <Modal.Header closeButton>
          <Modal.Title>Disable Slot Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Are you sure you want to disable this slot availability?</p>
            <table className="w-full my-6">
              <thead>
                <tr>
                  <th className="px-1.5 border-t border-b border-solid border-gray-300 py-2 border-r">Date</th>
                  <th className="px-1.5 border-t border-b text-center border-solid border-gray-300 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {dates.map((date, index) => (
                  <tr key={index}>
                    <td className="w-9/12 px-1.5 text-sm border-b border-solid border-gray-300 py-2 border-r">
                      {date}
                    </td>
                    <td className="w-1/4 px-1.5 text-center border-b border-solid border-gray-300 py-2">
                      <button
                        type="button"
                        onClick={() => removeDateInput(index)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex items-end gap-2">
              <input
                type="date"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
                min={today} // Ensure that the date is not in the past
                className="py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none w-2/4 flex-grow"
              />
              <button type="button" className="max-w-fit border border-solid border-[#2e3192] bg-[#2e3192] hover:bg-white hover:text-[#2e3192] font-medium text-base text-white p-3 rounded-xl transition-all duration-200 ease-linear" onClick={addDateInput}>
                + Add Date
              </button>
              
            </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-footer-btn" onClick={() => handleDisable(dates)}>
            Disable
          </button>
          <button className="modal-footer-btn" onClick={handleDisModalClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <div className="heading-and-button">
              <h5>All Time Slots</h5>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              {canAddTestCat && <div className="add-button">
                {canAddTestCat && (
                    <div className="add-button">
                      <button onClick={() => handleModalShow()}>Add Time Slot</button>
                    </div>
                    )}
              </div>}
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Time Slot</th>
                      {/* <th>To</th> */}
                      <th>Max Entry</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slots.length > 0 ? (
                      slots.map((t, index) => (
                        <tr key={t._id}>
                          <td>{index + 1}</td>
                          <td>{formatTime(t.from)} - {formatTime(t.to)}</td>
                          {/* <td>{t.from}</td>
                          <td>{t.to}</td> */}
                          <td>{t.maxEntry}</td>
                          <td>
                          {canEditTestCat && (
                            <i
                              className="fa-regular fa-pen-to-square"
                              onClick={() => handleModalShow(t._id)}
                            ></i>
                          )}
                            &nbsp;
                            {canDeleteTestCat && <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(t._id)}
                            ></i>}

                            &nbsp;
                            {/* disable slot availabality icon */}
                            {canEditTestCat && <i
                              className="fa-solid fa-calendar-days"
                              onClick={() => handleDisModalShow(t._id)}
                            ></i>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No time slots available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TimeSlot;
