import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

const EditTestCategory = () => {
  const { id } = useParams();
  const { filepath } = useAuth();
  const [name, setName] = useState("");
  const [totalTest, setTotalTest] = useState("");
  const [testImage, setTestImage] = useState(null);
  const [metaUrl, setMetaUrl] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDiscription] = useState("");
  const [metaogImage, setMetaOgImage] = useState(null);
  const [currentTestImg, setCurrentTestImage] = useState("");
  const [currentOgImg, setCurrentOgImage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axiosClient.get(`test-categories/test/category/${id}`);
        setName(response.data.testCategory.name);
        setTotalTest(response.data.testCategory.TotalTest); // Set TotalTest
        setMetaUrl(response.data.testCategory.metaUrl);
        setMetaTitle(response.data.testCategory.metaTitle);
        setMetaDiscription(response.data.testCategory.metaDescription);
        setCurrentOgImage(response.data.testCategory.metaOgImg);
        setCurrentTestImage(response.data.testCategory.TestImage);
      } catch (err) {
        setError("Failed to fetch test category details.");
      }
    };

    fetchCategory();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("TotalTest", totalTest);
    formData.append("metaUrl", metaUrl);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);

    if (testImage) {
      formData.append("TestImage", testImage); // Append the image file
    }
    if (metaogImage) {
      formData.append("metaOgImg", metaogImage);
    }

    try {
      await axiosClient.put(`test-categories/test/category/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data" // Set content type for file upload
        }
      });

      setSuccess("Test category updated successfully!");
      setError("");
      navigate("/admin/test-category-details");
    } catch (err) {
      setError("Failed to update test category.");
      setSuccess("");
    }
  };

  const handleOgImgChange = (e) => {
    const file = e.target.files[0];
    setMetaOgImage(file);

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentOgImage(imageUrl);
  };
  const handleTetsImgChange = (e) => {
    const file = e.target.files[0];
    setTestImage(file);

    // Create a preview URL
    const imageUrl = URL.createObjectURL(file);
    setCurrentTestImage(imageUrl);
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Test Category</h5>

            {/* Display success or error messages */}
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}

            <div className="row nav-column m-0 mt-5">
              <div>
                <form className="row justify-between" onSubmit={handleSubmit}>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="totalTest" className="form-label">
                      Total Test
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="totalTest"
                      value={totalTest}
                      onChange={(e) => setTotalTest(e.target.value)}
                      placeholder="Total Tests"
                      required
                    />
                  </div>

                  {/* Meta Title */}
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Meta Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      placeholder="Meta Title"
                      required
                    />
                  </div>

                  {/* meta URL slug */}
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Meta Url
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      value={metaUrl}
                      onChange={(e) => setMetaUrl(e.target.value)}
                      placeholder="Meta Url"
                    />
                  </div> 

                  {/* meta Discription */}
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Meta Discription
                    </label>
                    <textarea                      
                      className="form-control"                      
                      value={metaDescription}
                      onChange={(e) => setMetaDiscription(e.target.value)}
                      placeholder="Meta Description"
                      rows="2"                      
                    ></textarea>
                  </div>                  

                    {/* testCategory Image */}
                    <div className="col-lg-6 mb-3 px-3">
                      <label htmlFor="testImage" className="form-label">
                        Test Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="testImage"
                        onChange={handleTetsImgChange} // Get the selected file
                      />
                      {testImage && <p>{testImage.name}</p>}{" "}
                      {/* Display the name of the selected file */}
                      {currentTestImg && (
                        <div className="col-lg-12 mb-3">
                          <label className="form-label">Image Preview</label>
                          <img
                            src={testImage ? currentTestImg : filepath + currentTestImg} // use local preview if a new image is chosen
                            alt="Test Preview"
                            style={{ width: "200px", height: "auto" }}
                          />
                        </div>
                      )}
                    </div>

                    {/* meta ogImage */}
                    <div className="col-lg-6 mb-3 px-3">
                      <label htmlFor="metaOgImg" className="form-label">
                        Meta Og Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="metaOgImg"
                        onChange={handleOgImgChange} // Get the selected file
                      />
                      {currentOgImg && (
                        <div className="col-lg-12 mb-3">
                          <label className="form-label">Image Preview</label>
                          <img
                            src={metaogImage ? currentOgImg : filepath + currentOgImg} // use local preview if a new image is chosen
                            alt="Test Preview"
                            style={{ width: "200px", height: "auto" }}
                          />
                        </div>
                      )}
                    </div>

                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Update Test Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTestCategory;
