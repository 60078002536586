import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import BarChart from "./login/BarChart";
import PieChartComp from "./login/PieChartComp";
import axiosClient from "../../axios-client";

const Main = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [users, setUsers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosClient.get("users/details");
        console.log(response, 256);
        setUsers(response.data);
      } catch (err) {
        console.error("Failed to fetch users. Please try again.");
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axiosClient.get("/tests-booking/test/booking");
        setBookings(response.data);

        const pendingOrders = response.data.booking.filter(
          (booking) => booking.bookingStatus === "Confirmed"
        ).length;
        setPendingCount(pendingOrders); // Set the pending count
      } catch (err) {
        console.error("Failed to fetch bookings. Please try again.");
      }
    };

    fetchBookings();
  }, []);

  return (
    <>
      <SideBar />
      <Navbar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="admin-banner">
          <div className="row">
            {showPopup && (
              <div className="col-lg-12 dash-up">
                <div className="dash-strip">
                  <div>
                    <h3>Congrats!! you can now modify your application</h3>
                    <p>
                      Keep all the information up to date with minimal effort
                    </p>
                  </div>
                  <div>
                    <i
                      className="bi bi-x"
                      onClick={() => setShowPopup(false)}
                    ></i>
                  </div>
                </div>
                <div className="over"></div>
              </div>
            )}
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Users :{" "}
                  {users.userCount}
                </h2>
                {/* <h3 className="pt-2">10</h3> */}
                <ul className="overlapping-avatar">
                  <li>
                    <img src="/images/user1.png" alt="" />
                  </li>
                  <li>
                    <img src="/images/user2.png" alt="" />
                  </li>
                  <li>
                    <img src="/images/user3.png" alt="" />
                  </li>
                  <li>
                    <span>{users.userCount - 1}+</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Total Pending Tests :{" "}
                  {pendingCount}
                </h2>
                <div className="dash-space">
                  <div>
                    {/* <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p> */}
                  </div>
                  <div className="dash-img">
                    <img src="/images/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Total Completed Tests :{" "}
                  {bookings.bookingCount}
                </h2>
                <div className="dash-space">
                  <div>
                    {/* <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p> */}
                  </div>
                  <div className="dash-img">
                    <img src="/images/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-12">
                  <div className="recents">
                    <h5>Additional Information</h5>
                    <p>
                      An admin panel for a lab test website typically serves as
                      the back-end management interface, allowing administrators
                      to manage various aspects of the lab’s operations. Below
                      are the common features and functionalities that are often
                      part of such an admin panel:
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
